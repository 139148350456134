import JSZip from 'jszip';

import { Injectable } from '@angular/core';
import {
    AdminDocumentExportResponseModel,
    DocumentApiService
} from './document-api.service';
import { downloadBlob } from '../browser';
import { ModulesService } from './modules.service';
import { convertDesignsFunction } from '../entities/modules-entities';
import { getDateFromUnix } from '../date';

@Injectable({
    providedIn: 'root'
})
export class ExportDesignsService {
    private designIdsFile?: File; // store file if user wants to export later

    private documentServiceBatchSize: number = 100;
    private convertBatchSize: number = 100;


    constructor(
        private documentApiService: DocumentApiService,
        private modulesService: ModulesService
    ) {}


    public reset() {
        this.designIdsFile = undefined;
        this.hideError();
    }

    public handleFileDropped(files: FileList) {
        this.hideError();

        if (files.length > 0) {
            const file = files[0];
            const errors = this.validateFile(file);
            if (errors.length > 0) {
                this.showError(errors[0]);
                return;
            }

            this.handleFileChanged(file);
        }
    }

    public handleFileChanged(file: File) {
        this.designIdsFile = file;
        this.uploadFile(this.designIdsFile);
    }

    public async exportToZip(content: string) {
        this.hideError();

        let ids = this.parseIds(content);
        if (ids == null) {
            return;
        }

        const documents: AdminDocumentExportResponseModel[] = [];
        while (ids.length > 0) {
            const batchRequest = {
                documentIds: ids.splice(0, this.documentServiceBatchSize)
            };
            const batchResponse = await this.documentApiService.adminExportDocuments(batchRequest);
            documents.push(...batchResponse);
        }

        await this.processDocuments(documents);
    }


    private hideError() {
        $('#upload-error #error-message').html('');
        $('#upload-error').hide();
    }

    private showError(message: string) {
        $('#upload-error #error-message').html(message);
        $('#upload-error').show();

        this.designIdsFile = undefined;
    }

    private uploadFile(file: File) {
        this.hideError();

        const errors = this.validateFile(file);
        if (errors.length > 0) {
            this.showError(errors[0]);
            return;
        }

        $('#text-ids').val('');
        this.readFileContent(file);
    }

    private readFileContent(file: File){
        const reader = new FileReader();
        reader.onload = async (event) => {
            const arrayBuffer = event.target.result as ArrayBuffer;
            const array = new Uint8Array(arrayBuffer);
            const content = String.fromCharCode.apply(null, array as any)
            await this.exportToZip(content);
        }
        reader.readAsArrayBuffer(file);
    }

    private validateFile(file: File) {
        const errors: string[] = [];

        const fileExt = file.name.substring(file.name.indexOf('.'));
        if (fileExt != '.csv') {
            errors.push('Wrong file type; only csv file type supported!');
        }

        return errors;
    }

    private parseIds(content: string) {
        if (content == null) {
            this.showError('Enter at least one design id!');
            return undefined;
        }

        const regex = new RegExp(/[\s,;]/g);
        let ids = content
            .split(regex)
            .map((id) => id.trim())
            .filter((id) => id.length > 0);

        if (ids.length < 1) {
            this.showError('Enter at least one design id!');
            return undefined;
        }

        return ids;
    }

    private async processDocuments(documents: AdminDocumentExportResponseModel[]) {
        const documentsByDesignType = this.groupDocumentsByDesignType(documents);
        const files = await this.convertDocumentsByDesignType(documentsByDesignType);

        const zip = new JSZip();
        for(let document of documents) {
            const folder = zip.folder(document.documentId);
            folder.file(`${document.name}.pe`, files[document.documentId]);
            folder.file('metadata.json', this.prepareMetadataJson(document));
        }

        this.downloadZip(await zip.generateAsync({ type:"blob" }));
    }

    private downloadZip(zipBlob: Blob) {
        downloadBlob(zipBlob, 'cat-export.zip');
    }

    private groupDocumentsByDesignType(documents: AdminDocumentExportResponseModel[]): Record<number, AdminDocumentExportResponseModel[]> {
        const groupedObject: Record<number, AdminDocumentExportResponseModel[]> = {};

        documents.forEach(document => {
            if (groupedObject[document.designType] == null) {
                groupedObject[document.designType] = [];
            }

            groupedObject[document.designType].push(document);
        });

        return groupedObject;
    }

    private async convertDocumentsByDesignType(documents: Record<number, AdminDocumentExportResponseModel[]>) {
        const retVal: Record<string, string> = {};

        type toBeConvertedType = {
            function: convertDesignsFunction;
            documents: AdminDocumentExportResponseModel[]
        };
        const toBeConverted: Record<number, toBeConvertedType> = {};

        for(const designTypeStr of Object.keys(documents)) {
            const designType = parseInt(designTypeStr, 10);

            const designListInfo = this.modulesService.getDesignListInfoForDesignType(designType);
            const designListInfoConvertDesignsFn = designListInfo?.convertDesigns;

            if (designListInfoConvertDesignsFn == null) {
                // No conversion needed
                documents[designType].forEach(x => {
                    retVal[x.documentId] = atob(x.fileContent);
                });
            }
            else {
                // Conversion needed.
                // Group together designs with the same method.
                const fnId = designListInfo.convertDesignsId ?? 0;
                if (toBeConverted[fnId] == null) {
                    toBeConverted[fnId] = {
                        function: designListInfoConvertDesignsFn,
                        documents: []
                    };
                }

                toBeConverted[fnId].documents.push(...documents[designType]);
            }
        }

        // Convert all designs that need to be converted
        for(const toBeConvertedEntry of Object.values(toBeConverted)) {
            while (toBeConvertedEntry.documents.length > 0) {
                // Iterate through documents and convert them.
                const batchResponse = await toBeConvertedEntry.function({
                    Items: toBeConvertedEntry.documents.splice(0, this.convertBatchSize).map(x => ({ Id: x.documentId, Content: atob(x.fileContent) }))
                });
                for(let res of batchResponse.Items) {
                    retVal[res.Id] = res.Content;
                }
            }
        }

        return retVal;
    }

    private prepareMetadataJson(document: AdminDocumentExportResponseModel) {
        return JSON.stringify(document, (k, v) => {
            switch (k) {
                case 'fileContent': return undefined;
                case 'created': return getDateFromUnix(v);
                case 'updated': return getDateFromUnix(v);
                default: return v;
            }
        }, 4);
    }
}
