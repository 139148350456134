<h2 class="mb-3">Search GDPR data</h2>

<form id="searchForm" class="form-horizontal">
    <div class="form-group row">
        <label class="col-form-label col-sm-2" for="username">Username</label>
        <div class="col-sm-5">
            <input type="text" class="form-control" id="username" name="username" maxlength="100" required>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-offset-2 col-sm-5">
            <button type="button" id="searchButton" class="btn btn-primary">Search</button>
        </div>
    </div>
</form>

<h2>Profis engineering</h2>

<br />

<form id="displayForm" class="form-horizontal">
    <input type="hidden" id="responseUserId" name="userId" value="">
    <input type="hidden" id="responseUsername" name="username" value="">
    <div class="form-group row">
        <label class="col-form-label col-sm-2">General name</label>
        <div class="col-sm-5">
            <p class="form-paragraph" id="Profis3Name" name="generalName" maxlength="100"></p>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Email</label>
        <div class="col-sm-5">
            <p class="form-paragraph" id="Profis3Email" name="email" maxlength="100"></p>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Phone number</label>
        <div class="col-sm-5">
            <p class="form-paragraph" id="Profis3PhoneNumber" name="phoneNumber" maxlength="100"></p>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Fax</label>
        <div class="col-sm-5">
            <p class="form-paragraph" id="Profis3Fax" name="fax" maxlength="100"></p>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Allowed to track</label>
        <input type="checkbox" id="Profis3UserPrivacy" name="userPrivacy" disabled>

    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Allowed to send E-mail</label>
        <input type="checkbox" id="Profis3UserNotify" name="userNotify" disabled>
    </div>

</form>

<h2>Quantity calculator</h2>

<br />

<form class="form-horizontal">
    <div class="form-group row">
        <label class="col-form-label col-sm-2">General name</label>
        <div class="col-sm-5">
            <p class="form-paragraph" id="PurchaserName" name="generalName" maxlength="100"></p>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Email</label>
        <div class="col-sm-5">
            <p class="form-paragraph" id="PurchaserEmail" name="email" maxlength="100"></p>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Phone number</label>
        <div class="col-sm-5">
            <p class="form-paragraph" id="PurchaserPhoneNumber" name="phoneNumber" maxlength="100"></p>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Fax</label>
        <div class="col-sm-5">
            <p class="form-paragraph" id="PurchaserFax" name="fax" maxlength="100"></p>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Allowed to track</label>
        <input type="checkbox" id="PurchaserUserPrivacy" name="userPrivacy" disabled>

    </div>
    <div class="form-group row">
        <label class="col-form-label col-sm-2">Allowed to send E-mail</label>
        <input type="checkbox" id="PurchaserUserNotify" name="userNotify" disabled>
    </div>

</form>

<h2>GDPR from templates</h2>
<br />

<div class="main-div">
    <div id="jsGridTemplateManagement"></div>
</div>

<br />
<button style="display:none;" type="button" id="exportGDPR" class="btn btn-primary mr-2">Export results in CSV</button>
<button style="display:none;" type="button" id="deleteButton" class="btn btn-danger mr-2">Delete</button>
