import { Component } from '@angular/core';

import { AppTitleService } from '../../services/app-title.service';
import { ExportDesignsService } from '../../services/export-designs.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { Page } from '../page';

@Component({
    selector: 'app-export-designs',
    templateUrl: './export-designs.component.html',
    styleUrls: ['./export-designs.component.css']
})
export class ExportDesignsComponent extends Page {
    pageTitle = $localize`:app-title export designs|Export designs application title:Export Designs`;

    public over = false;

    constructor(
        protected appTitleService: AppTitleService,
        protected pageLoadingService: PageLoadingService,
        private exportDesignsService: ExportDesignsService
    ) {
        super(appTitleService, pageLoadingService);
    }


    ngOnInit() {
        super.ngOnInit();
        this.exportDesignsService.reset();

        // drag and drop file zone
        $('#drop-zone')
            .on('dragover', eventObject => {
                this.handleDragEvent(eventObject.originalEvent, true);
            })
            .on('dragleave', eventObject => {
                this.handleDragEvent(eventObject.originalEvent, false);
            })
            .on('drop', eventObject => {
                this.handleDragEvent(eventObject.originalEvent, false);
                this.exportDesignsService.handleFileDropped(eventObject.originalEvent.dataTransfer.files);
            });

        // click on hidden select file button
        $('#selectFileButton').on('click', () => {
            $('#js-upload-files').trigger('click');
        });

        // upload file when file input changes
        $('#js-upload-files').on('change', () => {
            const $fileInput = $('#js-upload-files');
            const fileInput = <HTMLInputElement>$fileInput[0];

            this.exportDesignsService.handleFileChanged(fileInput.files[0]);
            $fileInput.val(null);
        });

        // submit ids from textarea
        $('#submit-ids').on('click', () => {
            (async () => {
                await this.exportDesignsService.exportToZip($('#text-ids').val() as string);
            })();
        });
    }

    private handleDragEvent(eventObject: Event, over: boolean) {
        eventObject.preventDefault();
        eventObject.stopPropagation();

        this.over = over;
    }
}
