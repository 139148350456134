<h2 class="mb-3">Marketing Campaigns</h2>

<div id="searchMarketingCampaignsForm"></div>

<div class="main-div">
    <div id="marketingCampaignGrid" class="marketing-campaign-grid"></div>
</div>

<div id="addEditMarketingCampaignDialog"></div>
<div id="viewMarketingCampaignDialog"></div>

<div id="deleteMarketingCampaignDialog">
    Are you sure you want to delete the campaign?
</div>

<div id="importMarketingCampaignEmailsExcelFile" title="Import marketing campaign user emails">
</div>

<div id="chooseTypeMarketingCampaignDialog">
    <form id="chooseTypeMarketingCampaignForm" class="form-horizontal">
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Type</label>
            <div class="col-md-6">
                <select name="CampaignType" class="form-control">
                    <option value="0">Video</option>
                    <option value="1">Image</option>
                </select>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="1" id="inAppPlacement" name="InAppPlacement">
            <label class="form-check-label" for="inAppPlacement">
                In app marketing placement
            </label>
        </div>
    </form>
</div>
