import { Component } from '@angular/core';

import { AppTitleService } from '../../services/app-title.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { RegionLinksService, RegionLinksWithName } from '../../services/region-links.service';
import { Page } from '../page';
import regionLinksEditDialogTemplate from './region-links-edit-dialog.hbs';
import regionLinksPreviewDialogTemplate from './region-links-preview-dialog.hbs';
import regionLinksSearchFormTemplate from './region-links-search-form.hbs';

@Component({
    selector: 'app-region-links',
    templateUrl: './region-links.component.html',
    styleUrls: ['./region-links.component.css']
})
export class RegionLinksComponent extends Page {
    pageTitle = $localize`:app-title regional links|Regional links application title:Regional links`;

    private $regionLinksGrid: JQuery<HTMLElement>;
    private $regionLinksDialog: JQuery<HTMLElement>;
    private $regionLinksSearch: JQuery<HTMLElement>;
    private image: string;

    constructor(
        protected appTitleService: AppTitleService,
        protected pageLoadingService: PageLoadingService,
        private regionLinksService: RegionLinksService
    ) {
        super(appTitleService, pageLoadingService);
    }

    async ngOnInit() {
        super.ngOnInit();

        this.$regionLinksGrid = $('#regionLinksGrid');
        this.$regionLinksDialog = $('#regionLinksDialog');
        this.$regionLinksSearch = $('#regionLinksSearch');

        this.initGrid();
        this.initSearchForm();

        await this.loadGridData();
    }

    private initGrid() {
        this.$regionLinksGrid.jsGrid({
            width: '100%',
            height: 'auto',
            sorting: true,
            paging: true,
            pageSize: 10,
            fields: [
                { name: 'RegionId', type: 'number', visible: false },
                { name: 'RegionName', type: 'text', width: 150, title: 'Region', align: 'left' },
                { name: 'BuyLicenseHolLink', type: 'text', width: 200, title: 'Buy license HOL page link', align: 'left' },
                { name: 'LicenseComparisonPageLink', type: 'text', width: 200, title: 'License comparison page link', align: 'left' },
                { name: 'ManageAccountLink', type: 'text', width: 200, title: 'Manage Account Link', align: 'left' },
                { name: 'SoftwareSellingLink', type: 'text', width: 200, title: 'Software Selling Link', align: 'left' },
                { name: 'UpgradeToPremiumLink', type: 'text', width: 200, title: 'Upgrade to premium', align: 'left' },
                { name: 'UpgradeToPremiumHeaderLink', type: 'text', width: 200, title: 'Upgrade to premium in header', align: 'left' },
                { name: 'AskHiltiLink', type: 'text', width: 200, title: 'AskHiltiLink', align: 'left' },
                { name: 'BetLearningLink', type: 'text', width: 200, title: 'BET learning link', align: 'left' },
                { name: 'HandrailCBFEMLearnMoreLink', type: 'text', width: 200, title: 'Handrail CBFEM learn more link', align: 'left' },
                { name: 'PerfectSpecLink', type: 'text', width: 200, title: 'Perfect Spec link', align: 'left' },
                {
                    type: 'control',
                    width: 150,
                    headerTemplate: 'Actions',
                    itemTemplate: (value: void, item: RegionLinksWithName) => {
                        const $mainNode = $('<div>').addClass('js-grid-actions');
                        const $viewNode = $('<span>').addClass('sprite d-inline-block sprite-view').attr('title', 'Preview').appendTo($mainNode);
                        const $editNode = $('<span>').addClass('sprite d-inline-block sprite-edit').attr('title', 'Edit').appendTo($mainNode);

                        $viewNode.click(() => {
                            const compiledHtml = regionLinksPreviewDialogTemplate(item);
                            this.$regionLinksDialog.html(compiledHtml);
                            this.$regionLinksDialog.dialog({
                                title: 'Preview regional links',
                                width: '1000px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [{
                                    text: 'Close',
                                    click: () => {
                                        this.$regionLinksDialog.dialog('close');
                                    }
                                }],
                                close: () => {
                                    this.$regionLinksDialog.empty();
                                }
                            });
                        });

                        $editNode.click(async () => {
                            const initialData = await this.regionLinksService.getRegionData(item.RegionId);
                            const compiledHtml = regionLinksEditDialogTemplate(initialData);

                            this.$regionLinksDialog.html(compiledHtml);

                            this.$regionLinksDialog.dialog({
                                title: 'Edit regional links',
                                width: '1000px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [
                                    {
                                        text: 'Cancel',
                                        click: () => {
                                            this.$regionLinksDialog.dialog('close');
                                        }
                                    },
                                    {
                                        text: 'Save',
                                        click: async () => {
                                            const $form = this.$regionLinksDialog.find('form');
                                            const form = $form[0];

                                            if (form.reportValidity == null || form.reportValidity()) {
                                                await this.regionLinksService.saveRegionData(form, this.image);

                                                this.$regionLinksDialog.dialog('close');
                                                await this.loadGridData();
                                            }
                                        }
                                    }
                                ],
                                close: () => {
                                    this.$regionLinksDialog.empty();
                                    this.image = null;
                                },
                                open: () => {
                                    this.$regionLinksDialog.find('#learningImage').on('change', (eventObject, args) => this.handleImage());
                                }
                            });
                        });

                        return $mainNode;
                    }
                }
            ]
        });
    }

    private initSearchForm() {
        const regions = this.regionLinksService.getRegions();
        this.$regionLinksSearch.html(regionLinksSearchFormTemplate({
            Regions: regions
        }));

        $('#regionLinksSearchButton').click(async () => {
            const $form = $('#regionLinksSearchForm');
            const form = <HTMLFormElement>$form[0];

            if (form.reportValidity == null || form.reportValidity()) {
                await this.loadGridData();

                // Since search probably results in less entries go back to page 1
                this.$regionLinksGrid.jsGrid({
                    pageIndex: 1
                });
            }
        });

        $('#regionLinksClearFilter').click(async () => {
            const $form = $('#regionLinksSearchForm');
            const form = <HTMLFormElement>$form[0];

            form.reset();
            await this.loadGridData();
        });
    }

    private async loadGridData() {
        const data = await this.regionLinksService.getRegionListData($('#regionLinksSearchRegion').val() as number);
        this.$regionLinksGrid.jsGrid({
            data: data
        });
    }

    private handleImage() {
        const file = (document.querySelector("input[type=file]") as HTMLInputElement).files[0];
        const reader = new FileReader();

        reader.addEventListener(
          "load",
          () => {
            // convert image file to base64 string
            this.image = reader.result.toString();
            (document.querySelector("#learningImage") as HTMLInputElement).src = this.image;
          },
          false,
        );

        if (file) {
          reader.readAsDataURL(file);
        }
    }
}
