<div *ngIf="pageLoadingService.isLoading" class="loading">
    <div class="bar">
        <div class="fill fill-1"></div>
        <div class="space"></div>
        <div class="fill fill-2"></div>
        <div class="space"></div>
        <div class="fill fill-3"></div>
        <div class="space"></div>
        <div class="fill fill-4"></div>
        <div class="space"></div>
        <div class="fill fill-5"></div>
    </div>
</div>
