import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiOptions, ApiService } from './api.service';
import { AppTitleService } from './app-title.service';


export interface ApplicationVersions {
    EngineeringVersion?: string;
    QuantityCalculatorVersion?: string;
    AdminVersion?: string;
    CommonModuleVersion?: string;
}

export interface ApplicationVersion {
    Version?: string;
}


@Injectable({
    providedIn: 'root'
})
export class ApplicationVersionsService {
    private applicationVersionLoaded = false;
    private serviceApplicationVersion?: string;


    constructor(
        private apiService: ApiService,
        private appTitleService: AppTitleService
    ) { }


    public async getApplicationVersions(apiOptions?: ApiOptions): Promise<ApplicationVersions> {
        const httpRequest = new HttpRequest('GET', `${environment.productInformationAdminWebServiceUrl}ApplicationVersions`);
        return await this.apiService.request<ApplicationVersions>(httpRequest, apiOptions);
    }

    public async updateApplicationVersions(request: ApplicationVersions, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('PUT', `${environment.productInformationAdminWebServiceUrl}ApplicationVersions`, request);
        await this.apiService.request(httpRequest, apiOptions);
    }

    public async getApplicationVersionFromService(forceUpdate = false, apiOptions?: ApiOptions) {
        if (this.applicationVersionLoaded && !forceUpdate) {
            return Promise.resolve();
        }

        try {
            const httpRequest = new HttpRequest('GET', `${environment.productInformationWebServiceUrl}ApplicationVersions/Admin`);

            const result = await this.apiService.request<ApplicationVersion>(httpRequest, apiOptions);
            const version = (result?.Version ?? '').trim();

            if (version != '') {
                this.serviceApplicationVersion = version;
            }
            else {
                this.serviceApplicationVersion = undefined;
            }
            this.applicationVersionLoaded = true;
        }
        catch (error) {
            console.error(error);
        }
    }

    public async setApplicationVersion(forceUpdate = false, apiOptions?: ApiOptions) {
        await this.getApplicationVersionFromService(forceUpdate, apiOptions);
        this.appTitleService.setApplicationVersion(this.serviceApplicationVersion);
        this.appTitleService.updatePageTitle();
    }
}
