import { Component } from '@angular/core';

import { AppTitleService } from '../../services/app-title.service';
import { DecodeUidService } from '../../services/decode-uid.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { Page } from '../page';
import { DecryptedMarketing } from '../../services/user-settings-api.service';

@Component({
    selector: 'app-decode-uid',
    templateUrl: './decode-uid.component.html',
    styleUrls: ['./decode-uid.component.css']
})
export class DecodeUidComponent extends Page {
    pageTitle = $localize`:app-title decode uid|Decode UID application title:Decryptor`;

    public over = false;

    constructor(
        protected appTitleService: AppTitleService,
        protected pageLoadingService: PageLoadingService,
        private decodeUidService: DecodeUidService
    ) {
        super(appTitleService, pageLoadingService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.decodeUidService.reset();

        // drag and drop file zone
        $('#drop-zone')
            .on('dragover', eventObject => {
                this.handleDragEvent(eventObject.originalEvent, true);
            })
            .on('dragleave', eventObject => {
                this.handleDragEvent(eventObject.originalEvent, false);
            })
            .on('drop', eventObject => {
                this.handleDragEvent(eventObject.originalEvent, false);
                this.decodeUidService.handleFileDropped(eventObject.originalEvent.dataTransfer.files, this.openGrid);
            });

        // click on hidden select file button
        $('#selectFileButton').on('click', () => {
            $('#js-upload-files').trigger('click');
        });

        // upload file when file input changes
        $('#js-upload-files').on('change', () => {
            const $fileInput = $('#js-upload-files');
            const fileInput = <HTMLInputElement>$fileInput[0];

            this.decodeUidService.handleFileChanged(fileInput.files[0], this.openGrid);
            $fileInput.val(null);
        });

        // submit ids from textarea
        $('#submit-ids').on('click', () => {
            (async() => {
                this.openGrid(await this.decodeUidService.getDecodedUsersData($('#text-ids').val() as string));
            })();
        });

        // copy to clipboard
        $('#copyButton').on('click', () => {
            const el = $('#copy');
            el.show().trigger('select');
            navigator.clipboard.writeText(el.val() as string);
            el.hide();
        });

        // export results to file
        $('#exportButton').on('click', () => {
            (async () => {
                await this.decodeUidService.exportDecodedUsersData($('#text-ids').val() as string);
            })();
        });
    }

    private handleDragEvent(eventObject: Event, over: boolean) {
        eventObject.preventDefault();
        eventObject.stopPropagation();

        this.over = over;
    }

    private openGrid(data: DecryptedMarketing[]) {
        const titleRow = ['Tracking ID (Hashed)', 'UID (decrypted ID)', 'Email', 'License type', 'Language', 'Tracking'];

        // add numbering
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            item.ranking = i + 1;

            if (item.licenseType != null && item.licenseTypeOffline != null)
                item.licenseType = item.licenseType + ', ' + item.licenseTypeOffline;
            else
                item.licenseType = item.licenseType || item.licenseTypeOffline;
        }

        $('#jsGridDecrypted').jsGrid({
            width: '100%',

            pagerContainer: '#externalPager',
            pagerFormat: 'Current page: {pageIndex} / {pageCount} &nbsp;&nbsp; {first} {prev} {pages} {next} {last}',

            sorting: true,
            paging: true,
            pageSize: 50,
            pagePrevText: '<',
            pageNextText: '>',
            pageFirstText: '<<',
            pageLastText: '>>',


            data: data,
            fields: [
                { name: 'ranking', type: 'number', title: 'Ranking', width: 50 },
                { name: 'encryptedUID', type: 'text', title: titleRow[0] },
                { name: 'userID', type: 'text', title: titleRow[1] },
                { name: 'username', type: 'text', title: titleRow[2] },
                { name: 'licenseType', type: 'text', title: titleRow[3] },
                { name: 'languagePreference', type: 'text', title: titleRow[4] },
                { name: 'tracking', type: 'text', title: titleRow[5] }
            ]
        });

        // prepare data to be copied to clipboard
        $('#copy').val(
            data.reduce(
                (val, row) => {
                    return val + (row.encryptedUID || '') + '\t' + (row.userID || '') + '\t' + (row.username || '') + '\t' +
                        (row.licenseType || '') + '\t' + (row.languagePreference || '') + '\t' + (row.tracking || '') + '\n';
                },
                titleRow.join('\t') + '\n'
            )
        );

        $('#exportButton').show();
        $('#copyButton').show();
        $('#resultCount').html(data.length + ' results');
    }
}
