var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"LearningImage") || (depth0 != null ? lookupProperty(depth0,"LearningImage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"LearningImage","hash":{},"data":data,"loc":{"start":{"line":76,"column":28},"end":{"line":76,"column":45}}}) : helper)))
    + "\" class=\"learning-image\"/>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <hr/>\n    <fieldset form=\"regionLinksEditForm\" name=\"translations-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":98,"column":60},"end":{"line":98,"column":68}}}) : helper)))
    + "\">\n        <input id=\"regionId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":99,"column":28},"end":{"line":99,"column":36}}}) : helper)))
    + "\" name=\"regionId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":99,"column":53},"end":{"line":99,"column":61}}}) : helper)))
    + "\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionId") || (depth0 != null ? lookupProperty(depth0,"RegionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionId","hash":{},"data":data,"loc":{"start":{"line":99,"column":84},"end":{"line":99,"column":96}}}) : helper)))
    + "\" />\n        <input id=\"lcid-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":100,"column":24},"end":{"line":100,"column":32}}}) : helper)))
    + "\" name=\"lcid-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":100,"column":45},"end":{"line":100,"column":53}}}) : helper)))
    + "\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":100,"column":76},"end":{"line":100,"column":84}}}) : helper)))
    + "\" />\n\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"languageName-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":103,"column":69},"end":{"line":103,"column":77}}}) : helper)))
    + "\">Language</label>\n            <div class=\"col-sm-9\">\n                <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"LanguageName") || (depth0 != null ? lookupProperty(depth0,"LanguageName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LanguageName","hash":{},"data":data,"loc":{"start":{"line":105,"column":40},"end":{"line":105,"column":56}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"upgradeToPremiumText-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":109,"column":77},"end":{"line":109,"column":85}}}) : helper)))
    + "\">Upgrade to premium</label>\n            <div class=\"col-sm-9\">\n                <input type=\"text\" class=\"form-control note-input\" id=\"upgradeToPremiumText-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":111,"column":92},"end":{"line":111,"column":100}}}) : helper)))
    + "\" name=\"upgradeToPremiumText-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":111,"column":129},"end":{"line":111,"column":137}}}) : helper)))
    + "\"\n                    value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"UpgradeToPremiumText") || (depth0 != null ? lookupProperty(depth0,"UpgradeToPremiumText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UpgradeToPremiumText","hash":{},"data":data,"loc":{"start":{"line":112,"column":27},"end":{"line":112,"column":51}}}) : helper)))
    + "\" maxlength=\"255\">\n            </div>\n        </div>\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"upgradeToPremiumHeaderText-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":116,"column":83},"end":{"line":116,"column":91}}}) : helper)))
    + "\">Upgrade to premium in header</label>\n            <div class=\"col-sm-9\">\n                <input type=\"text\" class=\"form-control note-input\" id=\"upgradeToPremiumHeaderText-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":118,"column":98},"end":{"line":118,"column":106}}}) : helper)))
    + "\" name=\"upgradeToPremiumHeaderText-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LCID") || (depth0 != null ? lookupProperty(depth0,"LCID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LCID","hash":{},"data":data,"loc":{"start":{"line":118,"column":141},"end":{"line":118,"column":149}}}) : helper)))
    + "\"\n                    value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"UpgradeToPremiumHeaderText") || (depth0 != null ? lookupProperty(depth0,"UpgradeToPremiumHeaderText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UpgradeToPremiumHeaderText","hash":{},"data":data,"loc":{"start":{"line":119,"column":27},"end":{"line":119,"column":57}}}) : helper)))
    + "\" maxlength=\"255\">\n            </div>\n        </div>\n    </fieldset>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"regionLinksEditForm\">\n    <input id=\"regionId\" name=\"regionId\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionId") || (depth0 != null ? lookupProperty(depth0,"RegionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionId","hash":{},"data":data,"loc":{"start":{"line":2,"column":62},"end":{"line":2,"column":74}}}) : helper)))
    + "\" />\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"regionName\">Region</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionName") || (depth0 != null ? lookupProperty(depth0,"RegionName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionName","hash":{},"data":data,"loc":{"start":{"line":7,"column":36},"end":{"line":7,"column":50}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n\n    <hr/>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"buyLicenseHolLink\">Buy license HOL page link</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"buyLicenseHolLink\" name=\"buyLicenseHolLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"BuyLicenseHolLink") || (depth0 != null ? lookupProperty(depth0,"BuyLicenseHolLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BuyLicenseHolLink","hash":{},"data":data,"loc":{"start":{"line":17,"column":23},"end":{"line":17,"column":44}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"licenseComparisonPageLink\">License comparison page link</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"licenseComparisonPageLink\" name=\"licenseComparisonPageLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LicenseComparisonPageLink") || (depth0 != null ? lookupProperty(depth0,"LicenseComparisonPageLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LicenseComparisonPageLink","hash":{},"data":data,"loc":{"start":{"line":24,"column":23},"end":{"line":24,"column":52}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"manageAccountLink\">Manage account link</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"manageAccountLink\" name=\"manageAccountLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ManageAccountLink") || (depth0 != null ? lookupProperty(depth0,"ManageAccountLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ManageAccountLink","hash":{},"data":data,"loc":{"start":{"line":31,"column":23},"end":{"line":31,"column":44}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"softwareSellingLink\">Software selling link</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"softwareSellingLink\" name=\"softwareSellingLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"SoftwareSellingLink") || (depth0 != null ? lookupProperty(depth0,"SoftwareSellingLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SoftwareSellingLink","hash":{},"data":data,"loc":{"start":{"line":38,"column":23},"end":{"line":38,"column":46}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"upgradeToPremiumLink\">Upgrade to premium link</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"upgradeToPremiumLink\" name=\"upgradeToPremiumLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"UpgradeToPremiumLink") || (depth0 != null ? lookupProperty(depth0,"UpgradeToPremiumLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UpgradeToPremiumLink","hash":{},"data":data,"loc":{"start":{"line":45,"column":23},"end":{"line":45,"column":47}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"upgradeToPremiumHeaderLink\">Upgrade to premium in header</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"upgradeToPremiumHeaderLink\" name=\"upgradeToPremiumHeaderLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"UpgradeToPremiumHeaderLink") || (depth0 != null ? lookupProperty(depth0,"UpgradeToPremiumHeaderLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UpgradeToPremiumHeaderLink","hash":{},"data":data,"loc":{"start":{"line":52,"column":23},"end":{"line":52,"column":53}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n\n    <hr/>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"askHiltiLink\">Ask Hilti link</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"askHiltiLink\" name=\"askHiltiLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"AskHiltiLink") || (depth0 != null ? lookupProperty(depth0,"AskHiltiLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AskHiltiLink","hash":{},"data":data,"loc":{"start":{"line":62,"column":23},"end":{"line":62,"column":39}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"betLearningLink\">BET learning link</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"betLearningLink\" name=\"betLearningLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"BetLearningLink") || (depth0 != null ? lookupProperty(depth0,"BetLearningLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BetLearningLink","hash":{},"data":data,"loc":{"start":{"line":69,"column":23},"end":{"line":69,"column":42}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n     <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"learningImage\">Learning Image (Image size MUST be 238x96)</label>\n        <div class=\"col-sm-9\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LearningImage") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":13},"end":{"line":77,"column":20}}})) != null ? stack1 : "")
    + "             <input type=\"file\" id=\"learningImage\" name=\"image\" accept=\"image/*\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LearningImage") || (depth0 != null ? lookupProperty(depth0,"LearningImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LearningImage","hash":{},"data":data,"loc":{"start":{"line":78,"column":88},"end":{"line":78,"column":105}}}) : helper)))
    + "\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"handrailCBFEMLearnMoreLink\">Handrail CBFEM learn more link</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"handrailCBFEMLearnMoreLink\" name=\"handrailCBFEMLearnMoreLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"HandrailCBFEMLearnMoreLink") || (depth0 != null ? lookupProperty(depth0,"HandrailCBFEMLearnMoreLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HandrailCBFEMLearnMoreLink","hash":{},"data":data,"loc":{"start":{"line":85,"column":23},"end":{"line":85,"column":53}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"perfectSpecLink\">Perfect Spec link</label>\n        <div class=\"col-sm-9\">\n            <input type=\"url\" class=\"form-control note-input\" id=\"perfectSpecLink\" name=\"perfectSpecLink\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"PerfectSpecLink") || (depth0 != null ? lookupProperty(depth0,"PerfectSpecLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PerfectSpecLink","hash":{},"data":data,"loc":{"start":{"line":92,"column":23},"end":{"line":92,"column":42}}}) : helper)))
    + "\" maxlength=\"255\">\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"RegionLinksTranslations") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":4},"end":{"line":123,"column":13}}})) != null ? stack1 : "")
    + "</form>\n";
},"useData":true});