import { QueryStringHelper } from '../app/query-string';
import type schema from './environment.local.json';

export type Environment = typeof schema & {
    authenticationRequired: string[];
    baseUrl: string;
};

export const environment: Environment = getEnvironmentSettings();

const qsHelper = new QueryStringHelper(window.location.search);

// setBaseUrl
environment.baseUrl = document.getElementsByTagName('base')[0].href;

environment.authenticationRequired = [
    environment.userSettingsWebServiceUrl,
    environment.userSettingsWebServiceAdminUrl,
    environment.documentWebServiceUrl,
    environment.documentWebServiceAdminUrl,
    environment.productInformationWebServiceUrl,
    environment.productInformationAdminWebServiceUrl,
    environment.adminWebServicesUrl,
    environment.baseplateCalculationWebServiceUrl
].filter(x => x);

/**
 * Get environment settings and update service version (v1 or v2). Required for blue green deployment.
 * Supported environments
 * - Ireland: dev
 * - China: stage and prod
 */
function getEnvironmentSettings(): Environment {
    const host = window.location.host;
    const v1Hosts: string[] = ['profis3admin-d.hilti.com', 'profis3admin-qa.hilti.com', 'profis3admin-q.hilti.com', 'profis3admin.hilti.com', 'profis3admin-q.hilti.cn', 'profis3admin.hilti.cn', 'profis3admin-s.hilti.com', 'profis3admin-hf.hilti.com'];
    const v2Hosts: string[] = ['profis3admin-d2.hilti.com', 'profis3admin-qa2.hilti.com', 'profis3admin-q2.hilti.com', 'profis3admin2.hilti.com', 'profis3admin-q2.hilti.cn', 'profis3admin2.hilti.cn', 'profis3admin-s2.hilti.com'];

    let serviceVersion = '';
    if (v1Hosts.some(x => x == host)) {
        serviceVersion = 'v1';
    }
    else if (v2Hosts.some(x => x == host)) {
        serviceVersion = 'v2';
    }

    const env: Environment = (window as any).environment;
    if (serviceVersion != '') {
        env.userSettingsWebServiceUrl = formatServiceVersion(env.userSettingsWebServiceUrl, serviceVersion);
        env.userSettingsWebServiceAdminUrl = formatServiceVersion(env.userSettingsWebServiceAdminUrl, serviceVersion);
        env.documentWebServiceUrl = formatServiceVersion(env.documentWebServiceUrl, serviceVersion);
        env.documentWebServiceAdminUrl = formatServiceVersion(env.documentWebServiceAdminUrl, serviceVersion);
        env.productInformationWebServiceUrl = formatServiceVersion(env.productInformationWebServiceUrl, serviceVersion);
        env.productInformationAdminWebServiceUrl = formatServiceVersion(env.productInformationAdminWebServiceUrl, serviceVersion);
        env.adminWebServicesUrl = formatServiceVersion(env.adminWebServicesUrl, serviceVersion);
        env.baseplateCalculationWebServiceUrl = formatServiceVersion(env.baseplateCalculationWebServiceUrl, serviceVersion);
    }

    return env ?? emptyEnvironmentSettings();
}

function formatServiceVersion(url: string, serviceVersion: string) {
    return format(url, '{service_version}', serviceVersion);
}

function format(value: string, searchValue: string, replaceValue: string) {
    if (value == null) {
        return null;
    }

    return value.replace(searchValue, replaceValue);
}

/** for unit tests */
function emptyEnvironmentSettings(): Environment {
    return {
        version: "1.0.0",
        production: false,
        solutionName: '',
        defaultLanguage: '',
        applicationVersion: '',
        includeHCHeaders: false,
        userSettingsWebServiceUrl: 'http://localhost/api/usersettingsservice',
        userSettingsWebServiceAdminUrl: '',
        documentWebServiceUrl: '',
        documentWebServiceAdminUrl: '',
        productInformationWebServiceUrl: '',
        productInformationAdminWebServiceUrl: '',
        adminWebServicesUrl: '',
        baseplateCalculationWebServiceUrl: '',
        externalAuthenticationUrl: '',
        externalAuthorize: '',
        externalTokens: '',
        externalLogout: '',
        externalClientId: '',
        authentication: '',
        enableDevelopmentApi: false,
        authenticationRequired: [],
        baseUrl: ''
    };
}
