import 'jquery-ui/ui/widgets/dialog';
import 'jquery-ui/ui/widgets/datepicker';
import 'bootstrap';
import 'chosen-js';
import 'jsgrid';

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AnchorAiComponent } from '../components/anchor-ai/anchor-ai.component';
import { AppComponent } from '../components/app/app.component';
import { ApplicationVersionsComponent } from '../components/application-versions/application-versions.component';
import { CheckbotPluginComponent } from '../components/checkbot-plugin/checkbot-plugin.component';
import { DecodeUidComponent } from '../components/decode-uid/decode-uid.component';
import { DevelopmentApiComponent } from '../components/development-api/development-api.component';
import { ErrorComponent } from '../components/error/error.component';
import { ExportDesignsComponent } from '../components/export-designs/export-designs.component';
import { GdprComponent } from '../components/gdpr/gdpr.component';
import {
    HiltiApplicationsComponent
} from '../components/hilti-applications/hilti-applications.component';
import { HomeComponent } from '../components/home/home.component';
import {
    MarketingCampaignComponent
} from '../components/marketing-campaign/marketing-campaign.component';
import { NoopComponent } from '../components/noop/noop.component';
import { PageLoadingComponent } from '../components/page-loading/page-loading.component';
import {
    ProjectManagementComponent
} from '../components/project-management/project-management.component';
import { RegionLinksComponent } from '../components/region-links/region-links.component';
import { ReleaseNotesComponent } from '../components/release-notes/release-notes.component';
import { UserAgreementComponent } from '../components/user-agreement/user-agreement.component';
import { VersionComponent } from '../components/version/version.component';
import { httpInterceptorProviders } from '../http-interceptors';
import { DateField } from '../jsgrid-fields';
import { AppTitleService } from '../services/app-title.service';
import { AppRoutingModule } from './app-routing.module';

// bootstrap no conflict
$.fn['bootstrapBtn'] = $.fn.button['noConflict']();

// jsgrid bug
window['args'] = undefined;

// jsgrid fields
jsGrid.fields.date = DateField;

@NgModule({
    declarations: [
        AnchorAiComponent,
        AppComponent,
        ApplicationVersionsComponent,
        CheckbotPluginComponent,
        DecodeUidComponent,
        DevelopmentApiComponent,
        ErrorComponent,
        ExportDesignsComponent,
        GdprComponent,
        HiltiApplicationsComponent,
        HomeComponent,
        MarketingCampaignComponent,
        NoopComponent,
        PageLoadingComponent,
        ProjectManagementComponent,
        RegionLinksComponent,
        ReleaseNotesComponent,
        UserAgreementComponent,
        VersionComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule
    ],
    providers: [
        Title,
        httpInterceptorProviders
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(appTitleService: AppTitleService) {
        appTitleService.setAppTitle();
    }
}
