var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"hiltiApplicationsPreview\">\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"ApplicationName\">Application name</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ApplicationName") || (depth0 != null ? lookupProperty(depth0,"ApplicationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ApplicationName","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":55}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"ApplicationSubtitle\">Application subtitle</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ApplicationSubtitle") || (depth0 != null ? lookupProperty(depth0,"ApplicationSubtitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ApplicationSubtitle","hash":{},"data":data,"loc":{"start":{"line":12,"column":36},"end":{"line":12,"column":59}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n\n    <hr/>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"Image\">Image</label>\n        <div class=\"col-sm-9\">\n            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Image") || (depth0 != null ? lookupProperty(depth0,"Image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Image","hash":{},"data":data,"loc":{"start":{"line":21,"column":22},"end":{"line":21,"column":31}}}) : helper)))
    + "\"/>\n        </div>\n    </div>\n\n    <hr/>\n\n     <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"ShowNewLabel\">Show new label</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ShowNewLabel") || (depth0 != null ? lookupProperty(depth0,"ShowNewLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ShowNewLabel","hash":{},"data":data,"loc":{"start":{"line":30,"column":36},"end":{"line":30,"column":52}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"Active\">Active</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Active") || (depth0 != null ? lookupProperty(depth0,"Active") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Active","hash":{},"data":data,"loc":{"start":{"line":37,"column":36},"end":{"line":37,"column":46}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"ActivatedPerHub\">Activated per-hub for all users</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ActivatedPerHub") || (depth0 != null ? lookupProperty(depth0,"ActivatedPerHub") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ActivatedPerHub","hash":{},"data":data,"loc":{"start":{"line":44,"column":36},"end":{"line":44,"column":55}}}) : helper)))
    + "</p>\n            <p class=\"note-display small\">(if <code>true</code> activated per hub, for all users; if <code>false</code> activated for specified emails only!)</p>\n        </div>\n    </div>\n</form>\n";
},"useData":true});