import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ErrorGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // do not wrap this method with handleGuardAction or you will get an infinite loop
        // handleGuardAction => error => ErrorGuard => handleGuardAction => error => ErrorGuard => handleGuardAction => ...

        // the error route should only be shown on other urls
        // /error route will be redirected to /
        // use router.navigateByUrl('/error', { skipLocationChange: true }) to show the error page without changing the url
        if (!this.router.getCurrentNavigation().extras.skipLocationChange) {
            return this.router.parseUrl('/');
        }

        return true;
    }
}
