var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"previewReleaseNoteTemplate\">\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"noteTitle\">Title</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NoteTitle") || (depth0 != null ? lookupProperty(depth0,"NoteTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NoteTitle","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":49}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"releaseVersion\">Release version</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ReleaseVersion") || (depth0 != null ? lookupProperty(depth0,"ReleaseVersion") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ReleaseVersion","hash":{},"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":54}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"releaseDate\">Release date</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ReleaseDate") || (depth0 != null ? lookupProperty(depth0,"ReleaseDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ReleaseDate","hash":{},"data":data,"loc":{"start":{"line":17,"column":36},"end":{"line":17,"column":51}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"noteContent\">Content</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NoteContent") || (depth0 != null ? lookupProperty(depth0,"NoteContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NoteContent","hash":{},"data":data,"loc":{"start":{"line":23,"column":36},"end":{"line":23,"column":51}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n</form>\n";
},"useData":true});