<div class="main-div">
    <div class="header">
        <h2 class="md-3">Quickstart external applications</h2>
        <button (click)="addApp()" type="button" name="addApp" class="btn btn-primary float-right" id="addAppButton">Add Application</button>
    </div>

    <div id="externalAppsGrid"></div>
</div>

<div id="deleteExternalAppDialog">
    Are you sure you want to delete the external app?
</div>

<div id="externalAppsDialog" title="External Applications">

<div id="externalAppsLinksDialog" title="External Application Links">
</div>

