<h2 class="mb-3">Anchor.AI</h2>

<h4>User Access</h4>
<textarea class="user-access-textarea" [(ngModel)]="emails"></textarea>

<p>Can take up to one minute for changes to be visible in PROFIS Engineering.</p>

<button type="button" class="save btn btn-primary" (click)="save()">Save</button>
<br/>
<br/>
<h4>Regions</h4>
<div id="anchoraiRegionForm"></div>
<button type="button" class="save btn btn-primary" (click)="saveRegion()">Save</button>
