import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CodeListsService } from './code-lists.service';
import { ProductInformationApiService, RegionLinks, RegionLinksModel, RegionLinksTranslationsModel } from './product-information-api.service';

export interface RegionLinksWithName extends RegionLinks {
    RegionName: string;
}

export interface RegionLinksUiModel extends RegionLinksWithName {
    RegionLinksTranslations: RegionLinksTranslationsUiModel[];
}

export interface RegionLinksTranslationsUiModel extends RegionLinksTranslationsModel {
    LanguageName: string;
}

@Injectable({
    providedIn: 'root'
})
export class RegionLinksService {
    constructor(
        private productInformationApiService: ProductInformationApiService,
        private codeListsService: CodeListsService
    ) { }


    public getRegions() {
        const regions = this.codeListsService.regions
            .sort((r1, r2) => {
                if (r1.Name > r2.Name) {
                    return 1;
                }

                if (r1.Name < r2.Name) {
                    return -1;
                }

                return 0;
            });
        return regions;
    }


    public async getRegionListData(regionId: number): Promise<RegionLinksWithName[]>{
        const srcData = await this.productInformationApiService.regionLinksListRegionLinks({
            RegionId: regionId
        });

        const data = srcData
            .map(d => {
                return {
                    RegionId: d.RegionId,
                    RegionName: this.findRegionName(d.RegionId),
                    AskHiltiLink: d.AskHiltiLink,
                    BetLearningLink: d.BetLearningLink,
                    BuyLicenseHolLink: d.BuyLicenseHolLink,
                    HandrailCBFEMLearnMoreLink: d.HandrailCBFEMLearnMoreLink,
                    LearningImage: d.LearningImage,
                    LicenseComparisonPageLink: d.LicenseComparisonPageLink,
                    ManageAccountLink: d.ManageAccountLink,
                    PerfectSpecLink: d.PerfectSpecLink,
                    SoftwareSellingLink: d.SoftwareSellingLink,
                    UpgradeToPremiumHeaderLink: d.UpgradeToPremiumHeaderLink,
                    UpgradeToPremiumLink: d.UpgradeToPremiumLink
                };
            })
            .sort((r1, r2) => {
                if (r1.RegionName > r2.RegionName) {
                    return 1;
                }

                if (r1.RegionName < r2.RegionName) {
                    return -1;
                }

                return 0;
            });
        return data;
    }

    public async getRegionData(regionId: number): Promise<RegionLinksUiModel> {
        const initialData = await this.productInformationApiService.regionLinksGetRegionLinks(regionId);
        return {
            RegionId: initialData.RegionId,
            RegionName: this.findRegionName(initialData.RegionId),
            LicenseComparisonPageLink: initialData.LicenseComparisonPageLink,
            BuyLicenseHolLink: initialData.BuyLicenseHolLink,
            UpgradeToPremiumLink: initialData.UpgradeToPremiumLink,
            UpgradeToPremiumHeaderLink: initialData.UpgradeToPremiumHeaderLink,
            HandrailCBFEMLearnMoreLink: initialData.HandrailCBFEMLearnMoreLink,
            SoftwareSellingLink: initialData.SoftwareSellingLink,
            ManageAccountLink: initialData.ManageAccountLink,
            BetLearningLink: initialData.BetLearningLink,
            LearningImage : initialData.LearningImage,
            AskHiltiLink: initialData.AskHiltiLink,
            PerfectSpecLink: initialData.PerfectSpecLink,
            RegionLinksTranslations: initialData.RegionLinksTranslations.map(x => {
                return {
                    RegionId: x.RegionId,
                    LCID: x.LCID,
                    LanguageName: this.codeListsService.languages.find(l => l.Id == x.LCID).DisplayName,
                    UpgradeToPremiumText: x.UpgradeToPremiumText,
                    UpgradeToPremiumHeaderText: x.UpgradeToPremiumHeaderText
                };
            })
        }
    }

    public async saveRegionData(form: HTMLFormElement, image: string) {
        const data: RegionLinksModel = {
            RegionId: parseInt(form.elements['regionId'].value),
            LicenseComparisonPageLink: form.elements['licenseComparisonPageLink'].value,
            BuyLicenseHolLink: form.elements['buyLicenseHolLink'].value,
            UpgradeToPremiumLink: form.elements['upgradeToPremiumLink'].value,
            UpgradeToPremiumHeaderLink: form.elements['upgradeToPremiumHeaderLink'].value,
            HandrailCBFEMLearnMoreLink: form.elements['handrailCBFEMLearnMoreLink'].value,
            SoftwareSellingLink: form.elements['softwareSellingLink'].value,
            ManageAccountLink: form.elements['manageAccountLink'].value,
            BetLearningLink: form.elements['betLearningLink'].value,
            LearningImage: image,
            AskHiltiLink: form.elements['askHiltiLink'].value,
            PerfectSpecLink: form.elements['perfectSpecLink'].value,
            RegionLinksTranslations: []
        };

        const formElements = Array.from(form.elements) as HTMLFormElement[];
        for (let element of formElements) {
            if (element.name.startsWith('translations')) {
                const fieldNameAppendix = element.name.replace('translations', '');
                const translationsData: RegionLinksTranslationsModel = {
                    RegionId: parseInt(element.elements[`regionId${fieldNameAppendix}`].value),
                    LCID: parseInt(element.elements[`lcid${fieldNameAppendix}`].value),
                    UpgradeToPremiumText: element.elements[`upgradeToPremiumText${fieldNameAppendix}`].value,
                    UpgradeToPremiumHeaderText: element.elements[`upgradeToPremiumHeaderText${fieldNameAppendix}`].value
                };
                data.RegionLinksTranslations.push(translationsData)
            }
        }

        await this.productInformationApiService.regionLinksEditRegionLinks(data);
    }


    private findRegionName(regionId: number) {
        return this.codeListsService.regions.find(x => x.Id == regionId).Name;
    }
}
