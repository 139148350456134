import { Injectable } from '@angular/core';

import { ProductInformationApiService } from './product-information-api.service';

export interface CodeListsEntity {
    Id: number;
    Name: string;
}

export interface RegionEntity extends CodeListsEntity {
    HubId: number;
}
export interface RegionLanguageEntity extends CodeListsEntity {
    RegionId: number;
    LcId: number;
    DefaultForRegion: boolean;
}

export interface HubEntity extends CodeListsEntity {
}

export interface LanguageEntity extends CodeListsEntity {
    DisplayName: string;
}

export interface HiltiApplicationEntity extends CodeListsEntity {
    DisplayKey: string;
    SubtitleKey: string;
    AllUsers: boolean;
    Active: boolean;
    Url: string;
    ShowNewLabel: boolean;
}

enum InitStatus {
    None,
    InProgress,
    Done
}

@Injectable({
    providedIn: 'root'
})
export class CodeListsService {
    constructor(private productInformationApiService: ProductInformationApiService) { }

    private loadDataPromise: Promise<boolean>;

    private _regions: RegionEntity[];
    public get regions() {
        return this._regions;
    }

    private _regionLanguages: RegionLanguageEntity[];
    public get regionLanguages() {
        return this._regionLanguages;
    }

    private _hubs: HubEntity[];
    public get hubs() {
        return this._hubs;
    }

    private _languages: LanguageEntity[];
    public get languages() {
        return this._languages;
    }

    private _hiltiApplications: HiltiApplicationEntity[];
    public get hiltiApplications() {
        return this._hiltiApplications;
    }

    public async initialize(): Promise<boolean> {
        if (this.loadDataPromise != null){
            return await this.loadDataPromise;
        }

        this.loadDataPromise = this.loadData();
        try {
            return await this.loadDataPromise;
        }
        catch (ex) {
            this.loadDataPromise = null;
            throw ex;
        }
    }

    private async loadData(): Promise<boolean> {
        const response = await this.productInformationApiService.getCodeLists();
        this._regions = response.Regions;
        this._regionLanguages = response.RegionLanguages;
        this._hubs = response.Hubs;
        this._languages = response.Languages;
        this._hiltiApplications = response.HiltiApplications;
        return true;
    }
}
