import { tap } from 'rxjs/operators';

import { HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiHttpRequest } from '../services/api.service';
import { ModalService } from '../services/modal.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private modalService: ModalService) { }

    intercept(req: ApiHttpRequest<any>, next: HttpHandler) {
        if (req.supressErrorMessage) {
            return next.handle(req);
        }

        try {
            return next.handle(req)
                .pipe(
                    tap(event => undefined, error => this.modalService.showServiceErrorMessage(req, error))
                );
        }
        catch (error) {
            this.modalService.showServiceErrorMessage(req, error);

            throw error;
        }
    }
}
