import escape from 'lodash-es/escape';

import { Component } from '@angular/core';

import { getDateFromUnix } from '../../date';
import { DesignType } from '../../entities/modules-entities';
import { AppTitleService } from '../../services/app-title.service';
import { DocumentApiService, Project } from '../../services/document-api.service';
import { ModalService } from '../../services/modal.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { Page } from '../page';
import previewProjectTemplate from './preview-project.hbs';
import shareProjectTemplate from './share-project.hbs';

interface GridData {
    Ranking: number;
    ProjectId: string;
    ProjectName: string;
    Owner: string;
    DateCreated: Date;
    DateModified: Date;
    IsDraft: boolean;
    Checked: boolean;
}

@Component({
    selector: 'app-project-management',
    templateUrl: './project-management.component.html',
    styleUrls: ['./project-management.component.css']
})
export class ProjectManagementComponent extends Page {
    pageTitle = $localize`:app-title project management|Project management application title:Project Management`;

    userEmail: string;
    projectName: string;
    designName: string;

    get designType(): DesignType[] {
        return (($('#designType').chosen().val() as string[]) ?? []).map(x => parseInt(x, 10) as DesignType);
    }

    get createdDateFrom() {
        return $('#createdDateFrom').val() as string;
    }

    get createdDateTo() {
        return $('#createdDateTo').val() as string;
    }

    private $grid: JQuery<HTMLElement>;
    private $projectManagementDialog: JQuery<HTMLElement>;

    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private documentApiService: DocumentApiService,
        private modalService: ModalService
    ) {
        super(appTitleService, pageLoadingService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.$grid = $('#jsGridProjectManagement');
        this.$projectManagementDialog = $('#projectManagementDialog');

        $('#designType').chosen();

        $('#createdDateFrom').datepicker({
            dateFormat: 'yy-mm-dd',
            minDate: new Date(1970, 0, 1)
        });

        $('#createdDateTo').datepicker({
            dateFormat: 'yy-mm-dd',
            minDate: new Date(1970, 0, 1)
        });

        this.initGrid();
    }

    search() {
        const form = <HTMLFormElement>$('#searchForm')[0];

        if (form.reportValidity == null || form.reportValidity()) {
            this.loadGridData();

            // Since search probably results in less entries go back to page 1
            this.$grid.jsGrid({
                pageIndex: 1
            });
        }
    }

    clear() {
        const form = <HTMLFormElement>$('#searchForm')[0];

        form.reset();
    }

    async removeUserForSelectedProjects() {
        const gridData: GridData[] = this.$grid.data('JSGrid').data ?? [];

        if (gridData.some(item => item.IsDraft && item.Checked)) {
            this.modalService.showMessage('Users cannot be added or removed for draft projects. Please remove them from the selection.', 'Draft project selected');
            return;
        }

        const checkedItems = gridData.filter(item => item.Checked);
        const checkedProjectIds = checkedItems.map(item => item.ProjectId);

        // If user is specified unshare the projects only for him, else for all but owner.
        const userSpecified = this.userEmail;

        if (checkedProjectIds.length == 0) {
            this.modalService.showMessage('There are no projects selected in the grid.', 'No project selected');
            return;
        }

        if (await this.modalService.showConfirmation('Are you sure you want to unshare the selected projects?', 'Unshare projects')) {
            await this.unshareProjects(checkedProjectIds, userSpecified);
        }
    }

    shareSelectedProjectsForUser() {
        const gridData: GridData[] = this.$grid.data('JSGrid').data ?? [];

        if (gridData.some(item => item.IsDraft && item.Checked)) {
            this.modalService.showMessage('Users cannot be added or removed for draft projects. Please remove them from the selection.', 'Draft project selected');
            return;
        }

        const checkedItems = gridData.filter(item => item.Checked);
        const checkedProjectIds = checkedItems.map(item => item.ProjectId);

        if (checkedProjectIds.length == 0) {
            this.modalService.showMessage('There are no projects selected in the grid.', 'No project selected');
            return;
        }

        this.shareProjects(checkedProjectIds);
    }

    async removeUserProjectInfo(element: HTMLElement) {
        const projectId = element.closest('[project-id]').getAttribute('project-id');
        const userName = element.closest('[user-name]') .getAttribute('user-name');

        if (await this.modalService.showConfirmation('Are you sure you want to remove the selected user?', 'Remove user from project')) {
            this.removeUsersFromProjects(projectId, [userName]);
        }
    }

    private initGrid() {
        let selectAll = false;
        this.$grid.jsGrid({
            width: '100%',
            height: 'auto',
            sorting: true,
            paging: true,
            pageSize: 20,
            fields: [
                { name: 'ProjectId', type: 'text', visible: false, itemTemplate: escape },
                {
                    name: 'Selected',
                    align: 'center',
                    itemTemplate: (value: boolean, item: GridData) => {
                        return $('<input>')
                            .attr('type', 'checkbox')
                            .prop('checked', value || item.Checked || false)
                            .on('change', (eventObject) => {
                                item.Checked = $(eventObject.target).is(':checked');
                            });
                    },
                    headerTemplate: () => {
                        return $('<input>')
                            .attr('type', 'checkbox')
                            .prop('checked', selectAll)
                            .on('change', (eventObject) => {
                                selectAll = $(eventObject.target).is(':checked');

                                const data: GridData[] = this.$grid.data('JSGrid').data;

                                for (const item of data) {
                                    item.Checked = selectAll
                                }

                                this.$grid.jsGrid('refresh');
                            });
                    },
                    sorting: false
                },
                { name: 'Ranking', type: 'number', width: 100, title: 'Ranking', align: 'left', itemTemplate: escape },
                { name: 'ProjectName', type: 'text', width: 200, title: 'Project name', align: 'left', itemTemplate: escape },
                { name: 'Owner', type: 'text', width: 200, title: 'Owner', align: 'left', itemTemplate: escape },
                { name: 'DateCreated', type: 'date', width: 150, title: 'Creation date', align: 'left' },
                { name: 'DateModified', type: 'date', width: 150, title: 'Last modified', align: 'left' },
                { name: 'IsDraft', type: 'boolean', visible: false },
                {
                    type: 'control',
                    width: 150,
                    headerTemplate: () => 'Actions',
                    itemTemplate: (value: void, item: GridData) => {
                        const $mainNode = $('<div>').addClass('js-grid-actions');
                        const $viewNode = $('<span>').addClass('sprite d-inline-block sprite-view').attr('title', 'View project info').appendTo($mainNode);
                        const $shareNode = $('<span>').addClass('sprite d-inline-block sprite-share').attr('title', 'Share project with users').appendTo($mainNode);
                        const $deleteNode = $('<span>').addClass('sprite d-inline-block sprite-delete').attr('title', 'Remove project for user').appendTo($mainNode);

                        if (item.IsDraft) {
                            $shareNode.addClass('sprite-disabled');
                            $deleteNode.addClass('sprite-disabled');
                        }

                        $viewNode.click(async () => {
                            const data = await this.documentApiService.adminGetProjectInfo({ projectId: item.ProjectId });

                            const compiledHtml = previewProjectTemplate({
                                ProjectId: item.ProjectId,
                                Owner: item.Owner,
                                Users: data.users.map(user => ({
                                    UserId: user.userid,
                                    User: user.name
                                })),
                                Documents: data.documents.map(document => {
                                    const designTypeMetadata = document.metadata?.find(metadata => metadata.key == 'designType');
                                    const designTypeName = designTypeMetadata != null ? <string>DesignType[designTypeMetadata.value] : 'Unknown';

                                    return {
                                        Name: document.name,
                                        Type: designTypeName,
                                        Created: getDateFromUnix(document.created).toLocaleDateString('de-DE'),
                                        LockedBy: document.lockedby
                                    };
                                })
                            });

                            this.$projectManagementDialog.html(compiledHtml);
                            this.$projectManagementDialog.find('.remove-user-project-info-button').click(event => this.removeUserProjectInfo(event.target));

                            if (item.IsDraft) {
                                $('#projectManagementDialog .sprite-delete').addClass('sprite-disabled');
                                $('#projectManagementDialog .sprite-delete').prop('onclick', null);
                            }

                            this.$projectManagementDialog.dialog({
                                title: 'Project information',
                                width: '700px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [{
                                    text: 'Close',
                                    click: () => {
                                        this.$projectManagementDialog.dialog('close');
                                    }
                                }],
                                close: () => {
                                    this.$projectManagementDialog.empty();
                                }
                            });
                        });

                        if (!item.IsDraft) {
                            $shareNode.click(() => {
                                this.shareProjects([item.ProjectId]);
                            });

                            $deleteNode.click(async () => {
                                const data = await this.documentApiService.adminGetProjectInfo({ projectId: item.ProjectId });

                                const userSpecified = this.userEmail;

                                if (data.users.length > 0) {
                                    let message = 'Are you sure you want to unshare the project for the following users:\n';
                                    const userNames: string[] = [];

                                    for (let i = 0; i < data.users.length; i++) {
                                        // Unshare with specified user or everyone that is not the owner
                                        if (!userSpecified || userSpecified == data.users[i].name) {
                                            if (data.users[i].name != data.owner) {
                                                message += '- ' + data.users[i].name + '\n';
                                                userNames.push(data.users[i].name);
                                            }
                                        }
                                    }

                                    if (userNames.length != 0) {
                                        if (await this.modalService.showConfirmation(message, 'Unshare project')) {
                                            await this.removeUsersFromProjects(item.ProjectId, userNames);
                                        }
                                    }
                                    else {
                                        this.modalService.showMessage('No users to unshare the project for.', 'Unshare project');
                                    }
                                }
                                else {
                                    this.modalService.showMessage('Project is not shared with anyone.', 'Unshare project');
                                }
                            });
                        }

                        return $mainNode;
                    }
                }
            ]
        });
    }

    private addRow(element: HTMLElement) {
        const $tr = $(element.parentNode.parentNode.cloneNode(true)) as JQuery<HTMLElement>;
        $tr.find('input').val('');
        this.addShareProjectTemplateEvents($tr);

        document.getElementById('shareProjectTable').appendChild($tr[0]);
    }

    private removeRow(element: HTMLElement) {
        const tr = element.parentNode.parentNode;
        document.getElementById('shareProjectTable').removeChild(tr);
    }

    private addShareProjectTemplateEvents($element: JQuery<HTMLElement>) {
        $element.find('.add-row-button').click((event) => this.addRow(event.target));
        $element.find('.remove-row-button').click((event) => this.removeRow(event.target));
    }

    private shareProjects(projectIds: string[]) {
        this.$projectManagementDialog.html(shareProjectTemplate(null));
        this.addShareProjectTemplateEvents(this.$projectManagementDialog);

        this.$projectManagementDialog.dialog({
            title: 'Share the project with:',
            width: '700px',
            modal: true,
            resizable: false,
            draggable: false,
            buttons: [
                {
                    text: 'Cancel',
                    click: () => {
                        this.$projectManagementDialog.dialog('close');
                    }
                },
                {
                    text: 'Share',
                    click: async () => {
                        const $form = this.$projectManagementDialog.find('form');
                        const form = $form[0];

                        if (form.reportValidity == null || form.reportValidity()) {
                            const usersArray = $form.serializeArray();
                            const userNames = usersArray.map(field => field.value);

                            const adminCheckIfUsersExistsResponse = await this.documentApiService.adminCheckIfUsersExists({ userNames: userNames });
                            if (adminCheckIfUsersExistsResponse.missingUserNames.length > 0) {
                                this.modalService.showMessage('The following emails do not exist in the database: ' + adminCheckIfUsersExistsResponse.missingUserNames.join(', '), 'User does not exist');
                            }
                            else {
                                await this.documentApiService.adminAddUsersToProjects({ projectids: projectIds, users: userNames });

                                if (projectIds.length == 1) {
                                    this.modalService.showMessage('Project was successfully shared with user.', 'Share project');
                                }
                                else {
                                    this.modalService.showMessage('Selected projects were successfully shared with user.', 'Share projects');
                                }
                            }
                        }
                    }
                }],
            close: () => {
                this.$projectManagementDialog.empty();
            }
        });
    }

    private async unshareProjects(projectIds: string[], userSpecified: string) {
        // if user is not specified empty array must be sent
        let users: string[] = [];
        if (userSpecified) {
            users = [userSpecified];
        }

        if (userSpecified) {
            const adminCheckIfUsersExistsResponse = await this.documentApiService.adminCheckIfUsersExists({ userNames: users });
            if (adminCheckIfUsersExistsResponse.missingUserNames.length > 0) {
                this.modalService.showMessage('The following emails do not exist in the database: ' + adminCheckIfUsersExistsResponse.missingUserNames.join(', '), 'User does not exist');
            }
            else {
                await this.unshareProjectsCall(projectIds, users);
            }
        } else {
            await this.unshareProjectsCall(projectIds, users);
        }
    }

    private async unshareProjectsCall(projectIds: string[], users: string[]) {
        const adminUnshareProjectsResponse = await this.documentApiService.adminUnshareProjects({ projectids: projectIds, users: users });
        if (adminUnshareProjectsResponse.unshared == 0) {
            this.modalService.showMessage('Nothing was unshared!', 'Unshare projects');
        }
        else {
            this.modalService.showMessage('Selected projects were successfully unshared.', 'Unshare projects');
            this.userEmail = null;
        }

        this.loadGridData();
    }

    private async removeUsersFromProjects(projectId: string, userNames: string[]) {
        const adminCheckIfUsersExistsResponse = await this.documentApiService.adminCheckIfUsersExists({ userNames: userNames });
        if (adminCheckIfUsersExistsResponse.missingUserNames.length > 0) {
            this.modalService.showMessage('The following emails do not exist in the database: ' + adminCheckIfUsersExistsResponse.missingUserNames.join(', '), 'User does not exist');
        }
        else {
            await this.documentApiService.adminRemoveUsersFromProjects({ ProjectIds: [projectId], users: userNames });

            this.modalService.showMessage('User was successfully removed from the selected project.', 'Remove user from project');
            await this.loadGridData();
        }
    }

    private async loadGridData() {
        const adminListProjectsResponse = await this.documentApiService.adminListProjects({
            userEmail: this.userEmail,
            projectName: this.projectName,
            designName: this.designName,
            designType: this.designType,
            createdDateFrom: this.createdDateFrom,
            createdDateTo: this.createdDateTo
        });

        this.$grid.jsGrid({
            data: this.prepareProjectGridData(adminListProjectsResponse.projects)
        });
    }

    private prepareProjectGridData(data: Project[]) {
        const gridData: GridData[] = [];

        for (let i = 0; i < data.length; i++) {
            const project = data[i];

            gridData.push({
                Ranking: i + 1,
                ProjectId: project.projectid,
                ProjectName: project.name === '#$draft' ? 'Draft' : project.name,
                Owner: project.creator != null ? project.creator.name : null,
                DateCreated: project.created != null ? getDateFromUnix(project.created) : null,
                DateModified: project.updated != null ? getDateFromUnix(project.updated) : null,
                IsDraft: project.name === '#$draft',
                Checked: false
            });
        }

        return gridData;
    }
}
