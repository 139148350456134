import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { ApiOptions, ApiService } from './api.service';

export interface AdminGetDecodedUsersRequest {
    identifiers?: string;
    file?: {
        name: string;
        content: string;
    }
}

export interface DecryptedMarketing {
    userID: string;
    encryptedUID: string;
    username: string;
    languagePreference: string;
    languagePreferenceOffline: string;
    licenseType: string;
    licenseTypeOffline: string;
    tracking: string;
    ranking: number;
}

export interface AdminGetDecodedUsersResponse {
    decryptedMarketings: DecryptedMarketing[];
}

export interface AdminGetDecodedUsersFileRequest {
    identifiers?: string;
    file?: {
        name: string;
        content: string;
    }
}

@Injectable({
    providedIn: 'root'
})
export class UserSettingsApiService {
    constructor(
        private apiService: ApiService
    ) { }

    private get userSettingsServiceAdminUrlBase() {
        return environment.userSettingsWebServiceAdminUrl;
    }

    async adminResetUserAgreements(apiOptions?: ApiOptions) {
        const httpRequest = new HttpRequest<void>('POST', `${this.userSettingsServiceAdminUrlBase}ResetUserAgreements`, undefined);
        return await this.apiService.request(httpRequest, apiOptions);
    }

    async adminGetDecodedUsers(request: AdminGetDecodedUsersRequest, apiOptions?: ApiOptions): Promise<AdminGetDecodedUsersResponse> {
        const httpRequest = new HttpRequest('POST', `${this.userSettingsServiceAdminUrlBase}GetDecodedUsers`, request);

        const decryptedMarketings = await this.apiService.request<DecryptedMarketing[]>(httpRequest, apiOptions);
        return {
            decryptedMarketings
        };
    }

    async adminGetDecodedUsersFile(request: AdminGetDecodedUsersFileRequest, apiOptions?: ApiOptions) {
        const httpRequest = new HttpRequest('POST', `${this.userSettingsServiceAdminUrlBase}GetDecodedUsersFile`, request, { responseType: 'blob' });
        return await this.apiService.request<Blob>(httpRequest, apiOptions);
    }
}
