var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":27,"column":31},"end":{"line":27,"column":40}}}) : helper)))
    + "\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"Selected") || (depth0 != null ? lookupProperty(depth0,"Selected") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Selected","hash":{},"data":data,"loc":{"start":{"line":27,"column":42},"end":{"line":27,"column":54}}}) : helper)))
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":27,"column":55},"end":{"line":27,"column":63}}}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <input name=\"DisplayOnLaunch\" type=\"hidden\" value=\"true\" />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-md-4\">\n            <input name=\"DisplayOnLaunch\" type=\"checkbox\" value=\"true\" "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"DisplayOnLaunch") || (depth0 != null ? lookupProperty(depth0,"DisplayOnLaunch") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"DisplayOnLaunch","hash":{},"data":data,"loc":{"start":{"line":35,"column":71},"end":{"line":35,"column":90}}}) : helper)))
    + " />\n            <label class=\"col-form-label\">Display campaign on PE launch</label>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <input name=\"Ordinal\" type=\"hidden\" value=\"1\" />\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <label class=\"col-md-1 col-form-label\">Priority</label>\n        <div class=\"col-md-3\">\n            <select name=\"Ordinal\" class=\"form-control\" id=\"addEditMarketingCampaignOrdinal\">\n                <option value=\"1\">First</option>\n                <option value=\"2\">Second</option>\n            </select>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"addEditMarketingCampaignForm\">\n    <button id=\"addEditMarketingCampaignSubmit\" type=\"submit\" hidden></button>\n    <input name=\"MarketingCampaignId\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"MarketingCampaignId") || (depth0 != null ? lookupProperty(depth0,"MarketingCampaignId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MarketingCampaignId","hash":{},"data":data,"loc":{"start":{"line":3,"column":59},"end":{"line":3,"column":82}}}) : helper)))
    + "\" />\n    <input name=\"CampaignType\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CampaignType") || (depth0 != null ? lookupProperty(depth0,"CampaignType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CampaignType","hash":{},"data":data,"loc":{"start":{"line":4,"column":52},"end":{"line":4,"column":68}}}) : helper)))
    + "\" />\n    <input name=\"InAppPlacement\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"InAppPlacement") || (depth0 != null ? lookupProperty(depth0,"InAppPlacement") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"InAppPlacement","hash":{},"data":data,"loc":{"start":{"line":5,"column":54},"end":{"line":5,"column":72}}}) : helper)))
    + "\" />\n    <div class=\"form-group row\">\n        <label class=\"col-md-1 col-form-label\">Name</label>\n        <div class=\"col-md-3\">\n            <input name=\"Name\" type=\"text\" maxlength=\"255\" required class=\"form-control\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":9,"column":96},"end":{"line":9,"column":104}}}) : helper)))
    + "\" />\n        </div>\n        <label class=\"col-md-1 col-form-label\">Active</label>\n        <div class=\"col-md-3\">\n            <input id=\"active-from-date\" name=\"ActiveFrom\" type=\"text\" required placeholder=\"yyyy-mm-dd\"\n                class=\"form-control\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ActiveFrom") || (depth0 != null ? lookupProperty(depth0,"ActiveFrom") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ActiveFrom","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":58}}}) : helper)))
    + "\" />\n        </div>\n        <label class=\"col-md-1 col-form-label\">To</label>\n        <div class=\"col-md-3\">\n            <input id=\"active-to-date\" name=\"ActiveTo\" type=\"text\" required placeholder=\"yyyy-mm-dd\"\n                class=\"form-control\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ActiveTo") || (depth0 != null ? lookupProperty(depth0,"ActiveTo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ActiveTo","hash":{},"data":data,"loc":{"start":{"line":19,"column":44},"end":{"line":19,"column":56}}}) : helper)))
    + "\" />\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-md-1 col-form-label\">Hubs</label>\n        <div class=\"col-md-3\">\n            <select id=\"addEditMarketingCampaignHubsFilter\" multiple class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"HubsFilter") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":28,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"InAppPlacement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"col-md-4\">\n            <input name=\"ForNewRegisteredUser\" type=\"checkbox\" value=\"true\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"ForNewRegisteredUser") || (depth0 != null ? lookupProperty(depth0,"ForNewRegisteredUser") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ForNewRegisteredUser","hash":{},"data":data,"loc":{"start":{"line":40,"column":76},"end":{"line":40,"column":100}}}) : helper)))
    + " />\n            <label class=\"col-form-label\">New users</label>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-md-1 col-form-label\">User license options</label>\n        <div class=\"col-md-3\">\n            <select id=\"addEditMarketingCampaignUserLicenseOptions\" name=\"UserLicenseOptions\" multiple class=\"form-control\">\n                <option value=\"1\">Trial</option>\n                <option value=\"2\">Standard</option>\n                <option value=\"4\">Premium</option>\n            </select>\n        </div>\n\n        <label class=\"col-md-1 col-form-label\">Postal codes</label>\n        <div class=\"chosen-container chosen-container-multi col-md-3\" id=\"addEditMarketingCampaignPostalCodes\">\n            <ul id=\"postalCodes\" class=\"chosen-choices\">\n                <li class=\"search-field\">\n                    <input id=\"addEditMarketingCampaignPostalCodeInput\" class=\"chosen-search-input\" type=\"text\">\n                </li>\n            </ul>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"InAppPlacement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":63,"column":8},"end":{"line":73,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"form-group\" id=\"addEditMarketingCampaignEmailControl\">\n        <div class=\"col-md-12\">\n            <div data-toggle=\"collapse\" data-target=\"#addEditMarketingCampaignEmails\" class=\"collapse-toggle collapsed\">\n                <label class=\"col-form-label\">Emails</label>\n                <span class=\"collapse-toggle-icon\"></span>\n            </div>\n            <div id=\"addEditMarketingCampaignEmails\" class=\"collapse-container collapse\">\n                <ul class=\"list-group list-unstyled row\" id=\"addEditMarketingCampaignEmailsList\">\n\n                </ul>\n            </div>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <div class=\"col-md-12\">\n            <label class=\"col-form-label\">Selected hubs</label>\n            <div id=\"addEditMarketingCampaignHubs\" class=\"hubs\"></div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});