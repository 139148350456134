var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"importMarketingCampaignEmailsForm\" class=\"form-horizontal\" enctype=\"multipart/form-data\">\n    <input id=\"importMarketingCampaignEmailsMarketingCampaignId\" name=\"marketingCampaignId\" type=\"hidden\"\n        value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"MarketingCampaignId") || (depth0 != null ? lookupProperty(depth0,"MarketingCampaignId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"MarketingCampaignId","hash":{},"data":data,"loc":{"start":{"line":3,"column":15},"end":{"line":3,"column":38}}}) : helper)))
    + "\" />\n\n    <div class=\"form-group\">\n        <p class=\"col-sm-12\">Select the excel file to import below. Click on the import button to trigger import.</p>\n    </div>\n    <div class=\"form-group\">\n        <label class=\"col-sm-3\" for=\"excelFile\">Select excel file:</label>\n        <div class=\"col-sm-9\">\n            <input type=\"file\" id=\"importMarketingCampaignEmailsExcelFile\" name=\"excelFile\"\n                accept=\"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\">\n        </div>\n    </div>\n</form>\n";
},"useData":true});