import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { ApiOptions, ApiService } from './api.service';

export interface GdprGetUserGdprDataRequest {
    username: string;
}

export interface GdprData {
    Username: string;
    UserId: string;
    Profis3GeneralName: string;
    Profis3Email: string;
    Profis3Fax: string;
    Profis3PhoneNumber: string;
    Profis3UserNotify: boolean;
    Profis3UserPrivacy: boolean;
    PurchaserGeneralName: string;
    PurchaserEmail: string;
    PurchaserFax: string;
    PurchaserPhoneNumber: string;
    PurchaserUserNotify: boolean;
    PurchaserUserPrivacy: boolean;
    TemplateGDPRData: TemplateGdprData[]
}

export interface TemplateGdprData {
    UserId: string;
    TemplateName: string;
    Email: string;
    PhoneNumber: string;
    FaxNumber: string;
    ContactPerson: string;
}

export interface GdprDeleteUserGdprDataRequest {
    UserId: string;
    Username: string;
}

export interface GdprExportGdprDataRequest {
    UserId: string;
    Username: string;
}

@Injectable({
    providedIn: 'root'
})
export class AdminApiService {
    constructor(private apiService: ApiService) { }

    async gdprGetUserGdprData(request: GdprGetUserGdprDataRequest, apiOptions?: ApiOptions): Promise<GdprData> {
        const httpRequest = new HttpRequest('POST', environment.adminWebServicesUrl + 'GDPR/GetUserGDPRData', request);

        return await this.apiService.request<GdprData>(httpRequest, apiOptions);
    }

    async gdprDeleteUserGdprData(request: GdprDeleteUserGdprDataRequest, apiOptions?: ApiOptions): Promise<GdprData> {
        const httpRequest = new HttpRequest('POST', environment.adminWebServicesUrl + 'GDPR/DeleteUserGDPRData', request);

        return await this.apiService.request<GdprData>(httpRequest, apiOptions);
    }

    async gdprExportGdprData(request: GdprExportGdprDataRequest, apiOptions?: ApiOptions): Promise<Blob> {
        const httpRequest = new HttpRequest('POST', environment.adminWebServicesUrl + 'GDPR/ExportGDPRData', request, { responseType: 'blob' });

        return await this.apiService.request<Blob>(httpRequest, apiOptions);
    }
}
