<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand d-flex align-items-center" href="#" routerLink="/" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <img class="d-inline-block align-top mr-1" alt="" width="60" height="18" [src]="mainLogoOldImage" />
        <ng-container i18n="site header|Application title in the site header">PROFIS Admin</ng-container>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-link" routerLink="/project-management" routerLinkActive="active"
                    i18n="nav project management|Project management site navigation">Project management</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/export-designs" routerLinkActive="active"
                    i18n="nav export designs|Export designs site navigation">Export designs</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/decode-uid" routerLinkActive="active"
                    i18n="nav decode uid|Decryptor site navigation">Decryptor</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/release-notes" routerLinkActive="active"
                    i18n="nav release notes|Release notes site navigation">Release notes</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/marketing-campaign" routerLinkActive="active"
                    i18n="nav marketing campaign|Marketing campaign site navigation">Marketing campaigns</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/user-agreement" routerLinkActive="active"
                    i18n="nav user agreement|User agreement site navigation">User agreements</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/gdpr" routerLinkActive="active"
                    i18n="nav gdpr|GDPR site navigation">GDPR</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/region-links" routerLinkActive="active"
                    i18n="nav regional links|Regional links">Regional links</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/anchor-ai" routerLinkActive="active"
                    i18n="nav anchor ai|Anchor.AI">Anchor.AI</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/checkbot-plugin" routerLinkActive="active"
                    i18n="nav checkbot plugin|Checkbot Plugin">Checkbot Plugin</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/hilti-applications" routerLinkActive="active"
                    i18n="nav hilti applications|Hilti applications">Hilti applications</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/external-apps" routerLinkActive="active"
                    i18n="nav external apps|External Apps">Quickstart external apps</a>
            </li>
            <li class="nav-item" *ngIf="environment.enableDevelopmentApi">
                <a class="nav-link" routerLink="/development-api" routerLinkActive="active"
                    i18n="nav development api|Development API">Development API</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/application-versions" routerLinkActive="active"
                    i18n="nav application versions|Application versions">Application versions</a>
            </li>
        </ul>
        <ul class="nav navbar-nav navbar-right">
            <li>
                <button class="btn btn-link nav-link" (click)="logoff()" i18n="nav log off|Log off site navigation">Log
                    off</button>
            </li>
        </ul>
    </div>
</nav>

<div class="container body-content">
    <app-page-loading></app-page-loading>
    <router-outlet></router-outlet>
</div>
