var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"regionLinksPreview\">\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Region</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionName") || (depth0 != null ? lookupProperty(depth0,"RegionName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionName","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":50}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n\n    <hr/>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Buy license HOL page link</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"BuyLicenseHolLink") || (depth0 != null ? lookupProperty(depth0,"BuyLicenseHolLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BuyLicenseHolLink","hash":{},"data":data,"loc":{"start":{"line":14,"column":45},"end":{"line":14,"column":66}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BuyLicenseHolLink") || (depth0 != null ? lookupProperty(depth0,"BuyLicenseHolLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BuyLicenseHolLink","hash":{},"data":data,"loc":{"start":{"line":14,"column":84},"end":{"line":14,"column":105}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">License comparison page link</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LicenseComparisonPageLink") || (depth0 != null ? lookupProperty(depth0,"LicenseComparisonPageLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LicenseComparisonPageLink","hash":{},"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":74}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"LicenseComparisonPageLink") || (depth0 != null ? lookupProperty(depth0,"LicenseComparisonPageLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LicenseComparisonPageLink","hash":{},"data":data,"loc":{"start":{"line":20,"column":92},"end":{"line":20,"column":121}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Manage account link</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ManageAccountLink") || (depth0 != null ? lookupProperty(depth0,"ManageAccountLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ManageAccountLink","hash":{},"data":data,"loc":{"start":{"line":26,"column":45},"end":{"line":26,"column":66}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ManageAccountLink") || (depth0 != null ? lookupProperty(depth0,"ManageAccountLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ManageAccountLink","hash":{},"data":data,"loc":{"start":{"line":26,"column":84},"end":{"line":26,"column":105}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Software selling link</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"SoftwareSellingLink") || (depth0 != null ? lookupProperty(depth0,"SoftwareSellingLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SoftwareSellingLink","hash":{},"data":data,"loc":{"start":{"line":32,"column":45},"end":{"line":32,"column":68}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"SoftwareSellingLink") || (depth0 != null ? lookupProperty(depth0,"SoftwareSellingLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SoftwareSellingLink","hash":{},"data":data,"loc":{"start":{"line":32,"column":86},"end":{"line":32,"column":109}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Upgrade to premium link</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"UpgradeToPremiumLink") || (depth0 != null ? lookupProperty(depth0,"UpgradeToPremiumLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UpgradeToPremiumLink","hash":{},"data":data,"loc":{"start":{"line":38,"column":45},"end":{"line":38,"column":69}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"UpgradeToPremiumLink") || (depth0 != null ? lookupProperty(depth0,"UpgradeToPremiumLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UpgradeToPremiumLink","hash":{},"data":data,"loc":{"start":{"line":38,"column":87},"end":{"line":38,"column":111}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Upgrade to premium in header</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"UpgradeToPremiumHeaderLink") || (depth0 != null ? lookupProperty(depth0,"UpgradeToPremiumHeaderLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UpgradeToPremiumHeaderLink","hash":{},"data":data,"loc":{"start":{"line":44,"column":45},"end":{"line":44,"column":75}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"UpgradeToPremiumHeaderLink") || (depth0 != null ? lookupProperty(depth0,"UpgradeToPremiumHeaderLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UpgradeToPremiumHeaderLink","hash":{},"data":data,"loc":{"start":{"line":44,"column":93},"end":{"line":44,"column":123}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n\n    <hr/>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Ask Hilti link</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"AskHiltiLink") || (depth0 != null ? lookupProperty(depth0,"AskHiltiLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AskHiltiLink","hash":{},"data":data,"loc":{"start":{"line":53,"column":45},"end":{"line":53,"column":61}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"AskHiltiLink") || (depth0 != null ? lookupProperty(depth0,"AskHiltiLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AskHiltiLink","hash":{},"data":data,"loc":{"start":{"line":53,"column":79},"end":{"line":53,"column":95}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">BET learning link</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"BetLearningLink") || (depth0 != null ? lookupProperty(depth0,"BetLearningLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BetLearningLink","hash":{},"data":data,"loc":{"start":{"line":59,"column":45},"end":{"line":59,"column":64}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"BetLearningLink") || (depth0 != null ? lookupProperty(depth0,"BetLearningLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BetLearningLink","hash":{},"data":data,"loc":{"start":{"line":59,"column":82},"end":{"line":59,"column":101}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Handrail CBFEM learn more link</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"HandrailCBFEMLearnMoreLink") || (depth0 != null ? lookupProperty(depth0,"HandrailCBFEMLearnMoreLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HandrailCBFEMLearnMoreLink","hash":{},"data":data,"loc":{"start":{"line":65,"column":45},"end":{"line":65,"column":75}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"HandrailCBFEMLearnMoreLink") || (depth0 != null ? lookupProperty(depth0,"HandrailCBFEMLearnMoreLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HandrailCBFEMLearnMoreLink","hash":{},"data":data,"loc":{"start":{"line":65,"column":93},"end":{"line":65,"column":123}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Perfect Spec link</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\"><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"PerfectSpecLink") || (depth0 != null ? lookupProperty(depth0,"PerfectSpecLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PerfectSpecLink","hash":{},"data":data,"loc":{"start":{"line":71,"column":45},"end":{"line":71,"column":64}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"PerfectSpecLink") || (depth0 != null ? lookupProperty(depth0,"PerfectSpecLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PerfectSpecLink","hash":{},"data":data,"loc":{"start":{"line":71,"column":82},"end":{"line":71,"column":101}}}) : helper)))
    + "</a></p>\n        </div>\n    </div>\n</form>\n";
},"useData":true});