import escape from 'lodash-es/escape';

import { Component } from '@angular/core';

import { downloadBlob } from '../../browser';
import { formatDateForInput, getDateFromJson } from '../../date';
import { AppTitleService } from '../../services/app-title.service';
import { ModalService } from '../../services/modal.service';
import { PageLoadingService } from '../../services/page-loading.service';
import {
    ProductInformationApiService, ReleaseNote
} from '../../services/product-information-api.service';
import { Page } from '../page';
import importTemplate from './import.hbs';
import previewReleaseNoteTranslationsTemplate from './preview-release-note-translations.hbs';
import previewReleaseNoteTemplate from './preview-release-note.hbs';
import releaseNoteTemplate from './release-note.hbs';

interface GridData {
    NoteId: number;
    VersionId: number;
    NoteTitle: string;
    NoteContent: string;
    Culture: string;
    ReleaseVersion: string;
    ReleaseDate: Date;
}

@Component({
    selector: 'app-release-notes',
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.css']
})
export class ReleaseNotesComponent extends Page {
    pageTitle = $localize`:app-title release notes|Release notes application title:Release notes`;

    private $grid: JQuery<HTMLElement>;
    private $releaseNoteDialog: JQuery<HTMLElement>;
    private $importDialog: JQuery<HTMLElement>;

    constructor(
        protected appTitleService: AppTitleService,
        protected pageLoadingService: PageLoadingService,
        private modalService: ModalService,
        private productInformationApiService: ProductInformationApiService
    ) {
        super(appTitleService, pageLoadingService);
    }

    async ngOnInit() {
        super.ngOnInit();

        this.$grid = $('#jsGridReleaseNotes');
        this.$releaseNoteDialog = $('#releaseNoteDialog');
        this.$importDialog = $('#importDialog');

        this.initGrid();

        $('#addNewReleaseNote').click(() => {
            this.$releaseNoteDialog.html(releaseNoteTemplate(null));

            this.$releaseNoteDialog.find('#releaseDate').datepicker({
                dateFormat: 'yy-mm-dd',
                minDate: new Date(1970, 0, 1)
            });

            this.$releaseNoteDialog.dialog({
                title: 'Add new release note',
                width: '700px',
                dialogClass: 'ui-dialog',
                modal: true,
                resizable: false,
                draggable: false,
                buttons: [
                    {
                        text: 'Cancel',
                        click: () => {
                            this.$releaseNoteDialog.dialog('close');
                        }
                    },
                    {
                        text: 'Publish',
                        click: async () => {
                            const $form = this.$releaseNoteDialog.find('form');
                            const form = $form[0];

                            if (form.reportValidity == null || form.reportValidity()) {
                                if (await this.modalService.showConfirmation('By publishing the release note, you will make it public to all users. Are you sure you want to publish it?', 'Add new release note')) {
                                    const keyValuePairs = $form.serializeArray();

                                    const data: ReleaseNote = {
                                        ReleaseNoteId: null,
                                        ReleaseNoteVersionId: null,
                                        Title: null,
                                        Content: null,
                                        Culture: null,
                                        ReleaseDate: null,
                                        ReleaseVersion: null
                                    };

                                    $.each(keyValuePairs, (i, v) => {
                                        if (v.name == 'noteTitle') {
                                            data.Title = v.value;
                                        }
                                        else if (v.name == 'noteContent') {
                                            data.Content = v.value;
                                        }
                                        else if (v.name == 'releaseVersion') {
                                            data.ReleaseVersion = v.value;
                                        }
                                        else if (v.name == 'releaseDate') {
                                            data.ReleaseDate = v.value;
                                        }
                                    });

                                    await this.productInformationApiService.releaseNotesAddReleaseNote(data);

                                    this.$releaseNoteDialog.dialog('close');
                                    await this.loadGridData();
                                }
                            }
                        }
                    }],
                close: () => {
                    this.$releaseNoteDialog.empty();
                }
            });
        });

        $('#searchButton').click(async () => {
            const $form = $('#searchForm');
            const form = <HTMLFormElement>$form[0];

            if (form.reportValidity == null || form.reportValidity()) {
                await this.loadGridData();

                // Since search probably results in less entries go back to page 1
                this.$grid.jsGrid({
                    pageIndex: 1
                });
            }
        });

        $('#clearFilter').click(async () => {
            const $form = $('#searchForm');
            const form = <HTMLFormElement>$form[0];

            form.reset();
            await this.loadGridData();
        });

        $('#searchReleaseDate').datepicker({
            dateFormat: 'yy-mm-dd',
            minDate: new Date(1970, 0, 1)
        });

        await this.loadGridData();
    }

    private initGrid() {
        this.$grid.jsGrid({
            width: '100%',
            height: 'auto',
            sorting: true,
            paging: true,
            pageSize: 10,
            fields: [
                { name: 'NoteId', type: 'number', visible: false, itemTemplate: escape },
                { name: 'VersionId', type: 'number', visible: false, itemTemplate: escape },
                { name: 'NoteContent', type: 'text', visible: false, itemTemplate: escape },
                { name: 'NoteTitle', type: 'text', width: 200, title: 'Title', align: 'left', itemTemplate: escape },
                { name: 'ReleaseVersion', type: 'text', width: 150, title: 'Release Version', align: 'left', itemTemplate: escape },
                { name: 'ReleaseDate', type: 'date', width: 150, title: 'Release Date', align: 'left' },
                {
                    type: 'control',
                    width: 150,
                    headerTemplate: 'Actions',
                    itemTemplate: (value: void, item: GridData) => {
                        const $mainNode = $('<div>').addClass('js-grid-actions');
                        const $viewNode = $('<span>').addClass('sprite d-inline-block sprite-view').attr('title', 'Preview').appendTo($mainNode);
                        const $editNode = $('<span>').addClass('sprite d-inline-block sprite-edit').attr('title', 'Edit').appendTo($mainNode);
                        const $deleteNode = $('<span>').addClass('sprite d-inline-block sprite-delete').attr('title', 'Delete').appendTo($mainNode);
                        const $exportNode = $('<span>').addClass('sprite d-inline-block sprite-export').attr('title', 'Export translations').appendTo($mainNode);
                        const $importNode = $('<span>').addClass('sprite d-inline-block sprite-import').attr('title', 'Import translations').appendTo($mainNode);
                        const $listNode = $('<span>').addClass('sprite d-inline-block sprite-list').attr('title', 'Preview translations').appendTo($mainNode);

                        $viewNode.click(() => {
                            const compiledHtml = previewReleaseNoteTemplate({
                                NoteTitle: item.NoteTitle,
                                NoteContent: item.NoteContent,
                                ReleaseVersion: item.ReleaseVersion,
                                ReleaseDate: item.ReleaseDate.toLocaleDateString('de-DE')
                            });

                            this.$releaseNoteDialog.html(compiledHtml);

                            this.$releaseNoteDialog.dialog({
                                title: 'Preview release note',
                                width: '700px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [{
                                    text: 'Close',
                                    click: () => {
                                        this.$releaseNoteDialog.dialog('close');
                                    }
                                }],
                                close: () => {
                                    this.$releaseNoteDialog.empty();
                                }
                            });
                        });

                        $editNode.click(() => {
                            const compiledHtml = releaseNoteTemplate({
                                NoteId: item.NoteId,
                                VersionId: item.VersionId,
                                NoteTitle: item.NoteTitle,
                                NoteContent: item.NoteContent,
                                ReleaseVersion: item.ReleaseVersion,
                                ReleaseDate: formatDateForInput(item.ReleaseDate)
                            });

                            this.$releaseNoteDialog.html(compiledHtml);

                            this.$releaseNoteDialog.find('#releaseDate').datepicker({
                                dateFormat: 'yy-mm-dd',
                                minDate: new Date(1970, 0, 1)
                            });

                            this.$releaseNoteDialog.dialog({
                                title: 'Edit release note',
                                width: '700px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [
                                    {
                                        text: 'Cancel',
                                        click: () => {
                                            this.$releaseNoteDialog.dialog('close');
                                        }
                                    },
                                    {
                                        text: 'Save',
                                        click: async () => {
                                            const $form = this.$releaseNoteDialog.find('form');
                                            const form = $form[0];

                                            if (form.reportValidity == null || form.reportValidity()) {
                                                const keyValuePairs = $form.serializeArray();

                                                const data: ReleaseNote = {
                                                    ReleaseNoteId: null,
                                                    ReleaseNoteVersionId: null,
                                                    Title: null,
                                                    Content: null,
                                                    Culture: null,
                                                    ReleaseDate: null,
                                                    ReleaseVersion: null
                                                };

                                                $.each(keyValuePairs, (i, v) => {
                                                    if (v.name == 'noteTitle') {
                                                        data.Title = v.value;
                                                    }
                                                    else if (v.name == 'noteContent') {
                                                        data.Content = v.value;
                                                    }
                                                    else if (v.name == 'releaseVersion') {
                                                        data.ReleaseVersion = v.value;
                                                    }
                                                    else if (v.name == 'releaseDate') {
                                                        data.ReleaseDate = v.value;
                                                    }
                                                    else if (v.name == 'noteId') {
                                                        data.ReleaseNoteId = parseInt(v.value);
                                                    }
                                                    else if (v.name == 'versionId') {
                                                        data.ReleaseNoteVersionId = parseInt(v.value);
                                                    }
                                                });

                                                await this.productInformationApiService.releaseNotesEditReleaseNote(data);

                                                this.$releaseNoteDialog.dialog('close');
                                                await this.loadGridData();
                                            }
                                        }
                                    }],
                                close: () => {
                                    this.$releaseNoteDialog.empty();
                                }
                            });
                        });

                        $deleteNode.click(async () => {
                            if (await this.modalService.showConfirmation('Are you sure you want to delete the release note?', 'Delete release note')) {
                                await this.productInformationApiService.releaseNotesDeleteReleaseNote({ releaseNoteVersionId: item.VersionId });

                                await this.loadGridData();
                            }
                        });

                        $exportNode.click(async () => {
                            const blob = await this.productInformationApiService.releaseNotesExportReleaseNote({ releaseNoteId: item.NoteId, releaseNoteVersionId: item.VersionId });

                            const now = new Date();
                            const year = now.getFullYear();
                            const month = ((now.getMonth() + 1) < 10 ? '0' : '') + (now.getMonth() + 1);
                            const day = (now.getDate() < 10 ? '0' : '') + now.getDate();

                            downloadBlob(blob, `ReleaseNotes_${year}-${month}-${day}.xlsx`);
                        });

                        $importNode.click(() => {
                            const compiledHtml = importTemplate({
                                VersionId: item.VersionId
                            });

                            this.$importDialog.html(compiledHtml);

                            this.$importDialog.dialog({
                                title: 'Import release notes',
                                width: '700px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [
                                    {
                                        text: 'Cancel',
                                        click: () => {
                                            this.$importDialog.dialog('close');
                                        }
                                    },
                                    {
                                        text: 'Import',
                                        click: () => {
                                            const $form = this.$importDialog.find('form');
                                            const form = $form[0];
                                            const formData = new FormData(form);

                                            if (form.reportValidity == null || form.reportValidity()) {
                                                const file = (<HTMLInputElement>form.elements[1]).files[0];
                                                const releaseNoteVersionId = (<HTMLInputElement>form.elements[0]).value;

                                                const reader = new FileReader();
                                                reader.onload = async (event) => {
                                                    const arrayBuffer = event.target.result as ArrayBuffer;
                                                    const array = new Uint8Array(arrayBuffer);

                                                    const content = btoa(String.fromCharCode.apply(null, array as any));

                                                    const data = {
                                                        ReleaseNoteVersionId: parseInt(releaseNoteVersionId, 10),
                                                        Content: content
                                                    };

                                                    await this.productInformationApiService.releaseNotesImportReleaseNotes(data);

                                                    this.modalService.showMessage('Release notes successfully imported.', 'Import release notes');
                                                }
                                                reader.readAsArrayBuffer(file);
                                            }
                                        }
                                    }],
                                close: () => {
                                    this.$importDialog.empty();
                                }
                            });
                        });

                        $listNode.click(async () => {
                            const releaseNotesListReleaseNoteTranslationsResponse = await this.productInformationApiService.releaseNotesListReleaseNoteTranslations({ releaseNoteVersionId: item.VersionId });

                            const compiledHtml = previewReleaseNoteTranslationsTemplate({
                                Notes: releaseNotesListReleaseNoteTranslationsResponse.releaseNotes.map(releaseNote => {
                                    return {
                                        Culture: releaseNote.Culture,
                                        NoteTitle: releaseNote.Title,
                                        NoteContent: releaseNote.Content
                                    };
                                })
                            });

                            this.$releaseNoteDialog.html(compiledHtml);

                            this.$releaseNoteDialog.dialog({
                                title: 'Preview release note translations',
                                width: '700px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [{
                                    text: 'Close',
                                    click: () => {
                                        this.$releaseNoteDialog.dialog('close');
                                    }
                                }],
                                close: () => {
                                    this.$releaseNoteDialog.empty();
                                }
                            });
                        });

                        return $mainNode;
                    }
                }
            ]
        });
    }

    private async loadGridData() {
        const releaseNotesListReleaseNotesResponse = await this.productInformationApiService.releaseNotesListReleaseNotes({
            searchTitle: $('#searchTitle').val() as string,
            searchReleaseVersion: $('#searchReleaseVersion').val() as string,
            searchReleaseDate: $('#searchReleaseDate').val() as string
        });

        const gridData = releaseNotesListReleaseNotesResponse.releaseNotes.map((releaseNote): GridData => ({
            Culture: releaseNote.Culture,
            NoteContent: releaseNote.Content,
            NoteId: releaseNote.ReleaseNoteId,
            NoteTitle: releaseNote.Title,
            ReleaseDate: getDateFromJson(releaseNote.ReleaseDate),
            ReleaseVersion: releaseNote.ReleaseVersion,
            VersionId: releaseNote.ReleaseNoteVersionId
        }));

        this.$grid.jsGrid({
            data: gridData
        });
    }
}
