/**
 * Known design types.
 */
export enum DesignType {
    Unknown = 0,
    Concrete = 1,
    Handrail = 2,
    Masonry = 3,
    MetalDeck = 4
}


/**
 * Design conversion data.
 */
export interface IConvertDesignsData {
    Id: string;
    Content: string;
}

/**
 * Design conversion request.
 */
export interface IConvertDesignsRequest {
    Items: IConvertDesignsData[];
}

/**
 * Design conversion response.
 */
export interface IConvertDesignsResponse {
    Items: IConvertDesignsData[];
}

export type convertDesignsFunction = (designs: IConvertDesignsRequest) => Promise<IConvertDesignsResponse>;

/**
 * Design type info.
 */
export interface IDesignListInfo {
    /** Design Type ID. */
    designTypeId: number;


    /** convertDesigns function identifier */
    convertDesignsId?: number;

    /** Returns a tooltip text for new design button. */
    convertDesigns?: convertDesignsFunction;
}
