import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';
import mainLogoOldImage from '../../../images/main-logo-old.png';
import { AuthService } from '../../services/auth.service';
import { PageLoadingService } from '../../services/page-loading.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    mainLogoOldImage = mainLogoOldImage;
    environment = environment;

    private logoutNavigationInProgress = false;

    constructor(
        private authService: AuthService,
        public pageLoadingService: PageLoadingService
    ) { }

    logoff() {
        if (this.authService.auth != null && !this.logoutNavigationInProgress) {
            const form = document.createElement('form');

            form.method = 'POST';
            form.action = `${environment.externalAuthenticationUrl}${environment.externalLogout}`;

            const client_id = document.createElement('input');
            client_id.value = environment.externalClientId;
            client_id.name = 'client_id';
            form.appendChild(client_id);

            const lang = document.createElement('input');
            lang.value = environment.defaultLanguage;
            lang.name = 'lang';
            form.appendChild(lang);

            const access_token = document.createElement('input');
            access_token.value = this.authService.auth.accessToken;
            access_token.name = 'access_token';
            form.appendChild(access_token);

            if (environment.authentication == 'oauth1') {
                const redirect_uri = document.createElement('input');
                redirect_uri.value = `${window.location.origin}/${environment.externalLogout}`;
                redirect_uri.name = 'redirect_uri';
                form.appendChild(redirect_uri);
            }
            if (environment.authentication == 'oauth2') {
                const logout_uri = document.createElement('input');
                logout_uri.value = `${window.location.origin}/${environment.externalLogout}`;
                logout_uri.name = 'logout_uri';
                form.appendChild(logout_uri);
            }

            form.style.display = 'none';
            document.body.appendChild(form);

            form.submit();

            this.authService.clear();
            this.logoutNavigationInProgress = true;
        }
    }
}
