import { Component, OnInit } from '@angular/core';

import { PageLoadingService } from '../../services/page-loading.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
    constructor(private pageLoadingService: PageLoadingService) { }

    ngOnInit(): void {
        this.pageLoadingService.remove('init');
    }
}
