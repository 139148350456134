var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row\">\n        <label class=\"control-label col-sm-6\">Project shared with:</label>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Users") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":16,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"UserId") || (depth0 != null ? lookupProperty(depth0,"UserId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UserId","hash":{},"data":data,"loc":{"start":{"line":12,"column":13},"end":{"line":12,"column":23}}}) : helper)))
    + "\" user-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"User") || (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"User","hash":{},"data":data,"loc":{"start":{"line":12,"column":36},"end":{"line":12,"column":44}}}) : helper)))
    + "\" class=\"row offset-md-1\" style=\"display: table;\">\n        <span class=\"span-middle\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"User") || (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"User","hash":{},"data":data,"loc":{"start":{"line":13,"column":34},"end":{"line":13,"column":42}}}) : helper)))
    + "</span>\n        <span class=\"span-middle sprite sprite-delete btn remove-user-project-info-button\" title=\"Remove user from project\"></span>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"row\">\n        <label class=\"control-label col-sm-6\" style=\"font-weight: bold;\">This project is not shared to any user.</label>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row\">\n        <label class=\"control-label col-sm-6\" style=\"font-weight: bold;\">Designs included in the project:</label>\n    </div>\n    <div class=\"row table-scroll\">\n        <table class=\"table\">\n            <thead>\n                <tr>\n                    <th>Design name</th>\n                    <th>Type</th>\n                    <th>Creation date</th>\n                    <th>Locked by</th>\n                </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Documents") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":45,"column":25}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":32}}}) : helper)))
    + "</td>\n                    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"Type") || (depth0 != null ? lookupProperty(depth0,"Type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Type","hash":{},"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":41,"column":32}}}) : helper)))
    + "</td>\n                    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"Created") || (depth0 != null ? lookupProperty(depth0,"Created") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Created","hash":{},"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":42,"column":35}}}) : helper)))
    + "</td>\n                    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"LockedBy") || (depth0 != null ? lookupProperty(depth0,"LockedBy") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LockedBy","hash":{},"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":43,"column":36}}}) : helper)))
    + "</td>\n                </tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"row\">\n        <label class=\"control-label col-sm-6\" style=\"font-weight: bold;\">There are no designs in this project.</label>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container\" project-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ProjectId") || (depth0 != null ? lookupProperty(depth0,"ProjectId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ProjectId","hash":{},"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":48}}}) : helper)))
    + "\">\n    <div class=\"row\">\n        <label class=\"col-sm-3\">Project owner:</label>\n        <span class=\"col-sm-9\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Owner") || (depth0 != null ? lookupProperty(depth0,"Owner") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Owner","hash":{},"data":data,"loc":{"start":{"line":4,"column":31},"end":{"line":4,"column":40}}}) : helper)))
    + "</span>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Users") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Documents") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":53,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});