import { Component, OnInit } from '@angular/core';

import { Hub, HubRegionLink, HubsFilterItem } from '../../entities/hubs-regions';
import { ImageHelper } from '../../helpers/image-helper';
import { AppTitleService } from '../../services/app-title.service';
import { CodeListsService, RegionEntity } from '../../services/code-lists.service';
import { ModalService } from '../../services/modal.service';
import { PageLoadingService } from '../../services/page-loading.service';
import {
    ExternalApp, ExternalAppLinks, ProductInformationApiService
} from '../../services/product-information-api.service';
import { Page } from '../page';
import externalAppsAddDialogTemplate from './external-apps-add-dialog.hbs';
import externalAppsAddLinksDialogTemplate from './external-apps-add-links-dialog.hbs';
import externalAppsEditDialogTemplate from './external-apps-edit-dialog.hbs';
import externalAppsPreviewDialogTemplate from './external-apps-preview-dialog.hbs';

@Component({
    selector: 'app-external-apps-applications',
    templateUrl: './external-apps.component.html',
    styleUrls: ['./external-apps.component.css']
})
export class ExternalAppsComponent extends Page implements OnInit {
    pageTitle = $localize`:app-title external apps|Quickstart external applications title:Quickstart external applications`;
    public regions: RegionEntity[] = [];

    private initialData: ExternalApp[];
    private selectedHubs: number[] = [];
    private hubRegionCodeList: HubRegionLink[] = [];
    private hubCodeList: Hub[] = [];

    private $externalAppsGrid: JQuery<HTMLElement>;
    private $externalAppsDialog: JQuery<HTMLElement>;
    private $externalAppsLinksDialog: JQuery<HTMLElement>;
    private image: string;
    private hubsFilter: HubsFilterItem[] = [];

    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private productInformationApiService: ProductInformationApiService,
        private codeListsService: CodeListsService,
        private modalService: ModalService
    ) {
        super(appTitleService, pageLoadingService);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        this.$externalAppsGrid = $('#externalAppsGrid');
        this.$externalAppsDialog = $('#externalAppsDialog');
        this.$externalAppsLinksDialog = $('#externalAppsLinksDialog');

        this.initGrid();
        await this.loadGridData();
    }

    addApp() {
        const compiledHtml = externalAppsAddDialogTemplate({
            Name: '',
            TranslationKey: '',
            DisplayTranslationKey: this.getAppTranslationKey('<key>'),
            Image: ImageHelper.emptyImage,
            Hubs: this.hubCodeList,
            HubsFilter: this.hubsFilter
        });

        this.$externalAppsDialog.html(compiledHtml);

        this.$externalAppsDialog.dialog({
            title: 'Add external applications',
            width: '1000px',
            modal: true,
            resizable: false,
            draggable: false,
            buttons: [
                {
                    text: 'Cancel',
                    click: () => {
                        this.$externalAppsDialog.dialog('close');
                    }
                },
                {
                    text: 'Save',
                    click: async () => {
                        const $form = this.$externalAppsDialog.find('form');
                        const form = $form[0] as any;

                        if (form.reportValidity == null || form.reportValidity()) {
                            if (ImageHelper.isImageEmpty(this.image)) {
                                this.modalService.showMessage('Please select application image.', 'No image specified');
                                return;
                            }

                            const data: ExternalApp = {
                                Id: -1,
                                Name: form.elements['name'].value,
                                TranslationKey: form.elements['translationKey'].value,
                                Image: this.image,
                                ShowNewLabel: form.elements['showNewLabel'].checked,
                                Links: this.getAppLinks($form)
                            };

                            this.productInformationApiService.addExternalApps(data).then( async () => {
                                this.$externalAppsDialog.dialog('close');
                                this.image = null;
                                await this.loadGridData();
                            });
                        }
                    }
                }
            ],
            open: () => {
                this.$externalAppsDialog.find('#hubsFilter')
                    .chosen({ width: '100%', placeholder_text_multiple: 'Select hubs ...' })
                    .on('change', (eventObject, args) => this.hubsFilterChange(eventObject, args, null, true));
                this.$externalAppsDialog.find('#image').on('change', (eventObject, args) => this.handleImage());
                this.$externalAppsDialog.find('#translationKey').on('keyup', (e) => this.onTranslationKeyChange());
            },
            close: () => {
                this.$externalAppsDialog.empty();
            }
        });
    }

    private initGrid() {
        this.$externalAppsGrid.jsGrid({
            width: '100%',
            height: 'auto',
            sorting: true,
            paging: true,
            pageSize: 5,
            fields: [
                { name: 'ExternalAppId', type: 'number', visible: false },
                { name: 'Image', type: 'image', width: 200, title: 'Image', align: 'left', itemTemplate:(value: string, _item: ExternalApp) => `<img src="${value}">` },
                { title: 'Name', type: 'text', width: 200, align: 'left', itemTemplate: (_value: void, item: ExternalApp) => item.Name },
                { title: 'Translation key', type: 'text', width: 'auto', align: 'left',
                    itemTemplate: (_value: void, item: ExternalApp) => {
                        let text = `<div>${this.getAppTranslationKey(item.Name)}</div>`;
                        return text;
                    }
                },
                { title: 'Show "New" label', type: 'bool', width: 180, align: 'left', itemTemplate: (_value: void, item: ExternalApp) => item.ShowNewLabel.toString() },
                {
                    type: 'control',
                    width: 150,
                    headerTemplate: 'Actions',
                    itemTemplate: (value: void, item: ExternalApp) => {
                        const $mainNode = $('<div>').addClass('js-grid-actions');
                        const $viewNode = $('<span>').addClass('sprite d-inline-block sprite-view').attr('title', 'Preview').appendTo($mainNode);
                        const $editNode = $('<span>').addClass('sprite d-inline-block sprite-edit').attr('title', 'Edit').appendTo($mainNode);
                        const $deleteNode = $('<span>').addClass('sprite d-inline-block sprite-delete').attr('title', 'Delete').appendTo($mainNode);

                        $viewNode.click(() => {
                            const compiledHtml = externalAppsPreviewDialogTemplate({
                                Name: item.Name,
                                DisplayTranslationKey: this.getAppTranslationKey(item.TranslationKey),
                                Image: item.Image,
                                ShowNewLabel: item.ShowNewLabel
                            });

                            this.$externalAppsDialog.html(compiledHtml);

                            this.$externalAppsDialog.dialog({
                                title: 'Preview hilti applications',
                                width: '1000px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [{
                                    text: 'Close',
                                    click: () => {
                                        this.$externalAppsDialog.dialog('close');
                                    }
                                }],
                                close: () => {
                                    this.$externalAppsDialog.empty();
                                }
                            });
                        });

                        $editNode.click(async () => {
                            const externalApp = this.initialData.find(x => x.Id == item.Id);
                            const regions = externalApp.Links.map(x => x.RegionId);
                            const hubs: Hub[] = [];

                            this.hubCodeList.forEach(x => {
                                let hubsFilterItem = this.hubsFilter.find(hfi => hfi.HubId == x.Id);
                                if (hubsFilterItem == null) {
                                    hubsFilterItem = {
                                        HubId: x.Id,
                                        Name: x.Name,
                                        Selected: null
                                    };
                                    this.hubsFilter.push(hubsFilterItem);
                                }

                                x.Regions.forEach(y => {
                                    if (regions.includes(y.Id) && hubs.find(h => h.Id == x.Id) == null) {
                                        hubs.push(x);
                                    }
                                });

                                hubsFilterItem.Selected = (hubs.find(h => h.Id == x.Id) == null) ? null : 'selected';
                            });

                            this.hubRegionCodeList.forEach(x => ({
                                Id: x.Id,
                                Name: x.Name,
                                HubId: x.HubId,
                                Link: externalApp.Links.find(y => y.RegionId == x.Id)?.Link,
                                Enabled: externalApp.Links.find(y => y.RegionId == x.Id)?.Active ? 'checked' : ''
                            }));

                            const compiledHtml = externalAppsEditDialogTemplate({
                                Name: externalApp.Name,
                                TranslationKey: externalApp.TranslationKey,
                                DisplayTranslationKey: this.getAppTranslationKey(externalApp.TranslationKey),
                                Image: externalApp.Image,
                                ShowNewLabel: externalApp.ShowNewLabel ? 'checked' : '',
                                Hubs: this.hubCodeList.map(x => ({
                                    Id: x.Id,
                                    Name: x.Name,
                                    Regions: this.hubRegionCodeList.filter(y => y.HubId == x.Id)
                                })),
                                HubsFilter: this.hubsFilter
                            });

                            this.$externalAppsDialog.html(compiledHtml);

                            const compiledHtmlLinks = externalAppsAddLinksDialogTemplate({
                                Regions: this.hubRegionCodeList
                            });

                            hubs.forEach((hub, index) => {
                                this.$externalAppsDialog.find('#hubs').append(externalAppsAddLinksDialogTemplate({
                                    Id: hub.Id,
                                    Name: hub.Name,
                                    Index: index,
                                    Regions: hub.Regions.map(regionCl => ({
                                        Id: regionCl.Id,
                                        Name: regionCl.Name,
                                        HubId: regionCl.HubId,
                                        Index: index,
                                        Link: externalApp.Links.find(y => y.RegionId == regionCl.Id)?.Link,
                                        Enabled: externalApp.Links.find(y => y.RegionId == regionCl.Id)?.Active ? 'checked' : ''
                                    }))
                                }));
                            });

                            this.$externalAppsLinksDialog.html(compiledHtmlLinks);

                            this.$externalAppsDialog.dialog({
                                title: 'Edit external applications',
                                width: '1000px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [
                                    {
                                        text: 'Cancel',
                                        click: async () => {
                                            this.$externalAppsDialog.dialog('close');
                                            await this.releaseResources();
                                        }
                                    },
                                    {
                                        text: 'Save',
                                        click: async () => {
                                            const $form = this.$externalAppsDialog.find('form');
                                            const form = $form[0] as any;

                                            if (form.reportValidity == null || form.reportValidity()) {
                                                const appImage = this.image ?? externalApp.Image;
                                                if (ImageHelper.isImageEmpty(appImage)) {
                                                    this.modalService.showMessage('Please select application image.', 'No image specified');
                                                    return;
                                                }

                                                const data: ExternalApp = {
                                                    Id: item.Id,
                                                    Name: form.elements['name'].value,
                                                    TranslationKey: form.elements['translationKey'].value,
                                                    Image: appImage,
                                                    ShowNewLabel: form.elements['showNewLabel'].checked,
                                                    Links: this.getAppLinks($form)
                                                };

                                                await this.productInformationApiService.updateExternalApps(data);

                                                this.$externalAppsDialog.dialog('close');
                                                await this.releaseResources();
                                            }
                                        }
                                    }
                                ],
                                open: () => {
                                    this.$externalAppsDialog.find('#hubsFilter')
                                        .chosen({ width: '100%', placeholder_text_multiple: 'Select hubs ...' })
                                        .on('change', (eventObject, args) => this.hubsFilterChange(eventObject, args, item.Id, false));
                                    this.$externalAppsDialog.find('#image').on('change', (eventObject, args) => this.handleImage());
                                    this.$externalAppsDialog.find('#translationKey').on('keyup', () => this.onTranslationKeyChange(true));
                                },
                                close: () => {
                                    this.$externalAppsDialog.empty();
                                    this.image = null;
                                    this.loadGridData();
                                }
                            });
                        });

                        $deleteNode.click(() => {
                            const $deleteDialog = $('#deleteExternalAppDialog');

                            $deleteDialog.dialog({
                                width: 500,
                                modal: true,
                                resizable: false,
                                draggable: false,
                                title: 'Delete Campaign',
                                buttons: [{
                                    text: 'Cancel',
                                    click: () => {
                                        $deleteDialog.dialog('close');
                                    }
                                }, {
                                    text: 'Delete',
                                    click: async () => {
                                        await this.productInformationApiService.deleteExternalApp(item.Id);

                                        $deleteDialog.dialog('close');
                                        await this.loadGridData();
                                    }
                                }]
                            });
                        });

                        return $mainNode;
                    }
                }
            ]
        });
    }

    public handleImage() {
        const file = document.querySelector<HTMLInputElement>("input[type=file]").files[0];
        const reader = new FileReader();

        reader.addEventListener(
            "load",
            () => {
                const image = reader.result as string;   // convert image file to base64 string
                (async () => {
                    try {
                        await ImageHelper.validateImageSize(
                            image,
                            128,
                            128
                        );

                        // success
                        this.image = image;
                        document.querySelector<HTMLInputElement>("#imagePreview").src = this.image;
                    }
                    catch (_) {
                        // error
                        this.modalService.showMessage('Image size MUST be 128x128 or smaller.', 'Wrong image size');
                        this.image = null;
                    }
                })();
            },
            false,
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    private async releaseResources() {
        this.image = null;
        await this.loadGridData();
    }

    private async loadGridData() {
        this.initialData = await this.productInformationApiService.getExternalApps();

        this.regions = this.codeListsService.regions;

        this.hubRegionCodeList = this.codeListsService.regions.map<HubRegionLink>(x => ({
            Id: x.Id,
            Name: x.Name,
            HubId: x.HubId,
            Link: '',
            Enabled: false
        }));

        this.hubCodeList = this.codeListsService.hubs
            .sort((h1, h2) => {
                if (h1.Name > h2.Name) {
                    return 1;
                }

                if (h1.Name < h2.Name) {
                    return -1;
                }

                return 0;
            }).map<Hub>(x => ({
                Id: x.Id,
                Name: x.Name,
                Regions: this.hubRegionCodeList.filter(y => y.HubId == x.Id)
            }));

        this.hubsFilter = this.hubCodeList.map(hub => ({
                HubId: hub.Id,
                Name: hub.Name,
                Selected: null
            }));

        this.$externalAppsGrid.jsGrid({
            data: this.initialData
        });
    }

    private hubsFilterChange(eventObject: JQuery.TriggeredEvent<any, any, any, any>, args: Chosen.SelectedData, id: number, isNew: boolean) {
        if ("selected" in args) {
            // if new hub was selected in filter, add hub node to make hub editable
            const hubCl = this.hubCodeList.find(hub => hub.Id.toString() == args.selected);

            const numberOfChosenHubs = document.getElementById('hubsFilter_chosen').getElementsByClassName('search-choice').length;

            if(!isNew) {
                const externalApp = this.initialData.find(x => x.Id == id);

                this.$externalAppsDialog.find('#hubs').append(externalAppsAddLinksDialogTemplate({
                    Id: hubCl.Id,
                    Name: hubCl.Name,
                    Index: numberOfChosenHubs - 1,
                    Regions: hubCl.Regions.map(regionCl => ({
                        Id: regionCl.Id,
                        Name: regionCl.Name,
                        HubId: regionCl.HubId,
                        Index: numberOfChosenHubs - 1,
                        Link: externalApp.Links.find(y => y.RegionId == regionCl.Id)?.Link,
                        Enabled: externalApp.Links.find(y => y.RegionId == regionCl.Id)?.Active ? 'checked' : ''
                    }))
                }));
            }
            else {
                this.$externalAppsDialog.find('#hubs').append(externalAppsAddLinksDialogTemplate({
                    Id: hubCl.Id,
                    Name: hubCl.Name,
                    Index: numberOfChosenHubs - 1,
                    Regions: hubCl.Regions.map(regionCl => ({
                        Id: regionCl.Id,
                        Name: regionCl.Name,
                        HubId: regionCl.HubId,
                        Index: numberOfChosenHubs - 1
                    }))
                }));
            }

            this.selectedHubs.push(hubCl.Id);
        }
        else if (args.deselected != null) {
            // hub was removed from filter, remove it also from editable hubs
            const hubCl = this.hubCodeList.find(hub => hub.Id.toString() == args.deselected);
            this.$externalAppsDialog.find('#externalAppAddLinkForm-' + args.deselected).remove();
            this.selectedHubs = this.selectedHubs.filter(x => x != hubCl.Id);
        }
    }

    private getAppLinks(form: JQuery<HTMLFormElement>) {
        const appLinks: ExternalAppLinks[] = [];

        for (let region of this.codeListsService.regions) {
            const elementIdBase = `#externalAppAddLinkForm-${region.HubId}-Region-${region.Id}`;
            const elementLink = form.find(`${elementIdBase}-Url`);
            const elementEnabled = form.find(`${elementIdBase}-Enabled`);
            const link = elementLink[0] as any;
            const enabled = elementEnabled[0] as any;

            if(link?.value || enabled?.value){
                const model: ExternalAppLinks = {
                    RegionId: region.Id,
                    Link: link.value,
                    Active: enabled.checked
                }

                appLinks.push(model);
            }
        }

        return appLinks;
    }

    private onTranslationKeyChange(isEdit = false) {
        const input = this.$externalAppsDialog.find('#translationKey').val().toString();
        this.$externalAppsDialog.find('#externalAppTranslationKey').text(this.getAppTranslationKey(input));

        if(isEdit) {
            this.$externalAppsDialog.find('#displayTranslationKey').text(this.getAppTranslationKey(input));
        }
    }

    private getAppTranslationKey(appPart: string) {
        return `Agito.Hilti.Profis3.ProjectAndDesign.Main.QuickStart.ExternalApplications.${appPart}`;
    }
}
