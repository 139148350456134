import { Injectable } from '@angular/core';
import {
    AdminGetDecodedUsersFileRequest, AdminGetDecodedUsersRequest,
    DecryptedMarketing,
    UserSettingsApiService
} from './user-settings-api.service';
import { downloadBlob } from '../browser';

type openGridFn = (data: DecryptedMarketing[]) => void;

@Injectable({
    providedIn: 'root'
})
export class DecodeUidService {
    private userIdsFile?: File; // store file if user wants to export later


    constructor(
        private userSettingsApiService: UserSettingsApiService
    ) {}

    public reset() {
        this.userIdsFile = undefined;
        this.hideError();
    }

    public handleFileDropped(files: FileList, openGrid?: openGridFn) {
        this.hideError();

        if (files.length > 0) {
            const file = files[0];
            const errors = this.validateFile(file);
            if (errors.length > 0) {
                this.showError(errors[0]);
                return;
            }

            this.handleFileChanged(file, openGrid);
        }
    }

    public handleFileChanged(file: File, openGrid?: openGridFn) {
        this.userIdsFile = file;
        this.uploadFile(this.userIdsFile, false, openGrid);
    }

    public async getDecodedUsersData(ids: string) {
        this.hideError();

        const errors = this.validateIds(ids);
        if (errors.length > 0) {
            this.showError(errors[0]);
            return Promise.reject(new Error(errors[0]));
        }

        return await this.getDecodedUsers({ identifiers: ids });
    }

    public async exportDecodedUsersData(textIds: string) {
        this.hideError();

        if (textIds != null && textIds != '') {
            await this.downloadDecodedUsersFile({
                identifiers: textIds
            });
        }
        else if (this.userIdsFile != null) {
            this.uploadFile(this.userIdsFile, true);
        }
        else {
            this.showError('Nothing to export! Enter at least one encrypted user id or attach a file!');
        }
    }


    private hideError() {
        $('#upload-error #error-message').html('');
        $('#upload-error').hide();
    }

    private showError(message: string) {
        $('#upload-error #error-message').html(message);
        $('#upload-error').show();

        $('#jsGridDecrypted').html('');
        $('#exportButton').hide();
        $('#copyButton').hide();
        $('#resultCount').html('');

        this.userIdsFile = undefined;
    }

    private validateFile(file: File) {
        const errors: string[] = [];

        const fileExt = file.name.substring(file.name.indexOf('.'));
        if (fileExt != '.xls' && fileExt != '.xlsx' && fileExt != '.csv') {
            errors.push('Wrong file type; only xls, xlsx and csv file types supported!');
        }

        return errors;
    }

    private validateIds(ids: string) {
        const errors: string[] = [];

        const regex = new RegExp(/[^\s,;]+/g);
        if (ids == null || ids == '' || regex.exec(ids) == null) {
            errors.push('Enter at least one encrypted user id!');
        }

        return errors;
    }

    private uploadFile(file: File, exportToFile: boolean, openGrid?: openGridFn) {
        this.hideError();

        const errors = this.validateFile(file);
        if (errors.length > 0) {
            this.showError(errors[0]);
            return;
        }

        $('#text-ids').val('');
        this.readFileContent(file, exportToFile, openGrid);
    }

    private readFileContent(file: File, exportToFile: boolean, openGrid?: openGridFn){
        const reader = new FileReader();
        reader.onload = async (event) => {
            const arrayBuffer = event.target.result as ArrayBuffer;
            const array = new Uint8Array(arrayBuffer);
            const content = btoa(String.fromCharCode.apply(null, array as any));
            await this.processFileContent(file.name, content, exportToFile, openGrid);
        }
        reader.readAsArrayBuffer(file);
    }

    private async processFileContent(filename: string, fileContent: string, exportToFile: boolean, openGrid: openGridFn) {
        const data = {
            file: {
                name: filename,
                content: fileContent
            }
        };

        if (exportToFile) {
            await this.downloadDecodedUsersFile(data);
        }
        else {
            openGrid(await this.getDecodedUsers(data));
        }
    }

    private async getDecodedUsers(request: AdminGetDecodedUsersRequest) {
        const response = await this.userSettingsApiService.adminGetDecodedUsers(request);
        return response.decryptedMarketings;
    }

    private async downloadDecodedUsersFile(request: AdminGetDecodedUsersFileRequest) {
        this.downloadCsv(await this.userSettingsApiService.adminGetDecodedUsersFile(request));
    }

    private downloadCsv(csvBlob: Blob) {
        downloadBlob(csvBlob, 'exported.csv');
    }
}
