import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PageLoadingService {
    get isLoading() {
        return Object.keys(this.loadingPromises).length > 0;
    }

    private loadingPromises: { [key: string]: Promise<void> } = {
        init: null
    };

    add(id: string, promise?: Promise<void>) {
        this.loadingPromises[id] = promise;
        promise?.finally(() => {
            delete this.loadingPromises[id];
        });
    }

    remove(id: string) {
        delete this.loadingPromises[id];
    }
}
