<h2 class="mb-3">Project Management</h2>

<form id="searchForm">
    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="userEmail">User email</label>
        <div class="col-sm-10">
            <input type="email" class="form-control" id="userEmail" name="userEmail" [(ngModel)]="userEmail" maxlength="100">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="projectName">Project name</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" id="projectName" name="projectName" [(ngModel)]="projectName" maxlength="100">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="designName">Design name</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" id="designName" name="designName" [(ngModel)]="designName" maxlength="100">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="designType">Design type</label>
        <div class="col-md-10">
            <select class="form-control" id="designType" name="designType" multiple>
                <option value="1">Conrete</option>
                <option value="2">Handrail</option>
                <option value="3">Masonry</option>
                <option value="4">MetalDeck</option>
            </select>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label">Design creation from</label>
        <div class="col-sm-10">
            <input id="createdDateFrom" name="createdDateFrom" type="text" class="form-control date-picker" placeholder="yyyy-mm-dd">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label">Design creation to</label>
        <div class="col-sm-10">
            <input id="createdDateTo" name="createdDateTo" type="text" class="form-control date-picker" placeholder="yyyy-mm-dd">
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-12">
            <button type="button" (click)="search()" class="btn btn-primary mr-2">Search</button>
            <button type="button" (click)="clear()" class="btn btn-secondary mr-2">Clear Filter</button>
            <div class="dropdown float-right">
                <button class="btn btn-primary dropdown-toggle" type="button" id="bulkActionsDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Bulk actions
                </button>
                <div class="dropdown-menu" aria-labelledby="bulkActionsDropdown">
                    <button type="button" (click)="removeUserForSelectedProjects()" class="dropdown-item btn-link">Unshare selected projects</button>
                    <button type="button" (click)="shareSelectedProjectsForUser()" class="dropdown-item btn-link">Share selected projects with user</button>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="main-div">
    <div id="jsGridProjectManagement"></div>
</div>

<div id="projectManagementDialog" title="Project Management">
</div>
