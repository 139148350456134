import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { DesignType } from '../entities/modules-entities';
import { ApiOptions, ApiService } from './api.service';
import { ModalService } from './modal.service';

export interface AdminGetProjectInfoRequest {
    projectId: string;
}

export interface AdminGetProjectInfoResponse {
    documents: Document[];
    users: User[];
    owner: string;
}

export interface Document {
    name: string;
    type: number;
    metadata: Metadata[];
    documentid: string;
    created: number;
    lastchange: number;
    locked: boolean;
    lockedby: string;
}

export interface Metadata {
    key: string;
    value: string;
}

export interface User {
    userid: string;
    name: string;
}

export interface AdminCheckIfUsersExistsRequest {
    userNames: string[];
}

export interface AdminCheckIfUsersExistsResponse {
    missingUserNames: string[];
}

export interface AdminAddUsersToProjectsRequest {
    projectids: string[];
    users: string[];
}

export interface AdminRemoveUsersFromProjectsRequest {
    ProjectIds: string[];
    users: string[];
}

export interface AdminListProjectsRequest {
    projectName: string;
    designName: string;
    designType: DesignType[];
    userEmail: string;
    createdDateFrom: string;
    createdDateTo: string;
}

export interface AdminListProjectsResponse {
    projects: Project[];
}

export interface Project {
    projectid: string;
    parentproject: Project;
    name: string;
    creator: User;
    created: number;
    updated: number;
}

export interface AdminUnshareProjectsRequest {
    projectids: string[];
    users: string[];
}

export interface AdminUnshareProjectsResponse {
    unshared: number;
}

export interface AdminDocumentExportRequestModel {
    documentIds: string[];
}

export interface AdminDocumentExportResponseModel {
    documentId: string;
    name: string;
    designType: number;
    fileContent: string;
    project: Project;
    owner: User;
}

export enum DevelopmentDeleteAllDesignsPattern {
    None = 0,
    LoadTest = 1,
    Automation = 2,
    User = 3
}

@Injectable({
    providedIn: 'root'
})
export class DocumentApiService {
    constructor(
        private apiService: ApiService,
        private modalService: ModalService
    ) { }

    private get documentServiceAdminUrlBase() {
        return environment.documentWebServiceAdminUrl;
    }


    // Projects
    async adminListProjects(request: AdminListProjectsRequest, apiOptions?: ApiOptions): Promise<AdminListProjectsResponse> {
        // create query params from request
        const urlRequest: string[][] = [];
        for (const [key, value] of Object.entries(request)) {
            if (value) {
                if (Array.isArray(value)) {
                    urlRequest.push(...value.map(x => [key, x?.toString()]));
                }
                else {
                    urlRequest.push([key, value?.toString()]);
                }
            }
        }

        const query = new URLSearchParams(urlRequest).toString();
        const httpRequest = new HttpRequest('GET', `${this.documentServiceAdminUrlBase}listProjects?${query}`);

        const projects = await this.apiService.request<Project[]>(httpRequest, apiOptions);
        return {
            projects
        };
    }

    async adminGetProjectInfo(request: AdminGetProjectInfoRequest, apiOptions?: ApiOptions): Promise<AdminGetProjectInfoResponse> {
        const httpRequest = new HttpRequest('GET', `${this.documentServiceAdminUrlBase}getProjectInfo/${encodeURIComponent(request.projectId)}`);

        return await this.apiService.request<AdminGetProjectInfoResponse>(httpRequest, apiOptions);
    }

    // Project share
    async adminAddUsersToProjects(request: AdminAddUsersToProjectsRequest, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('POST', `${this.documentServiceAdminUrlBase}addUsersToProjects`, request);

        return await this.apiService.request<void>(httpRequest, apiOptions);
    }

    async adminRemoveUsersFromProjects(request: AdminRemoveUsersFromProjectsRequest, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('DELETE', `${this.documentServiceAdminUrlBase}removeUsersFromProjects'`);
        // body is ignored for DELETE for some reason
        (httpRequest as any).body = request;

        return await this.apiService.request<void>(httpRequest, apiOptions);
    }

    async adminUnshareProjects(request: AdminUnshareProjectsRequest, apiOptions?: ApiOptions): Promise<AdminUnshareProjectsResponse> {
        const httpRequest = new HttpRequest<AdminUnshareProjectsRequest>('DELETE', `${this.documentServiceAdminUrlBase}/unshareProjects`);
        // body is ignored for DELETE for some reason
        (httpRequest as any).body = request;

        const unshared = await this.apiService.request<number>(httpRequest, apiOptions);
        return {
            unshared
        }
    }

    // Users
    async adminCheckIfUsersExists(request: AdminCheckIfUsersExistsRequest, apiOptions?: ApiOptions): Promise<AdminCheckIfUsersExistsResponse> {
        const httpRequest = new HttpRequest('POST', `${this.documentServiceAdminUrlBase}checkifusersexists`, request);

        try {
            await this.apiService.request<AdminGetProjectInfoResponse>(httpRequest, { ...apiOptions, supressErrorMessage: true });

            return { missingUserNames: [] };
        }
        catch (error) {
            // we might get back a 404 status code with missing user names
            if (error instanceof HttpErrorResponse && error.status == 404) {
                return { missingUserNames: error.error };
            }

            // we have an unknown error
            // show error message if needed
            if (!apiOptions?.supressErrorMessage) {
                this.modalService.showServiceErrorMessage(httpRequest, error);
            }

            throw error;
        }
    }

    // Documents
    async adminExportDocuments(request: AdminDocumentExportRequestModel, apiOptions?: ApiOptions): Promise<AdminDocumentExportResponseModel[]> {
        const httpRequest = new HttpRequest('POST', `${this.documentServiceAdminUrlBase}exportDocuments`, request);
        return await this.apiService.request<AdminDocumentExportResponseModel[]>(httpRequest, apiOptions);
    }

    // Design deletion
    async adminGetCurrentDeleteAllDesignsPattern(apiOptions?: ApiOptions): Promise<DevelopmentDeleteAllDesignsPattern> {
        const httpRequest = new HttpRequest('GET', `${this.documentServiceAdminUrlBase}getCurrentDeleteAllDesignsPattern`);
        return await this.apiService.request<DevelopmentDeleteAllDesignsPattern>(httpRequest, apiOptions);
    }

    async adminDeleteAllDesigns(pattern: DevelopmentDeleteAllDesignsPattern, apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('DELETE', `${this.documentServiceAdminUrlBase}deleteAllDesigns/${encodeURIComponent(pattern)}`);
        return await this.apiService.request<void>(httpRequest, apiOptions);
    }

    async adminCancelCurrentDeleteAllDesigns(apiOptions?: ApiOptions): Promise<void> {
        const httpRequest = new HttpRequest('PUT', `${this.documentServiceAdminUrlBase}cancelCurrentDeleteAllDesigns`, {});
        return await this.apiService.request<void>(httpRequest, apiOptions);
    }
}
