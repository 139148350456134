import escape from 'lodash-es/escape';

import { Component } from '@angular/core';

import { downloadBlob } from '../../browser';
import { AdminApiService, GdprData, TemplateGdprData } from '../../services/admin-api.service';
import { AppTitleService } from '../../services/app-title.service';
import { ModalService } from '../../services/modal.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { Page } from '../page';

interface GridData {
    Ranking: number;
    TemplateName: string;
    ContactPerson: string;
    Email: string;
    PhoneNumber: string;
    Fax: string;
}

@Component({
    selector: 'app-gdpr',
    templateUrl: './gdpr.component.html',
    styleUrls: ['./gdpr.component.css']
})
export class GdprComponent extends Page {
    pageTitle = $localize`:app-title GDPR|GDPR application title:GDPR`;

    private grid: JQuery<HTMLElement>;

    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private modalService: ModalService,
        private adminApiService: AdminApiService
    ) {
        super(appTitleService, pageLoadingService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.grid = $('#jsGridTemplateManagement');

        this.initGrid();

        $('#searchButton').click(async () => {
            const $form = $('#searchForm');
            const form = <HTMLFormElement>$form[0];

            if (form.reportValidity == null || form.reportValidity()) {
                const data = {
                    username: $('#searchForm #username').val() as string
                };

                const gdprData = await this.adminApiService.gdprGetUserGdprData(data);
                this.showGdprData(gdprData);

                // Since search probably results in less entries go back to page 1
                this.grid.jsGrid({
                    pageIndex: 1
                });
            }
        });

        $('#deleteButton').click(async () => {
            const $form = $('#displayForm');
            const form = <HTMLFormElement>$form[0];

            if (form.reportValidity == null || form.reportValidity()) {
                const data = {
                    UserId: $form.find('#responseUserId').val() as string,
                    Username: $form.find('#responseUsername').val() as string
                };

                if (await this.modalService.showConfirmation('Are you sure you want delete user GDPR data?', 'Delete user GDPR data')) {
                    const gdprData = await this.adminApiService.gdprDeleteUserGdprData(data);
                    this.showGdprData(gdprData);
                }
            }
        });

        $('#exportGDPR').click(async () => {
            const $form = $('#displayForm');
            const form = <HTMLFormElement>$form[0];

            if (form.reportValidity == null || form.reportValidity()) {
                const data = {
                    UserId: $form.find('#responseUserId').val() as string,
                    Username: $form.find('#responseUsername').val() as string
                };

                const blob = await this.adminApiService.gdprExportGdprData(data);
                downloadBlob(blob, 'Export.csv');
            }
        });
    }

    private initGrid() {
        this.grid.jsGrid({
            width: '100%',
            height: 'auto',
            sorting: true,
            paging: true,
            pageSize: 20,
            fields: [
                { name: 'Ranking', type: 'number', width: 100, title: 'Ranking', align: 'left', itemTemplate: escape },
                { name: 'TemplateName', type: 'text', width: 200, title: 'Template name', align: 'left', itemTemplate: escape },
                { name: 'ContactPerson', type: 'text', width: 200, title: 'Contact person', align: 'left', itemTemplate: escape },
                { name: 'Email', type: 'text', width: 200, title: 'E-mail', align: 'left', itemTemplate: escape },
                { name: 'PhoneNumber', type: 'text', width: 200, title: 'Phone number', align: 'left', itemTemplate: escape },
                { name: 'Fax', type: 'text', width: 200, title: 'Fax', align: 'left', itemTemplate: escape }
            ]
        });
    }

    private showGdprData(gdprData: GdprData) {
        $('#responseUsername').val(gdprData.Username);
        $('#responseUserId').val(gdprData.UserId);
        $('#Profis3Email').text(this.parseString(gdprData.Profis3Email));
        $('#Profis3Fax').text(this.parseString(gdprData.Profis3Fax));
        $('#Profis3PhoneNumber').text(this.parseString(gdprData.Profis3PhoneNumber));
        $('#Profis3Name').text(this.parseString(gdprData.Profis3GeneralName));
        $('#Profis3UserPrivacy').prop('checked', gdprData.Profis3UserPrivacy != null ? gdprData.Profis3UserPrivacy : false);
        $('#Profis3UserNotify').prop('checked', gdprData.Profis3UserNotify != null ? gdprData.Profis3UserNotify : false);
        $('#PurchaserEmail').text(this.parseString(gdprData.PurchaserEmail));
        $('#PurchaserFax').text(this.parseString(gdprData.PurchaserFax));
        $('#PurchaserPhoneNumber').text(this.parseString(gdprData.PurchaserPhoneNumber));
        $('#PurchaserName').text(this.parseString(gdprData.PurchaserGeneralName));
        $('#PurchaserUserPrivacy').prop('checked', gdprData.PurchaserUserPrivacy != null ? gdprData.PurchaserUserPrivacy : false);
        $('#PurchaserUserNotify').prop('checked', gdprData.PurchaserUserNotify != null ? gdprData.PurchaserUserNotify : false);

        if (gdprData.Username != null) {
            $('#exportGDPR').show();
            $('#deleteButton').show();
        }
        else {
            $('#exportGDPR').hide();
            $('#deleteButton').hide();
        }

        this.grid.jsGrid({
            data: this.prepareTemplateGridData(gdprData.TemplateGDPRData)
        });
    }

    private parseString(str: string) {
        if (str == null)
            return '';

        return str;
    }

    private prepareTemplateGridData(data: TemplateGdprData[]) {
        const gridData: GridData[] = [];

        for (let i = 0; i < data.length; i++) {
            const item = data[i];

            gridData.push({
                Ranking: i + 1,
                TemplateName: item.TemplateName != null ? item.TemplateName : '',
                ContactPerson: item.ContactPerson != null ? item.ContactPerson : '',
                Email: item.Email != null ? item.Email : '',
                PhoneNumber: item.PhoneNumber != null ? item.PhoneNumber : '',
                Fax: item.FaxNumber != null ? item.FaxNumber : ''
            });
        }

        return gridData;
    }
}
