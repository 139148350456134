import getPkce from 'oauth-pkce';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { routePaths } from '../modules/route-info';
import { OauthApiService, TokensRequest } from './oauth-api.service';
import { SessionStorageService } from './session-storage.service';

export interface Auth {
    readonly accessToken: string;
    readonly userId: string;
    readonly userName: string;
    readonly license: string;
}

const SessionStorageAuthKey = 'auth';
const SessionStorageLoginStateKey = 'login-state';
const SessionStorageRedirectUrlKey = 'redirect';
export const SessionStorageCodeVerifierKey = 'code_verifier';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _auth: Auth;
    get auth() {
        return this._auth;
    }

    private loginNavigationInProgress = false;
    private pendingAuthenticateFromOauthCallback: Promise<string> = null;


    constructor(
        private sessionStorageService: SessionStorageService,
        private activatedRoute: ActivatedRoute,
        private oauthApiService: OauthApiService,
        private router: Router
    ) { }


    public clear() {
        this._auth = null;
        this.sessionStorageService.remove(SessionStorageAuthKey);
    }

    private set(auth: Auth) {
        this.clear();

        this._auth = auth;
        this.sessionStorageService.set(SessionStorageAuthKey, auth);
    }

    /**
     * Returns true if we are already authenticated from SessionStorage, otherwise redirects to login page and returns a promise that is never resolved.
     */
    public async authenticateFromSessionStorage(redirectUrl?: string) {
        // we might be in the middle of authenticateFromOauthCallback
        // wait for it to finish before continuing
        // this can happen if a new navigation is started (user click that goes through AuthGuard) before tokens request is done
        if (this.pendingAuthenticateFromOauthCallback != null) {
            await this.pendingAuthenticateFromOauthCallback;
        }

        // check if we are authenticated, if not got to login page
        if (this.auth == null) {
            const auth = this.sessionStorageService.get<Auth>(SessionStorageAuthKey);
            if (auth) {
                this._auth = auth;
            }
            else if (environment.authentication == 'local'){
                this.sessionStorageService.set(SessionStorageAuthKey, this.generateLocalAccessToken());
                this.set({
                    accessToken: this.generateLocalAccessToken(),
                    userId: '7cd76c29cb794905ac96ab0adff915d4',
                    userName: 'Hailey.Bieber@agitavit.si',
                    license: this.generateLocalLicenses()
                });
            }
            else {
                await this.login(redirectUrl);

                // we are waiting for page navigation (window.location.href) in login so we should never get here
                throw new Error('login page navigation error');
            }
        }

        return true;
    }

    /**
     * Returns a promise that will never get resolved.
     */
    public async login(redirectUrl?: string): Promise<void> {
        if (!this.loginNavigationInProgress) {
            this.clear();

            // we check the login state when we get back from the login page
            const loginState = this.createNewLoginState();
            this.sessionStorageService.set(SessionStorageLoginStateKey, loginState);

            const country = this.activatedRoute.snapshot.queryParamMap.has('country')
                ? this.activatedRoute.snapshot.queryParamMap.get('country') : null;

            let language = navigator.language;
            if (/[\d_]/g.test(language) || (language.match(/-/g) || []).length > 1) {
                language = environment.defaultLanguage;
            }

            const loginUrl = new URL(`${environment.externalAuthenticationUrl}${environment.externalAuthorize}`);
            loginUrl.searchParams.append('client_id', environment.externalClientId);
            loginUrl.searchParams.append('redirect_uri', `${window.location.origin}/${routePaths.oauthCallback}`);
            loginUrl.searchParams.append('response_type', 'code');
            loginUrl.searchParams.append('scope', 'HC.Request.AllScopes');
            loginUrl.searchParams.append('state', loginState);
            loginUrl.searchParams.append('lang', language);

            if (country) {
                loginUrl.searchParams.append('country', country);
            }

            if (environment.authentication == 'oauth2') {
                const { verifier, challenge } = await this.getPkceAsync();
                this.sessionStorageService.set(SessionStorageCodeVerifierKey, verifier);
                loginUrl.searchParams.append('code_challenge', challenge);
                loginUrl.searchParams.append('code_challenge_method', 'S256');
            }

            // save the current url for when we get back from the login page
            this.sessionStorageService.set(SessionStorageRedirectUrlKey, redirectUrl ?? this.router.url);

            window.location.href = loginUrl.toString();
            this.loginNavigationInProgress = true;
        }

        // return a promise that is never resolved or rejected since we are waiting for page navigation (window.location.href)
        return new Promise<void>(() => { });
    }

    public async authenticateFromOauthCallback(code: string, loginState: string): Promise<string> {
        if (this.pendingAuthenticateFromOauthCallback != null) {
            throw new Error('only one authenticateFromOauthCallback can be run at the same time');
        }

        this.pendingAuthenticateFromOauthCallback = this.authenticateFromOauthCallbackInternal(code, loginState);
        try {
            return await this.pendingAuthenticateFromOauthCallback;
        }
        finally {
            this.pendingAuthenticateFromOauthCallback = null;
        }
    }


    private async authenticateFromOauthCallbackInternal(code: string, loginState: string) {
        // validate login state
        const originalLoginState = this.sessionStorageService.get(SessionStorageLoginStateKey);
        this.sessionStorageService.remove(SessionStorageLoginStateKey);

        if (!originalLoginState) {
            throw new Error('login state not found');
        }

        if (originalLoginState != loginState) {
            throw new Error('login state mismatch');
        }

        // fetch user info
        const tokensRequest: TokensRequest = {
            code,
            client_id: environment.externalClientId,
            grant_type: 'authorization_code',
            redirect_uri: `${window.location.origin}/${routePaths.oauthCallback}`
        };
        const tokensResponse = await this.oauthApiService.tokens(tokensRequest, { supressErrorMessage: true });

        // set auth
        this.set({
            accessToken: tokensResponse.access_token,
            userId: tokensResponse.subscription_info.UID,
            userName: tokensResponse.subscription_info.LogonID,
            license: tokensResponse.subscription_info.AuthorizationEntryList[0].Licenses
        });

        // return redirect url
        const redirectUrl = this.sessionStorageService.get<string>(SessionStorageRedirectUrlKey) ?? '/';
        this.sessionStorageService.remove(SessionStorageRedirectUrlKey);

        return redirectUrl;
    }

    private createNewLoginState() {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const length = 5;

        let result = '';
        for (let i = length; i > 0; --i) {
            result += chars[Math.round(Math.random() * (chars.length - 1))];
        }

        return result;
    }

    private async getPkceAsync() {
        return new Promise<{verifier: string, challenge: string}>((resolve) => {
            getPkce(43, (error, { verifier, challenge }) => {
              if (error) {
                  console.error(error.message);
                  throw error;
              }
              resolve({ verifier, challenge });
            });
          });
    }


    private generateLocalAccessToken = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYSabcdefghijklmnopqrstuvwxyz0123456789.-_';
        let result = '';

        for (let i = 0; i < 1416; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return result;
    };

    private generateLocalLicenses = () => {
        const obj = {
            application_name: 'P3 Baseplate Engineering Web D1',
            valid_until: '2099-01-12T11:11:11Z',
            licenses: [
                {
                    name: 'P3 Eng Web  - Basic / User',
                    key: 'DL0ZA7642C',
                    valid_until: '2019-04-30T00:00:00Z',
                    extension: false
                }
            ],
            features: [
                {
                    name: 'Basic',
                    key: 'BASIC'
                },
                {
                    name: 'Hrail',
                    key: 'HRAIL'
                },
                {
                    name: 'Advnc',
                    key: 'ADVNC'
                }
            ]
        };

        return btoa(JSON.stringify(obj));
    };
}
