import escape from 'lodash-es/escape';
import forEach from 'lodash-es/forEach';
import some from 'lodash-es/some';
import uniqueId from 'lodash-es/uniqueId';

export class MarketingCampaignHelper {
    private postalCodeInputSeparator = ';';

    public parsePostalCodeInput(input: string | string[] | number): string[] {
        let inputs = [];
        let parsedInput: string[] = [];

        // parse by type
        if (typeof input === "number"){
            inputs.push(input.toString());
        }
        else if (Array.isArray(input) && input.every(x => typeof x === 'string')) {
            forEach(input, x => inputs.push(x));
        }
        else if (typeof input === 'string' && some(input)) {
            inputs.push(input);
        }

        //parse each element by separator ';'
        forEach(inputs, item => {
            const parsedBySeparator = item.split(this.postalCodeInputSeparator);

            parsedInput.push(...parsedBySeparator);
        })

        return parsedInput;
    }

    public addPostalCode(input: string) {
        if (!some(input.trim())) {
             return;
        }

        input = escape(input);

        const elementId = uniqueId();

        // clear input
        $('#addEditMarketingCampaignPostalCodeInput').val("");

        // create new html node with event listeners
        const $div = $('<div id="postal-code-' + elementId+'"></div>');
        const $text = $('<input name="PostalCodes['+elementId+']" type="hidden" value="'+input+'" />');
        const $close = $('<li class="search-choice"><span>' + input + '</span><a class="search-choice-close"></a></li>');

        $close.find('.search-choice-close').on('click', () => this.deletePostalCode(elementId));

        $div.append($text);
        $div.append($close);

        $('#addEditMarketingCampaignPostalCodes').find('#postalCodes').append($div);
    }

    private deletePostalCode(id: string) {
        $('#addEditMarketingCampaignPostalCodes').find('[id^="postal-code-' + id + '"]').remove();
    }
}