import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppTitleService {
    private pageTitle?: string;

    private environmentApplicationVersion: string;
    private serviceApplicationVersionLoaded = false;


    constructor(private titleService: Title) {
        this.environmentApplicationVersion = environment.applicationVersion;
    }


    public setPageTitle(pageTitle: string) {
        this.pageTitle = pageTitle;
        this.updatePageTitle();
    }

    public setAppTitle() {
        this.pageTitle = undefined;
        this.updatePageTitle();
    }

    public updatePageTitle() {
        let title = '';

        if (this.pageTitle) {
            title = `${this.pageTitle} - `;
        }

        title = `${title}${environment.solutionName}`;

        if (this.serviceApplicationVersionLoaded) {
            title = `${title} - ${environment.applicationVersion}`;
        }

        this.titleService.setTitle(title);
    }

    public setApplicationVersion(applicationVersion: string) {
        if (applicationVersion) {
            environment.applicationVersion = applicationVersion;
        }
        else {
            environment.applicationVersion = this.environmentApplicationVersion;
        }

        this.serviceApplicationVersionLoaded = true;
    }
}
