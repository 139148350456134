import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppTitleService } from '../../services/app-title.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { VersionDetailsService } from '../../services/version-details.service';
import { Page } from '../page';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.css']
})
export class VersionComponent extends Page {
    pageTitle = $localize`:app-title version|Version title:Version`;

    public suiteVersion = '';
    public suiteBuildVersion = '';
    public cluster = '';


    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private versionDetailsService: VersionDetailsService
    ) {
        super(appTitleService, pageLoadingService);
    }

    public async ngOnInit() {
        this.suiteVersion = environment.applicationVersion;

        const clusterData = await this.versionDetailsService.getClusterDetails();
        this.cluster = clusterData.cluster;

        this.suiteBuildVersion = environment.version;

        super.ngOnInit();
    }
}
