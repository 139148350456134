import { Directive, OnInit } from '@angular/core';

import { AppTitleService } from '../services/app-title.service';
import { PageLoadingService } from '../services/page-loading.service';

@Directive()
export abstract class Page implements OnInit {
    abstract pageTitle: string;

    constructor(
        protected appTitleService: AppTitleService,
        protected pageLoadingService: PageLoadingService
    ) { }

    ngOnInit(): void {
        this.appTitleService.setPageTitle(this.pageTitle);
        this.pageLoadingService.remove('init');
    }
}
