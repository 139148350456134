import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';
import { ApplicationVersionsService } from '../services/application-versions.service';
import { CodeListsService } from '../services/code-lists.service';
import { handleGuardAction } from './guard-handler';

@Injectable({
    providedIn: 'root'
})
export class InitializationGuard implements CanActivate {
    constructor(
        private codeListsService: CodeListsService,
        private applicationVersionsService: ApplicationVersionsService,
        private router: Router
    ) { }

    canActivate(
        _next: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        return handleGuardAction(
            this.router,
            async () => {
                const setApplicationVersionPromise = this.applicationVersionsService.setApplicationVersion();
                const codeListsPromise = this.codeListsService.initialize();
                await Promise.all([setApplicationVersionPromise, codeListsPromise]);
                return Promise.resolve(true);
            }
        );
    }
}
