import { Component } from '@angular/core';

import { PageLoadingService } from '../../services/page-loading.service';

@Component({
    selector: 'app-page-loading',
    templateUrl: './page-loading.component.html',
    styleUrls: ['./page-loading.component.css']
})
export class PageLoadingComponent {
    constructor(public pageLoadingService: PageLoadingService) { }
}
