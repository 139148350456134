import { map, single } from 'rxjs/operators';

import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface ApiOptions {
    supressErrorMessage?: boolean;
}

export interface ApiHttpRequest<T> extends HttpRequest<T> {
    supressErrorMessage?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private httpClient: HttpClient) { }

    request<T = void>(httpRequest: HttpRequest<any>, options?: ApiOptions) {
        (httpRequest as ApiHttpRequest<any>).supressErrorMessage = options?.supressErrorMessage;

        return this.httpClient.request<T>(httpRequest)
            .pipe(
                single(x => x instanceof HttpResponse),
                map(x => (x as HttpResponse<T>).body)
            )
            .toPromise();
    }
}
