import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.authenticationRequired.some(x => req.url.startsWith(x))) {
            // if we are not authenticated go to login
            if (this.authService.auth == null) {
                // returns a promise that will never get resolved so it's fine if we cast it to any
                return from(this.authService.login()) as any;
            }

            if (!this.authService.auth.accessToken) {
                throw new Error('accessToken is not set');
            }

            const headers: { [name: string]: string | string[] } = {
                Authorization: `Bearer ${this.authService.auth.accessToken}`
            };

            if (environment.includeHCHeaders) {
                if (this.authService.auth.userId) {
                    headers['HC-UID'] = this.authService.auth.userId;
                }

                if (this.authService.auth.userName) {
                    headers['HC-User'] = this.authService.auth.userName;
                }

                if (this.authService.auth.license) {
                    headers['HC-License'] = this.authService.auth.license;
                }
            }

            req = req.clone({ setHeaders: headers });
        }

        return next.handle(req)
            .pipe(
                tap(event => undefined, error => this.handleError(req, error))
            );
    }

    private async handleError(req: HttpRequest<any>, error: unknown) {
        // go to login page on unauthorized (401) for our services
        if (error instanceof HttpErrorResponse && error.status == 401 && environment.authenticationRequired.some(x => req.url.startsWith(x))) {
            await this.authService.login();
        }
    }
}
