import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    get<T>(key: string) {
        return JSON.parse(sessionStorage.getItem(key) ?? null) as T;
    }

    set(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    remove(key: string) {
        sessionStorage.removeItem(key);
    }
}
