import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';

import { AuthService } from '../services/auth.service';
import { handleGuardAction } from './guard-handler';

@Injectable({
    providedIn: 'root'
})
export class OauthCallbackGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        return handleGuardAction(this.router, async () => {
            const code = state.root.queryParamMap.get('code');
            const loginState = state.root.queryParamMap.get('state');

            if (!code) {
                throw new Error('code missing in url');
            }

            if (!loginState) {
                throw new Error('state missing in url');
            }

            const redirectUrl = await this.authService.authenticateFromOauthCallback(code, loginState);

            return this.router.parseUrl(redirectUrl);
        });
    }
}
