var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"releaseNoteForm\">\n    <input id=\"noteId\" name=\"noteId\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"NoteId") || (depth0 != null ? lookupProperty(depth0,"NoteId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NoteId","hash":{},"data":data,"loc":{"start":{"line":2,"column":58},"end":{"line":2,"column":68}}}) : helper)))
    + "\" />\n    <input id=\"versionId\" name=\"versionId\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"VersionId") || (depth0 != null ? lookupProperty(depth0,"VersionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"VersionId","hash":{},"data":data,"loc":{"start":{"line":3,"column":64},"end":{"line":3,"column":77}}}) : helper)))
    + "\" />\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"noteTitle\">Title</label>\n        <div class=\"col-sm-9\">\n            <input type=\"text\" class=\"form-control note-input\" id=\"noteTitle\" name=\"noteTitle\" required=\"required\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"NoteTitle") || (depth0 != null ? lookupProperty(depth0,"NoteTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NoteTitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":23},"end":{"line":9,"column":36}}}) : helper)))
    + "\" maxlength=\"500\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"releaseVersion\">Release version</label>\n        <div class=\"col-sm-9\">\n            <input type=\"text\" class=\"form-control note-input\" id=\"releaseVersion\" name=\"releaseVersion\"\n                required=\"required\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ReleaseVersion") || (depth0 != null ? lookupProperty(depth0,"ReleaseVersion") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ReleaseVersion","hash":{},"data":data,"loc":{"start":{"line":16,"column":43},"end":{"line":16,"column":61}}}) : helper)))
    + "\" maxlength=\"100\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"releaseDate\">Release date</label>\n        <div class=\"col-sm-9\">\n            <input type=\"text\" class=\"form-control date-picker\" id=\"releaseDate\" name=\"releaseDate\" required=\"required\"\n                value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ReleaseDate") || (depth0 != null ? lookupProperty(depth0,"ReleaseDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ReleaseDate","hash":{},"data":data,"loc":{"start":{"line":23,"column":23},"end":{"line":23,"column":38}}}) : helper)))
    + "\" placeholder=\"yyyy-mm-dd\">\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"noteContent\">Content</label>\n        <div class=\"col-sm-9\">\n            <textarea rows=\"8\" class=\"form-control note-input\" id=\"noteContent\" name=\"noteContent\"\n                required=\"required\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NoteContent") || (depth0 != null ? lookupProperty(depth0,"NoteContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NoteContent","hash":{},"data":data,"loc":{"start":{"line":30,"column":36},"end":{"line":30,"column":51}}}) : helper)))
    + "</textarea>\n        </div>\n    </div>\n</form>\n";
},"useData":true});