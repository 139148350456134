import { Component } from '@angular/core';

import { AppTitleService } from '../../services/app-title.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { ProductInformationApiService } from '../../services/product-information-api.service';
import { Page } from '../page';
import { CodeListsService } from '../../services/code-lists.service';
import { HubRegion } from '../../entities/hubs-regions';
import anchoraiRegionFormTemplate from './anchorai-region-form.hbs';
@Component({
    selector: 'app-anchor-ai',
    templateUrl: './anchor-ai.component.html',
    styleUrls: ['./anchor-ai.component.css']
})
export class AnchorAiComponent extends Page {
    pageTitle = $localize`:app-title anchor ai|Anchor.AI title:Anchor.AI`;

    public emails: string;
    hubRegionCodeList: HubRegion[];
    public regions: string;

    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private productInformationApiService: ProductInformationApiService,
        private codeListsService: CodeListsService
    ) {
        super(appTitleService, pageLoadingService);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        await this.loadData();
        $('#anchorAiSearchRegion').chosen();
        const userData = await this.productInformationApiService.anchorAiGetUserAccess();
        this.emails = userData.Emails;
        this.regions = userData.Regions;
        $('#anchorAiSearchRegion').val(this.regions.split(',')).trigger('chosen:updated');
    }

    private async loadData() {
        this.hubRegionCodeList = this.codeListsService.regions.map<HubRegion>(x => ({
            Id: x.Id,
            Name: x.Name,
            HubId: x.HubId
        }));

        const $addDialog = $('#anchoraiRegionForm');

        $addDialog.html(anchoraiRegionFormTemplate({
            Regions: this.hubRegionCodeList
        }));
    }

    public async save(): Promise<void> {
        await this.productInformationApiService.anchorAiUpdateUserAccess(this.emails, this.regions);
    }

    public async saveRegion(): Promise<void> {
        const regionIds: string[] = $('#anchorAiSearchRegion').val() as string[];
        this.regions = regionIds.join(',');
        await this.productInformationApiService.anchorAiUpdateUserAccess(this.emails, this.regions);
    }
}
