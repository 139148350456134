<h2>Development API</h2>

<div>
    <button class="btn btn-primary mr-2" (click)="deleteAllDesigns(developmentDeleteAllDesignsPattern.LoadTest)" [disabled]="designDeletionInProgressPattern != 0">
        Delete load test designs
    </button>

    <button class="btn btn-primary mr-2" (click)="deleteAllDesigns(developmentDeleteAllDesignsPattern.Automation)" [disabled]="designDeletionInProgressPattern != 0">
        Delete automation designs
    </button>

    <button class="btn btn-primary mr-2" (click)="deleteAllDesigns(developmentDeleteAllDesignsPattern.User)" [disabled]="designDeletionInProgressPattern != 0">
        Delete my designs
    </button>

    <button class="btn btn-danger mr-2" (click)="cancelCurrentDesignsDeletion()" *ngIf="designDeletionInProgressPattern != 0">
        Cancel current designs deletion
    </button>


    <h4 class="mt-3" *ngIf="designDeletionInProgressPattern == developmentDeleteAllDesignsPattern.LoadTest">
        Deleting load test designs and projects. This might take a while, please wait!
    </h4>
    <h4 class="mt-3" *ngIf="showSuccessMessageDeleteAllDesigns == developmentDeleteAllDesignsPattern.LoadTest">
        Load test designs and projects deleted!
    </h4>
    <h4 class="mt-3" *ngIf="showTimeoutMessageDeleteAllDesigns == developmentDeleteAllDesignsPattern.LoadTest">
        Delete load test designs and projects is still processing the background... This might take up to 1h, please wait some time and manually check if designs were removed!
    </h4>

    <h4 class="mt-3" *ngIf="designDeletionInProgressPattern == developmentDeleteAllDesignsPattern.Automation">
        Deleting automation designs and projects. This might take a while, please wait!
    </h4>
    <h4 class="mt-3" *ngIf="showSuccessMessageDeleteAllDesigns == developmentDeleteAllDesignsPattern.Automation">
        Automation designs and projects deleted!
    </h4>
    <h4 class="mt-3" *ngIf="showTimeoutMessageDeleteAllDesigns == developmentDeleteAllDesignsPattern.Automation">
        Delete automation designs and projects is still processing the background... This might take up to 1h, please wait some time and manually check if designs were removed!
    </h4>

    <h4 class="mt-3" *ngIf="designDeletionInProgressPattern == developmentDeleteAllDesignsPattern.User">
        Deleting {{userName}} designs and projects. This might take a while, please wait!
    </h4>
    <h4 class="mt-3" *ngIf="showSuccessMessageDeleteAllDesigns == developmentDeleteAllDesignsPattern.User">
        {{userName}} designs and projects deleted!
    </h4>
    <h4 class="mt-3" *ngIf="showTimeoutMessageDeleteAllDesigns == developmentDeleteAllDesignsPattern.User">
        Delete {{userName}} designs and projects is still processing the background... This might take up to 1h, please wait some time and manually check if designs were removed!
    </h4>
</div>
