import { Component } from '@angular/core';

import { AppTitleService } from '../../services/app-title.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { ProductInformationApiService } from '../../services/product-information-api.service';
import { Page } from '../page';

@Component({
    selector: 'app-checkbot-plugin',
    templateUrl: './checkbot-plugin.component.html',
    styleUrls: ['./checkbot-plugin.component.css']
})
export class CheckbotPluginComponent extends Page {
    pageTitle = $localize`:app-title checkbot plugin|Checkbot Plugin title:Checkbot Plugin`;

    public emails: string;

    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private productInformationApiService: ProductInformationApiService
    ) {
        super(appTitleService, pageLoadingService);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        this.emails = await this.productInformationApiService.checkbotPluginGetUserAccess();
    }

    public async save(): Promise<void> {
        await this.productInformationApiService.checkbotPluginUpdateUserAccess(this.emails);
    }
}
