<div class="main-div">
    <div class="header">
        <h2 class="mb-3">Hilti Applications</h2>
        <button (click)="addApp()" type="button" name="addApp" class="btn btn-primary float-right" id="addAppButton">Add Application</button>
    </div>

    <div id="hiltiApplicationsGrid"></div>
</div>

<div id="deleteHiltiApplicationDialog">
    Are you sure you want to delete the Hilti application?
</div>

<div id="hiltiApplicationsDialog" title="Hilti Applications">
</div>

<div id="hiltiApplicationsLinksDialog" title="Hilti Application Links">
</div>
