export class QueryStringHelper {
    urlSearchParams: URLSearchParams;

    constructor(search: string) {
        this.urlSearchParams = new URLSearchParams(search);
    }

    getBoolean(name: string, caseInsensitive = true): boolean {
        const val = this.get(name, caseInsensitive);
        if (val != null && val.toLowerCase() == 'true') {
            return true;
        }
        return false;
    }

    private get(name: string, caseInsensitive = true): string | null {
        if (!caseInsensitive) {
            // URLSearchParams is case sensitive by default.
            return this.urlSearchParams.get(name);
        }

        const nameLc = name.toLowerCase();
        let retVal = null as string;
        this.urlSearchParams.forEach((value: string, key: string) => {
            if (retVal == null && key.toLowerCase() == nameLc) {
                retVal = value;
            }
        });

        return retVal;
    }
}
