import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { ApiHttpRequest } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    showMessage(message: string, title: string) {
        $('<div></div>').dialog({
            width: 500,
            modal: true,
            resizable: false,
            draggable: false,
            title: title,
            buttons: [{
                text: $localize`:modal-service show-message close button|Close button in message popup:Close`,
                click: function (this: HTMLElement) {
                    $(this).dialog('close');
                }
            }],
            close: function (this: HTMLElement) {
                $(this).remove();
            }
        }).text(message);
    }

    showConfirmation(message: string, title: string) {
        return new Promise<boolean>((resolve, reject) => {
            $('<div></div>').dialog({
                width: 500,
                modal: true,
                resizable: false,
                draggable: false,
                title: title,
                dialogClass: 'ui-confirmation-dialog',
                buttons: [
                    {
                        text: $localize`:modal-service show-confirmation no button|No button in confirmation popup:No`,
                        click: function (this: HTMLElement) {
                            $(this).dialog('close');
                        }
                    },
                    {
                        text: $localize`:modal-service show-confirmation yes button|Yes button in confirmation popup:Yes`,
                        click: function (this: HTMLElement) {
                            resolve(true);
                            $(this).dialog('close');
                        }
                    }
                ],
                close: function (this: HTMLElement) {
                    resolve(false);
                    $(this).remove();
                }
            }).text(message);
        });
    }

    showServiceErrorMessage(req: ApiHttpRequest<any>, error: unknown) {
        // unauthorized (401) for our services will be handled by auth-interceptor
        if (!(error instanceof HttpErrorResponse) || error.status != 401 || !environment.authenticationRequired.some(x => req?.url.startsWith(x))) {
            const message = error instanceof HttpErrorResponse && error.error?.Message
                ? error.error?.Message
                : $localize`:api error message|Message for an API error popup:Something went wrong, please try again later.`;

            this.showMessage(
                message,
                $localize`:api error title|Title for an API error popup:Error`,
            );
        }
    }
}
