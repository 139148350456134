var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "        <div id=\"addEditMarketingCampaignHub-"
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":10,"column":45},"end":{"line":10,"column":54}}}) : helper)))
    + "-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":10,"column":62},"end":{"line":10,"column":68}}}) : helper)))
    + "\" class=\"regions-item\">\n            <div class=\"row margin-bottom-10\">\n                <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":12,"column":34},"end":{"line":12,"column":43}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":12,"column":53},"end":{"line":12,"column":63}}}) : helper)))
    + "].RegionId\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":12,"column":96},"end":{"line":12,"column":102}}}) : helper)))
    + "\" />\n                <span class=\"regions-item-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":13,"column":48},"end":{"line":13,"column":56}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RegionLanguages") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":57},"end":{"line":13,"column":96}}})) != null ? stack1 : "")
    + "</span>\n            </div>\n            <div class=\"row margin-bottom-10\">\n                <div class=\"row col-sm-12\">\n                    <div class=\"col-sm-5\">\n                        <span class=\"regions-item-label\">Url</span>\n                        <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":19,"column":42},"end":{"line":19,"column":51}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":19,"column":61},"end":{"line":19,"column":71}}}) : helper)))
    + "].Url\" type=\"url\" maxlength=\"1024\"\n                            value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Url") || (depth0 != null ? lookupProperty(depth0,"Url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Url","hash":{},"data":data,"loc":{"start":{"line":20,"column":35},"end":{"line":20,"column":42}}}) : helper)))
    + "\" class=\"form-control\" />\n                    </div>\n                    <div class=\"col-sm-5\">\n                        <span class=\"regions-item-label\">Link</span>\n                        <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":24,"column":42},"end":{"line":24,"column":51}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":24,"column":61},"end":{"line":24,"column":71}}}) : helper)))
    + "].Link\" type=\"url\" maxlength=\"1024\"\n                            value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Link") || (depth0 != null ? lookupProperty(depth0,"Link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Link","hash":{},"data":data,"loc":{"start":{"line":25,"column":35},"end":{"line":25,"column":43}}}) : helper)))
    + "\" class=\"form-control\" />\n                    </div>\n                    <div class=\"col-sm-2\">\n                        <span class=\"regions-item-label\">Enabled</span>\n                        <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":29,"column":42},"end":{"line":29,"column":51}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":29,"column":61},"end":{"line":29,"column":71}}}) : helper)))
    + "].Enabled\" value=\"true\" type=\"checkbox\"\n                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"Enabled") || (depth0 != null ? lookupProperty(depth0,"Enabled") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Enabled","hash":{},"data":data,"loc":{"start":{"line":30,"column":28},"end":{"line":30,"column":39}}}) : helper)))
    + " class=\"regions-item-enabled\" />\n                    </div>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"InAppPlacement") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":49,"column":20}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"RegionLanguages") || (depth0 != null ? lookupProperty(depth0,"RegionLanguages") : depth0)) != null ? helper : alias2),(options={"name":"RegionLanguages","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":133,"column":32}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"RegionLanguages")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "(default)";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row margin-bottom-10\">\n                <div class=\"row col-sm-12  margin-bottom-10\">\n                    <div class=\"col-sm-5\">\n                        <span class=\"regions-item-label\">Title</span>\n                        <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":39,"column":42},"end":{"line":39,"column":51}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":39,"column":61},"end":{"line":39,"column":71}}}) : helper)))
    + "].Title\" type=\"text\" maxlength=\"200\"\n                            value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":40,"column":35},"end":{"line":40,"column":44}}}) : helper)))
    + "\" class=\"form-control\" />\n                    </div>\n                    <div class=\"col-sm-7\">\n                        <span class=\"regions-item-label\">Description</span>\n                        <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":44,"column":42},"end":{"line":44,"column":51}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":44,"column":61},"end":{"line":44,"column":71}}}) : helper)))
    + "].Description\" type=\"text\" maxlength=\"500\"\n                            value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":45,"column":35},"end":{"line":45,"column":50}}}) : helper)))
    + "\" class=\"form-control\" />\n                    </div>\n                </div>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "            <div class=\"row margin-bottom-10\"\n                id=\"addEditMarketingCampaignHub-"
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":52,"column":48},"end":{"line":52,"column":57}}}) : helper)))
    + "-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionId") || (depth0 != null ? lookupProperty(depth0,"RegionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionId","hash":{},"data":data,"loc":{"start":{"line":52,"column":65},"end":{"line":52,"column":77}}}) : helper)))
    + "-RegionLanguage-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LcId") || (depth0 != null ? lookupProperty(depth0,"LcId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LcId","hash":{},"data":data,"loc":{"start":{"line":52,"column":93},"end":{"line":52,"column":101}}}) : helper)))
    + "\">\n                <div class=\"col-sm-12 margin-top-10\">\n                    <div class=\"col-sm-4\"></div>\n                    <div class=\"col-sm-4\">\n                        <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":56,"column":42},"end":{"line":56,"column":51}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":56,"column":61},"end":{"line":56,"column":70}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":56,"column":87},"end":{"line":56,"column":97}}}) : helper)))
    + "].LcId\" type=\"hidden\"\n                            value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LcId") || (depth0 != null ? lookupProperty(depth0,"LcId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LcId","hash":{},"data":data,"loc":{"start":{"line":57,"column":35},"end":{"line":57,"column":43}}}) : helper)))
    + "\" />\n                        <span class=\"region-language-item-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionLanguage") || (depth0 != null ? lookupProperty(depth0,"RegionLanguage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionLanguage","hash":{},"data":data,"loc":{"start":{"line":58,"column":64},"end":{"line":58,"column":82}}}) : helper)))
    + "</span>\n                    </div>\n                    <div class=\"row\">\n                        <div class=\"col-sm-1\"></div>\n                        <div class=\"col-sm-4\">\n                            <span class=\"regions-item-label\">Url</span>\n                            <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":64,"column":46},"end":{"line":64,"column":55}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":64,"column":65},"end":{"line":64,"column":74}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":64,"column":91},"end":{"line":64,"column":101}}}) : helper)))
    + "].Url\" type=\"url\"\n                                maxlength=\"1024\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Url") || (depth0 != null ? lookupProperty(depth0,"Url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Url","hash":{},"data":data,"loc":{"start":{"line":65,"column":56},"end":{"line":65,"column":63}}}) : helper)))
    + "\" class=\"form-control\" />\n                        </div>\n                        <div class=\"col-sm-4\">\n                            <span class=\"regions-item-label\">Link</span>\n                            <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":69,"column":46},"end":{"line":69,"column":55}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":69,"column":65},"end":{"line":69,"column":74}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":69,"column":91},"end":{"line":69,"column":101}}}) : helper)))
    + "].Link\" type=\"url\"\n                                maxlength=\"1024\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Link") || (depth0 != null ? lookupProperty(depth0,"Link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Link","hash":{},"data":data,"loc":{"start":{"line":70,"column":56},"end":{"line":70,"column":64}}}) : helper)))
    + "\" class=\"form-control\" />\n                        </div>\n                        <div class=\"col-sm-2 margin-top-30\">\n                            <span class=\"regions-item-label\">Enabled</span>\n                            <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":74,"column":46},"end":{"line":74,"column":55}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":74,"column":65},"end":{"line":74,"column":74}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":74,"column":91},"end":{"line":74,"column":101}}}) : helper)))
    + "].Enabled\"\n                                value=\"true\" type=\"checkbox\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"Enabled") || (depth0 != null ? lookupProperty(depth0,"Enabled") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Enabled","hash":{},"data":data,"loc":{"start":{"line":75,"column":61},"end":{"line":75,"column":72}}}) : helper)))
    + " class=\"region-language-item-enabled\" />\n                        </div>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"InAppPlacement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":20},"end":{"line":92,"column":28}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"RegionLanguages") || (depth0 != null ? lookupProperty(depth0,"RegionLanguages") : depth0)) != null ? helper : alias2),(options={"name":"RegionLanguages","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":20},"end":{"line":130,"column":40}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"RegionLanguages")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row\">\n                        <div class=\"col-sm-1\"></div>\n                        <div class=\"col-sm-4 margin-top-10\">\n                            <span class=\"regions-item-label\">Title</span>\n                            <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":83,"column":46},"end":{"line":83,"column":55}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":83,"column":65},"end":{"line":83,"column":74}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":83,"column":91},"end":{"line":83,"column":101}}}) : helper)))
    + "].Title\"\n                                type=\"text\" maxlength=\"200\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":84,"column":67},"end":{"line":84,"column":76}}}) : helper)))
    + "\" class=\"form-control\" />\n                        </div>\n                        <div class=\"col-sm-6 margin-top-10\">\n                            <span class=\"regions-item-label\">Description</span>\n                            <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":88,"column":46},"end":{"line":88,"column":55}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":88,"column":65},"end":{"line":88,"column":74}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":88,"column":91},"end":{"line":88,"column":101}}}) : helper)))
    + "].Description\"\n                                type=\"text\" maxlength=\"500\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":89,"column":67},"end":{"line":89,"column":82}}}) : helper)))
    + "\" class=\"form-control\" />\n                        </div>\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row margin-bottom-10\" id=\"addEditMarketingCampaignHub-"
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":94,"column":90},"end":{"line":94,"column":99}}}) : helper)))
    + "-Region-"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionId") || (depth0 != null ? lookupProperty(depth0,"RegionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionId","hash":{},"data":data,"loc":{"start":{"line":94,"column":107},"end":{"line":94,"column":119}}}) : helper)))
    + "-RegionLanguage-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LcId") || (depth0 != null ? lookupProperty(depth0,"LcId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LcId","hash":{},"data":data,"loc":{"start":{"line":94,"column":135},"end":{"line":94,"column":143}}}) : helper)))
    + "\">\n                            <div class=\"row col-sm-12  margin-bottom-10\">\n                                <div class=\"col-sm-2 margin-top-30\">\n                                       <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":97,"column":57},"end":{"line":97,"column":66}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":97,"column":76},"end":{"line":97,"column":85}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":97,"column":102},"end":{"line":97,"column":112}}}) : helper)))
    + "].LcId\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LcId") || (depth0 != null ? lookupProperty(depth0,"LcId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LcId","hash":{},"data":data,"loc":{"start":{"line":97,"column":141},"end":{"line":97,"column":149}}}) : helper)))
    + "\" />\n                                        <span class=\"region-language-item-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"RegionLanguage") || (depth0 != null ? lookupProperty(depth0,"RegionLanguage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RegionLanguage","hash":{},"data":data,"loc":{"start":{"line":98,"column":80},"end":{"line":98,"column":98}}}) : helper)))
    + "</span>\n                                </div>\n                                <div class=\"col-sm-4\">\n                                         <span class=\"regions-item-label\">Url</span>\n                                         <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":102,"column":59},"end":{"line":102,"column":68}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":102,"column":78},"end":{"line":102,"column":87}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":102,"column":104},"end":{"line":102,"column":114}}}) : helper)))
    + "].Url\" type=\"url\" maxlength=\"1024\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Url") || (depth0 != null ? lookupProperty(depth0,"Url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Url","hash":{},"data":data,"loc":{"start":{"line":102,"column":156},"end":{"line":102,"column":163}}}) : helper)))
    + "\"\n                                            class=\"form-control\" />\n                                </div>\n                                <div class=\"col-sm-4\">\n                                    <span class=\"regions-item-label\">Link</span>\n                                    <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":107,"column":54},"end":{"line":107,"column":63}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":107,"column":73},"end":{"line":107,"column":82}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":107,"column":99},"end":{"line":107,"column":109}}}) : helper)))
    + "].Link\" type=\"url\" maxlength=\"1024\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Link") || (depth0 != null ? lookupProperty(depth0,"Link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Link","hash":{},"data":data,"loc":{"start":{"line":107,"column":152},"end":{"line":107,"column":160}}}) : helper)))
    + "\"\n                                        class=\"form-control\" />\n                                </div>\n                                    <div class=\"col-sm-2 margin-top-30\">\n                                        <span class=\"regions-item-label\">Enabled</span>\n                                    <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":112,"column":54},"end":{"line":112,"column":63}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":112,"column":73},"end":{"line":112,"column":82}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":112,"column":99},"end":{"line":112,"column":109}}}) : helper)))
    + "].Enabled\" value=\"true\" type=\"checkbox\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"Enabled") || (depth0 != null ? lookupProperty(depth0,"Enabled") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Enabled","hash":{},"data":data,"loc":{"start":{"line":112,"column":149},"end":{"line":112,"column":160}}}) : helper)))
    + "\n                                        class=\"region-language-item-enabled\" />\n                                </div>\n                            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"InAppPlacement") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":29},"end":{"line":128,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"row col-sm-12  margin-bottom-10\">\n                                    <div class=\"col-sm-2 margin-top-30\"></div>\n                                    <div class=\"col-sm-4\">\n                                            <span class=\"regions-item-label\">Title</span>\n                                            <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":121,"column":62},"end":{"line":121,"column":71}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":121,"column":81},"end":{"line":121,"column":90}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":121,"column":107},"end":{"line":121,"column":117}}}) : helper)))
    + "].Title\" type=\"text\" maxlength=\"200\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Title") || (depth0 != null ? lookupProperty(depth0,"Title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data,"loc":{"start":{"line":121,"column":161},"end":{"line":121,"column":170}}}) : helper)))
    + "\" class=\"form-control\" />\n                                    </div>\n                                    <div class=\"col-sm-6\">\n                                        <span class=\"regions-item-label\">Description</span>\n                                        <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":125,"column":58},"end":{"line":125,"column":67}}}) : helper)))
    + "].Regions["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":125,"column":77},"end":{"line":125,"column":86}}}) : helper)))
    + "].RegionLanguage["
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":125,"column":103},"end":{"line":125,"column":113}}}) : helper)))
    + "].Description\" type=\"text\" maxlength=\"500\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Description") || (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Description","hash":{},"data":data,"loc":{"start":{"line":125,"column":163},"end":{"line":125,"column":178}}}) : helper)))
    + "\" class=\"form-control\" />\n                                    </div>\n                                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"addEditMarketingCampaignHub-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":43}}}) : helper)))
    + "\" class=\"hubs-item\">\n    <input name=\"Hubs["
    + alias4(((helper = (helper = lookupProperty(helpers,"Index") || (depth0 != null ? lookupProperty(depth0,"Index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":31}}}) : helper)))
    + "].HubId\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":67}}}) : helper)))
    + "\" />\n    <div data-toggle=\"collapse\" data-target=\"#addEditMarketingCampaignHub-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":3,"column":74},"end":{"line":3,"column":80}}}) : helper)))
    + "-Regions\"\n        class=\"collapse-toggle collapsed\">\n        <span class=\"collapse-toggle-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":51}}}) : helper)))
    + "</span>\n        <span class=\"collapse-toggle-icon\"></span>\n    </div>\n    <div id=\"addEditMarketingCampaignHub-"
    + alias4(((helper = (helper = lookupProperty(helpers,"Id") || (depth0 != null ? lookupProperty(depth0,"Id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Id","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":47}}}) : helper)))
    + "-Regions\" class=\"collapse-container collapse\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"Regions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":135,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true});