<h2 class="mb-3">Export Designs</h2>

<h4>Export Designs:</h4>
<div class="row">
    <div class="col-lg-6">
        <textarea id="text-ids" name="identifiers"
            style="width:100%; max-width:100%; height:150px;"></textarea>
        <br />
    </div>
    <div class="col-lg-6">
        <div class="upload-drop-zone" [ngClass]="{ 'over': over }" id="drop-zone" style="width: 100%">
            <div>Drag here or</div>
            <input style="margin:8px" type="button" id="selectFileButton" value="Select file" class="btn btn-primary" />
            <div>Supported format: csv</div>
            <div>Important: Your file should contain only 1 column with ids</div>

            <form id="file-upload-form" action="/" method="POST" enctype="multipart/form-data">
                <input style="display: none" type="file" name="file" id="js-upload-files" accept=".csv">
            </form>
        </div>
    </div>
</div>

<div class="row" style="margin-top: 8px; margin-bottom: 8px">
    <div class="col-lg-6">
        <input id="submit-ids" type="button" value="Submit" class="btn btn-primary">

        <div id="upload-error">
            <div id="error-message"></div>
        </div>
    </div>
</div>
