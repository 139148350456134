export const DateField = function (this: any, config: any) {
    jsGrid.Field.call(this, config);
};

DateField.prototype = new jsGrid.Field({
    css: 'date-field',

    sorter: function (date1: Date, date2: Date) {
        return (date1 || new Date(0)).getTime() - (date2 || new Date(0)).getTime();
    },

    itemTemplate: function (value: Date) {
        return value != null ? value.toLocaleDateString('de-DE') : '';
    },

    insertTemplate: function (value: Date) {
        return this._insertPicker = $('<input>').datepicker({ defaultDate: new Date() });
    },

    editTemplate: function (value: Date) {
        return this._editPicker = $('<input>').datepicker().datepicker('setDate', value);
    },

    insertValue: function () {
        return this._insertPicker.datepicker('getDate').toLocaleDateString('de-DE');
    },

    editValue: function () {
        return this._editPicker.datepicker('getDate').toLocaleDateString('de-DE');
    }
});
