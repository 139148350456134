<h2 class="mb-3">Release Notes</h2>

<form id="searchForm">
    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="searchTitle">Title</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" id="searchTitle" name="searchTitle" maxlength="500">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="searchReleaseVersion">Release version</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" id="searchReleaseVersion" name="searchReleaseVersion"
                maxlength="100">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="searchReleaseDate">Release date</label>
        <div class="col-sm-10">
            <input type="text" class="form-control date-picker" id="searchReleaseDate" name="searchReleaseDate"
                placeholder="yyyy-mm-dd">
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-12">
            <button type="button" id="searchButton" class="btn btn-primary mr-2">Search</button>
            <button type="button" id="clearFilter" class="btn btn-secondary mr-2">Clear Filter</button>
            <button type="button" id="addNewReleaseNote" class="btn btn-primary float-right">
                Add New Release Note</button>
        </div>
    </div>
</form>

<div class="main-div">
    <div id="jsGridReleaseNotes"></div>
</div>

<div id="releaseNoteDialog" title="Release note">
</div>

<div id="importDialog" title="Import release notes">
</div>
