<h2 i18n="user agreement title|Reset user agreement title">Reset user agreements</h2>

<div>
    <div>
        <p i18n="user agreement description|Reset user agreement description">
            This will reset user agreements for all the users and they will have to confirm the user agreements again.
            See image below.
        </p>
        <img i18n-alt="user agreement img alt|Reset user agreement img alt for Hilti PROFIS Engineering User Agreement popup"
            alt="Hilti PROFIS Engineering User Agreement popup" width="767" height="324"
            [src]="userAgreementsImage" />
    </div>
    <br />

    <button class="btn btn-primary" (click)="resetUserAgreementsObservable()" i18n="user agreement button|Reset user agreement button">
        Reset user agreements
    </button>

    <h4 class="mt-3" *ngIf="showSuccessMessage" i18n="user agreement success|Reset user agreement was successful" >
        User agreements have been reset successfully!
    </h4>
</div>
