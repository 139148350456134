var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":57,"column":31},"end":{"line":57,"column":40}}}) : helper)))
    + "\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"Selected") || (depth0 != null ? lookupProperty(depth0,"Selected") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Selected","hash":{},"data":data,"loc":{"start":{"line":57,"column":42},"end":{"line":57,"column":54}}}) : helper)))
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":57,"column":55},"end":{"line":57,"column":63}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"externalAppEditForm\">\n    <fieldset form=\"externalAppEditForm\" name=\"external-applications\">\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"Name\">Application name</label>\n            <div class=\"col-sm-9\">\n                <input type=\"text\" id=\"name\" name=\"name\" accept=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":6,"column":78},"end":{"line":6,"column":86}}}) : helper)))
    + "\" class=\"form-control note-input\" maxlength=\"255\" required />\n            </div>\n\n            <span class=\"offset-3 col-sm-9 col-form-label\">\n                Application name will be used for tracking.\n                <br/>\n                <b>Tracking script needs to be updated!</b>\n            </span>\n        </div>\n\n        <hr/>\n\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"TranslationKey\">Translation Key</label>\n            <div class=\"col-sm-9\">\n                <input type=\"text\" id=\"translationKey\" name=\"translationKey\" accept=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"TranslationKey") || (depth0 != null ? lookupProperty(depth0,"TranslationKey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TranslationKey","hash":{},"data":data,"loc":{"start":{"line":21,"column":98},"end":{"line":21,"column":116}}}) : helper)))
    + "\" class=\"form-control note-input\"\n                    maxlength=\"255\" required />\n            </div>\n\n            <span class=\"offset-3 col-sm-9 col-form-label\">\n                Please ensure this translation key exists:\n                <br/>\n                <b id=\"externalAppTranslationKey\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DisplayTranslationKey") || (depth0 != null ? lookupProperty(depth0,"DisplayTranslationKey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DisplayTranslationKey","hash":{},"data":data,"loc":{"start":{"line":28,"column":50},"end":{"line":28,"column":75}}}) : helper)))
    + "</b>\n            </span>\n        </div>\n\n        <hr/>\n\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"Image\">Image (size MUST be 128x128 or smaller)</label>\n            <div class=\"col-sm-9\">\n                <img id=\"imagePreview\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Image") || (depth0 != null ? lookupProperty(depth0,"Image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Image","hash":{},"data":data,"loc":{"start":{"line":37,"column":44},"end":{"line":37,"column":53}}}) : helper)))
    + "\" />\n                <input type=\"file\" id=\"image\" name=\"image\" accept=\"image/*\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Image") || (depth0 != null ? lookupProperty(depth0,"Image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Image","hash":{},"data":data,"loc":{"start":{"line":38,"column":83},"end":{"line":38,"column":92}}}) : helper)))
    + "\" />\n            </div>\n        </div>\n\n        <hr/>\n\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"showNewLabel\">Show \"New\" label</label>\n            <div class=\"col-sm-9\">\n                <input id=\"showNewLabel\" name=\"showNewLabel\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ShowNewLabel") || (depth0 != null ? lookupProperty(depth0,"ShowNewLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ShowNewLabel","hash":{},"data":data,"loc":{"start":{"line":47,"column":68},"end":{"line":47,"column":84}}}) : helper)))
    + "\" type=\"checkbox\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"ShowNewLabel") || (depth0 != null ? lookupProperty(depth0,"ShowNewLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ShowNewLabel","hash":{},"data":data,"loc":{"start":{"line":47,"column":102},"end":{"line":47,"column":118}}}) : helper)))
    + "/>\n            </div>\n        </div>\n    </fieldset>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\">Hubs</label>\n        <div class=\"col-sm-9\">\n            <select id=\"hubsFilter\" multiple class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"HubsFilter") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":58,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        <div class=\"col-md-12\">\n            <label class=\"col-form-label\">Selected hubs</label>\n            <div id=\"hubs\" class=\"hubs\"></div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});