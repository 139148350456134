import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

export interface IClusterDetails {
    cluster: string;
}

export interface IVersionDetailsService {
    getClusterDetails(): Promise<IClusterDetails>;
}

@Injectable({
    providedIn: 'root'
})
export class VersionDetailsService implements IVersionDetailsService {
    constructor(
        private apiService: ApiService
    ) { }

    public async getClusterDetails(): Promise<IClusterDetails> {
        const options = { supressErrorMessage: true };

        try {
            const result = await this.apiService.request<IClusterDetails>(new HttpRequest('GET', `${environment.baseUrl}/api/cluster`), options);
            if (result != null) {
                return result;
            }
        } catch (error) {
            console.error(error);
        }

        return Promise.resolve({
            cluster: '?'
        });
    }
}
