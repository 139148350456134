export function downloadBlob(blob: Blob, fileName: string) {
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    anchor.href = url;
    anchor.style.display = 'none';
    anchor.setAttribute('download', fileName);
    anchor.click();

    anchor.remove();

    // wait a bit before revoking the url
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 5000);
}
