import { Component } from '@angular/core';

import { AppTitleService } from '../../services/app-title.service';
import { ModalService } from '../../services/modal.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { Page } from '../page';
import { DocumentApiService, DevelopmentDeleteAllDesignsPattern } from '../../services/document-api.service';
import { AuthService } from '../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-development-api',
    templateUrl: './development-api.component.html',
    styleUrls: ['./development-api.component.css']
})
export class DevelopmentApiComponent extends Page {
    developmentDeleteAllDesignsPattern = DevelopmentDeleteAllDesignsPattern;
    pageTitle = $localize`:app-title development-api|Development API application title:Development API`;

    designDeletionInProgressPattern = DevelopmentDeleteAllDesignsPattern.None;
    showSuccessMessageDeleteAllDesigns: DevelopmentDeleteAllDesignsPattern;
    showTimeoutMessageDeleteAllDesigns: DevelopmentDeleteAllDesignsPattern;


    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private documentApiService: DocumentApiService,
        private modalService: ModalService,
        private authService: AuthService
    ) {
        super(appTitleService, pageLoadingService);
    }


    public get userName() {
        return this.authService.auth.userName;
    }


    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        this.designDeletionInProgressPattern = await this.getCurrentDeleteAllDesignsPattern();
    }

    public async deleteAllDesigns(pattern: DevelopmentDeleteAllDesignsPattern) {
        if ((await this.getCurrentDeleteAllDesignsPattern()) != DevelopmentDeleteAllDesignsPattern.None){
            this.modalService.showMessage(
                'Deletion of designs already in progress.',
                'Already in progress.'
            );

            return;
        }

        this.designDeletionInProgressPattern = DevelopmentDeleteAllDesignsPattern.None;
        this.showSuccessMessageDeleteAllDesigns = null;
        this.showTimeoutMessageDeleteAllDesigns = null;

        const userFriendlyPatternTextFn = (pattern: DevelopmentDeleteAllDesignsPattern) => {
            switch (pattern) {
                case DevelopmentDeleteAllDesignsPattern.LoadTest: return 'load test';
                case DevelopmentDeleteAllDesignsPattern.Automation: return 'automation';
                case DevelopmentDeleteAllDesignsPattern.User: return this.userName;
                default: throw new Error('Unsupported DevelopmentDeleteAllDesignsPattern.');
            }
        };

        const confirmed = await this.modalService.showConfirmation(
            `Are you sure you want to delete all ${userFriendlyPatternTextFn(pattern)} designs and projects, this might take several minutes?`,
            'Delete designs and projects'
        );
        if (!confirmed) {
            return;
        }

        this.designDeletionInProgressPattern = pattern;

        try {
            await this.documentApiService.adminDeleteAllDesigns(pattern, { supressErrorMessage: true });
            this.modalService.showMessage(
                `Delete ${userFriendlyPatternTextFn(pattern)} designs and projects is completed successfully.`,
                `Delete designs and project done`
            );
            this.showSuccessMessageDeleteAllDesigns = pattern;
        }
        catch (error) {
            if (error instanceof HttpErrorResponse && error.status == 504) {
                this.modalService.showMessage(
                    `Hilti gateway returned timeout... Deleting ${userFriendlyPatternTextFn(pattern)} designs and projects is still going on in the background. This might take up to 1h. Please wait some time and manually check if designs were removed.`,
                    `Delete designs and project still in progress`
                );
                this.showTimeoutMessageDeleteAllDesigns = pattern;
            }
            else {
                this.modalService.showServiceErrorMessage(null, error);
            }
        }
        finally {
            this.designDeletionInProgressPattern = DevelopmentDeleteAllDesignsPattern.None;
        }
    }

    public async cancelCurrentDesignsDeletion() {
        if ((await this.getCurrentDeleteAllDesignsPattern()) == DevelopmentDeleteAllDesignsPattern.None){
            return;
        }

        try {
            await this.documentApiService.adminCancelCurrentDeleteAllDesigns({ supressErrorMessage: true });
        }
        catch (error) {
            this.modalService.showServiceErrorMessage(null, error);
        }
    }


    private async getCurrentDeleteAllDesignsPattern(){
        try {
            const result = await this.documentApiService.adminGetCurrentDeleteAllDesignsPattern({ supressErrorMessage: true });
            return result;
        }
        catch (error) {
            return DevelopmentDeleteAllDesignsPattern.None;
        }
    }
}
