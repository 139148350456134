export class ImageHelper {
    public static emptyImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAANSURBVBhXY2BgYGAAAAAFAAGKM+MAAAAAAElFTkSuQmCC';

    public static isImageEmpty(image: string) {
        return image == null
            || image.trim().length < 1
            || image == ImageHelper.emptyImage;
    }

    public static async validateImageSize(
        image: string, maxWidth: number, maxHeight: number
    ) {
        return new Promise<void>(
            (resolve, reject) => {
                const img = new Image();
                img.src = image;
                img.addEventListener('load', () => {
                    if (img.naturalWidth > maxWidth || img.naturalHeight > maxHeight) {
                        reject(new Error('Invalid image size.'));
                        return;
                    }

                    resolve();
                });
            }
        );
    };
}
