<h2 class="mb-3">Application versions</h2>

<form id="applicationVersionsForm" #ngForm="ngForm" *ngIf="applicationVersions != null">
    <div class="form-group row">
        <h4 class="col-sm-6 mb-1">Application versions</h4>
    </div>

    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="EngineeringVersion">Hilti PROFIS Engineering version</label>
        <div class="col-sm-4">
            <input type="text" class="form-control" id="EngineeringVersion" name="EngineeringVersion"
                [(ngModel)]="applicationVersions.EngineeringVersion" #EngineeringVersion="ngModel"
                maxlength="25" pattern="^(\d+)(\.\d+)(\.\d+)((\.\d+)?)$" />
                <div *ngIf="EngineeringVersion.errors" class="invalid-feedback">
                    {{ getValidationError(EngineeringVersion.errors) }}
                </div>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="QuantityCalculatorVersion">Hilti Quantity Calculator version</label>
        <div class="col-sm-4">
            <input type="text" class="form-control" id="QuantityCalculatorVersion" name="QuantityCalculatorVersion"
                [(ngModel)]="applicationVersions.QuantityCalculatorVersion" #QuantityCalculatorVersion="ngModel"
                maxlength="25" pattern="^(\d+)(\.\d+)(\.\d+)((\.\d+)?)$" />
            <div *ngIf="QuantityCalculatorVersion.errors" class="invalid-feedback">
                {{ getValidationError(QuantityCalculatorVersion.errors) }}
            </div>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="AdminVersion">Hilti PROFIS Admin version</label>
        <div class="col-sm-4">
            <input type="text" class="form-control" id="AdminVersion" name="AdminVersion"
                [(ngModel)]="applicationVersions.AdminVersion" #AdminVersion="ngModel"
                maxlength="25" pattern="^(\d+)(\.\d+)(\.\d+)((\.\d+)?)$" />
                <div *ngIf="AdminVersion.errors" class="invalid-feedback">
                    {{ getValidationError(AdminVersion.errors) }}
                </div>
        </div>
    </div>


    <div class="form-group row">
        <h4 class="col-sm-6 mb-1">Module versions</h4>
    </div>

    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="CommonModuleVersion">Common module version</label>
        <div class="col-sm-4">
            <input type="text" class="form-control" id="CommonModuleVersion" name="CommonModuleVersion"
                [(ngModel)]="applicationVersions.CommonModuleVersion" #CommonModuleVersion="ngModel"
                maxlength="25" pattern="^(\d+)(\.\d+)(\.\d+)((\.\d+)?)$" />
                <div *ngIf="CommonModuleVersion.errors" class="invalid-feedback">
                    {{ getValidationError(CommonModuleVersion.errors) }}
                </div>
        </div>
    </div>


    <div class="form-group row">
        <div class="offset-2 col-sm-4">
            <button type="button" class="save btn btn-primary"
                (click)="save(ngForm)" [disabled]="pendingSave || (ngForm.enabled && !ngForm.valid)">
                Save
            </button>
            <p class="note-display small">Note: the changes will only be visible after user refreshes corresponding page!</p>
        </div>
    </div>
</form>
