import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { ApiOptions, ApiService } from './api.service';
import { SessionStorageCodeVerifierKey } from './auth.service';
import { SessionStorageService } from './session-storage.service';

export interface TokensRequest {
    grant_type: 'authorization_code';
    code: string;
    client_id: string;
    redirect_uri: string;
}

export interface TokensResponse {
    access_token: string;
    subscription_info: {
        AuthorizationEntryList: {
            City: string;
            ContactFirstName: string;
            ContactIDs: string;
            ContactLastName: string;
            ContactOriginID: string;
            Country: string;
            CustomerID: string;
            CustomerName: string;
            CustomerOriginID: string;
            Licenses: string;
            PostalCode: string;
            Roles: string;
            Scopes: string;
            SecondaryName: string;
            Street: string;
        }[];
        ClientID: string;
        Country: string;
        CountryOfResidence: string;
        CustomerID: string;
        LogonID: string;
        UID: string;
    }
}

@Injectable({
    providedIn: 'root'
})
export class OauthApiService {
    constructor(private apiService: ApiService, private sessionStorageService: SessionStorageService) { }

    async tokens(request: TokensRequest, apiOptions?: ApiOptions): Promise<TokensResponse> {
        const formDataRequest = new URLSearchParams();
        formDataRequest.set('grant_type', request.grant_type);
        formDataRequest.set('code', request.code);
        formDataRequest.set('client_id', request.client_id);
        formDataRequest.set('redirect_uri', request.redirect_uri);

        if (environment.authentication == 'oauth2') {
            const verifier = this.sessionStorageService.get<string>(SessionStorageCodeVerifierKey);
            this.sessionStorageService.remove(SessionStorageCodeVerifierKey);
            formDataRequest.set('code_verifier', verifier);
        }

        const tokensUrl = environment.externalAuthenticationUrl + environment.externalTokens;
        const httpRequest = new HttpRequest('POST', tokensUrl, formDataRequest.toString(), { headers: new HttpHeaders({ 'content-type': 'application/x-www-form-urlencoded' }) });

        return await this.apiService.request<TokensResponse>(httpRequest, apiOptions);
    }
}
