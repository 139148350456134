export function getDateFromJson(jsonDate: string) {
    // gets the js date object from microsoft json format
    return new Date(Date.parse(jsonDate));
}

export function getDateFromUnix(unixDate: number) {
    // gets the js date object from unix date format
    return new Date(unixDate * 1000.0);
}

export function formatDateForInput(jsDate: Date) {
    // add leading zeroes to js date when days or months are < 10.
    return jsDate.getFullYear() + '-' +
        ((jsDate.getMonth() + 1) < 10 ? ('0' + (jsDate.getMonth() + 1)) : (jsDate.getMonth() + 1)) + '-' +
        (jsDate.getDate() < 10 ? ('0' + jsDate.getDate()) : jsDate.getDate());
}
