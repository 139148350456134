import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm, ValidationErrors } from '@angular/forms';
import { AppTitleService } from '../../services/app-title.service';
import {
    ApplicationVersions, ApplicationVersionsService
} from '../../services/application-versions.service';
import { ModalService } from '../../services/modal.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { Page } from '../page';

@Component({
    selector: 'app-application-versions',
    templateUrl: './application-versions.component.html',
    styleUrls: ['./application-versions.component.css']
})
export class ApplicationVersionsComponent extends Page implements OnInit {
    pageTitle = $localize`:app-title application versions|Application versions title:Application versions`;

    public applicationVersions: ApplicationVersions;

    public pendingSave = false;


    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private applicationVersionsService: ApplicationVersionsService,
        private modalService: ModalService
    ) {
        super(appTitleService, pageLoadingService);
    }


    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        await this.load();
    }

    public async save(form: NgForm) {
        if (this.pendingSave || (form.enabled && !form.valid)) {
            return;
        }

        this.pendingSave = true;

        const loadingId = 'application-versions-saving';
        this.pageLoadingService.add(loadingId);

        try {
            await this.applicationVersionsService.updateApplicationVersions(this.applicationVersions, { supressErrorMessage: true });
            await this.applicationVersionsService.setApplicationVersion(true);
        }
        catch (error) {
            this.handleErrors(form, error);
        }
        finally {
            this.pageLoadingService.remove(loadingId);
            this.pendingSave = false;
        }
    }

    public getValidationError(errors: ValidationErrors) {
        if (errors?.['pattern']) {
            return 'Invalid version value';
        }

        if (errors?.['server']) {
            return errors['server'];
        }

        return undefined;
    }


    private async load() {
        this.applicationVersions = await this.applicationVersionsService.getApplicationVersions();
    }

    private handleErrors(form: NgForm, err: any) {
        if (err instanceof HttpErrorResponse && err.status == 400) {
            const errors = err?.error.errors;
            for (let key in errors) {
                if (errors[key].length) {
                    form.controls[key]?.setErrors({
                        'server': errors[key][0]
                    });
                }
            }
            return;
        }
        this.modalService.showServiceErrorMessage(null, err);
    }
}
