import { from } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';

import { Component } from '@angular/core';

import userAgreementsImage from '../../../images/userAgreements.png';
import { AppTitleService } from '../../services/app-title.service';
import { ModalService } from '../../services/modal.service';
import { PageLoadingService } from '../../services/page-loading.service';
import { UserSettingsApiService } from '../../services/user-settings-api.service';
import { Page } from '../page';

@Component({
    selector: 'app-user-agreement',
    templateUrl: './user-agreement.component.html',
    styleUrls: ['./user-agreement.component.css']
})
export class UserAgreementComponent extends Page {
    pageTitle = $localize`:app-title user agreement|User agreement application title:User agreements`;
    userAgreementsImage = userAgreementsImage;

    showSuccessMessage = false;

    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private userSettingsApiService: UserSettingsApiService,
        private modalService: ModalService
    ) {
        super(appTitleService, pageLoadingService);
    }

    async resetUserAgreements() {
        this.showSuccessMessage = false;

        const confirmed = await this.modalService.showConfirmation(
            $localize`:reset-user-agreement confirm message|Confirm message for reset user agreement:Are you sure you want to reset user agreements? This will make all users to confirm their agreements again.`,
            $localize`:reset-user-agreement confirm title|Confirm title for reset user agreement:Reset user agreements`
        );

        if (confirmed) {
            await this.userSettingsApiService.adminResetUserAgreements();

            this.showSuccessMessage = true;
        }
    }

    /**
     * @deprecated Only used to show how it can be done with Observable
     */
    resetUserAgreementsObservable() {
        this.showSuccessMessage = false;

        from(this.modalService.showConfirmation(
            $localize`:reset-user-agreement confirm message|Confirm message for reset user agreement:Are you sure you want to reset user agreements? This will make all users to confirm their agreements again.`,
            $localize`:reset-user-agreement confirm title|Confirm title for reset user agreement:Reset user agreements`
        ))
            .pipe(
                filter(x => x),
                flatMap(() => this.userSettingsApiService.adminResetUserAgreements())
            )
            .subscribe(() => {
                this.showSuccessMessage = true;
            });
    }
}
