import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { ApiOptions, ApiService } from './api.service';

export interface ConvertFromJsonToXmlDesignRequest {
    Items: {
        Id: string;
        Content: string;
    }[]
}

export interface ConvertFromJsonToXmlDesignResponse {
    Items: {
        Id: string;
        Content: string;
    }[]
}

@Injectable({
    providedIn: 'root'
})
export class PeCalculationApiService {
    constructor(
        private apiService: ApiService
    ) { }

    private get baseplateCalculationServiceUrl() {
        return environment.baseplateCalculationWebServiceUrl;
    }

    async convertFromJsonToXmlDesign(request: ConvertFromJsonToXmlDesignRequest, apiOptions?: ApiOptions): Promise<ConvertFromJsonToXmlDesignResponse> {
        const httpRequest = new HttpRequest('POST', `${this.baseplateCalculationServiceUrl}ConvertFromJsonToXmlDesign`, request);
        return await this.apiService.request<ConvertFromJsonToXmlDesignResponse>(httpRequest, apiOptions);
    }
}
