var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"externalAppsPreview\">\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"Name\">Application name</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":44}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n\n    <hr/>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"TranslationKey\">Translation Key</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DisplayTranslationKey") || (depth0 != null ? lookupProperty(depth0,"DisplayTranslationKey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DisplayTranslationKey","hash":{},"data":data,"loc":{"start":{"line":14,"column":36},"end":{"line":14,"column":61}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n\n    <hr/>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"Image\">Image</label>\n        <div class=\"col-sm-9\">\n            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Image") || (depth0 != null ? lookupProperty(depth0,"Image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Image","hash":{},"data":data,"loc":{"start":{"line":23,"column":22},"end":{"line":23,"column":31}}}) : helper)))
    + "\"/>\n        </div>\n    </div>\n\n    <hr/>\n\n    <div class=\"form-group row\">\n        <label class=\"col-sm-3 col-form-label\" for=\"ShowNewLabel\">Show \"New\" label</label>\n        <div class=\"col-sm-9\">\n            <p class=\"note-display\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ShowNewLabel") || (depth0 != null ? lookupProperty(depth0,"ShowNewLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ShowNewLabel","hash":{},"data":data,"loc":{"start":{"line":32,"column":36},"end":{"line":32,"column":52}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n</form>\n";
},"useData":true});