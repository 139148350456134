import { Injectable } from '@angular/core';
import { DesignType, IConvertDesignsRequest, IDesignListInfo } from '../entities/modules-entities';
import { PeCalculationApiService } from './pe-calculation-api.service';

/**
 * Service used to consolidate data obtained from various modules (e.g. PE, C2C etc.).
 */
@Injectable({
    providedIn: 'root'
})
export class ModulesService {
    private designListInfo: IDesignListInfo[] = [];


    constructor(
        private peCalculationApiService: PeCalculationApiService
    ) {
        this.loadDesignListInfoForPeModule();
    }


    public addDesignListInfo(designListInfo: IDesignListInfo[]) {
        if (designListInfo.length) {
            this.designListInfo = this.designListInfo.concat(designListInfo);
        }
    }

    public getDesignListInfoForDesignType(designTypeId: number) {
        return this.designListInfo.find(x => x.designTypeId == designTypeId);
    }


    private loadDesignListInfoForPeModule() {
        const common = {
            convertDesignsId: 1,
            convertDesigns: (designs: IConvertDesignsRequest) => {
                return this.peCalculationApiService.convertFromJsonToXmlDesign(designs)
            }
        };

        const designListInfo: IDesignListInfo[] = [
            {
                ...common,
                designTypeId: DesignType.Concrete
            },
            {
                ...common,
                designTypeId: DesignType.Handrail
            },
            {
                ...common,
                designTypeId: DesignType.Masonry
            },
            {
                ...common,
                designTypeId: DesignType.MetalDeck
            }
        ];
        this.addDesignListInfo(designListInfo);
    }
}
