import { Component } from '@angular/core';

import { Page } from '../page';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent extends Page {
    pageTitle = '';
}
